
.mixin(tariff-calculator) {

	.main-process .card .tariff-calculator-card {
		margin-bottom: 2rem;
		
		.tariff-calculator-output-container {
			margin-left: 0px;
			margin-right: 0px;
			background-color: @tariff-calculator-output-row-background-color;
			border-bottom: @tariff-calculator-output-row-border-bottom;
			
			.tariff-calculator-output-row {
														
				.tariff-calculator-output-label {
					font-family: @tariff-calculator-output-label-font-family;
					font-weight: @tariff-calculator-output-label-font-weight;
					font-size: @tariff-calculator-output-label-font-size;
					color: @tariff-calculator-output-label-color;
					padding: 0.25rem 1rem;	
				}
				.tariff-calculator-output-value {
					font-family: @tariff-calculator-output-value-font-family;
					font-weight: @tariff-calculator-output-value-font-weight;
					font-size: @tariff-calculator-output-value-font-size;
					color: @tariff-calculator-output-value-color;
					padding: 0.25rem 1rem;	
				}
			}
				
			@media (max-width: @viewport-sm-max) {
				
				border-bottom: 0px;
				
				.tariff-calculator-output-row {
					border-bottom: @tariff-calculator-output-row-border-bottom;
				}	
			}	
		}	
		
		.accordion {
			.accordion-item {
				h3.accordion-header {
					border-bottom: 0px;
					border-radius: 0px;
					margin-top: 0px;
					background-color: @tariff-calculator-card-accordion-header-background-color;
										
					.accordion-button,
					.accordion-button.collapsed {
						border-bottom: 0px;
						padding: 0.25rem 1rem;
						background-color: @tariff-calculator-card-accordion-header-background-color;
						
						&:hover, &:focus {
							border-bottom: 0px;
							background-color: @tariff-calculator-card-accordion-header-background-color-hover-focus;
						}

						> div {
							width: 100%;
							text-align: right;
						}	
					}	
					
					.tariff-calc-heading-label {
						font-family: @tariff-calculator-card-accordion-header-font-family;
						font-weight: @tariff-calculator-card-accordion-header-font-weight;
						font-size: @tariff-calculator-card-accordion-header-font-size;
						color: @tariff-calculator-card-accordion-header-color;
						vertical-align: top;
						padding-top: 0.25rem;
						display: inline-block;
					}
					.fa-caret-up,
					.fa-caret-down {
						color: @tariff-calculator-card-accordion-header-color;
					}
					
					.accordion-button {
						&:hover, &:focus {
							.tariff-calc-heading-label,
							.fa-caret-up,
							.fa-caret-down {
								color: @tariff-calculator-card-accordion-header-color-hover-focus;
							}
						}
					}
				}
				.accordion-body {
					background-color: @tariff-calculator-card-accordion-body-background-color;
					padding-top: 0px;
					padding-bottom: 0.5rem;
					
					.radio-customer-type-row {
						margin-left: -1.25rem;
						margin-right: -1.25rem;
						border-top: @tariff-calculator-radio-customer-type-border;
					
						input[type=radio]:not(old) {
							margin: 0;
							padding: 0;
							font-size: 1em;
							opacity: 0;
						}
						input[type=radio]:not(old) + label {
							display: inline-block;
							width: 100%;
							float: left;
							background: @tariff-calculator-radio-customer-type-background-color none;
							border-right: @tariff-calculator-radio-customer-type-border;	

							div.radio-label {
								margin: 0px;
								padding: 0.5rem 0px;
								text-align: center;
								font-family: @tariff-calculator-radio-customer-type-font-family;
								font-weight: @tariff-calculator-radio-customer-type-font-weight;
								font-size: @tariff-calculator-radio-customer-type-font-size;
								color: @tariff-calculator-radio-customer-type-color;
							}
							&:hover, &:focus {
								background: @tariff-calculator-radio-customer-type-background-color-hover-focus none;
								
								div.radio-label {
									color: @tariff-calculator-radio-customer-type-color-hover-focus;
								}
							}
						}
						input[type=radio]:not(old):checked + label {
							background: @tariff-calculator-radio-customer-type-checked-background-color none;
							
							div.radio-label {
								color: @tariff-calculator-radio-customer-type-checked-color;
							}
							
							&:hover, &:focus {
								background: @tariff-calculator-radio-customer-type-checked-background-color-hover-focus none;
								
								div.radio-label {
									color: @tariff-calculator-radio-customer-type-checked-color-hover-focus;
								}
							}
						}
						input[type=radio]:not(old) + label > span {
							display: none;
						}

						> *[class^='col-'] {    
							&:last-child {
								input[type=radio]:not(old) + label {
									border-right: 0px;
								}
							}
						}					
					}
					
					.radio-branches-row {
						margin-left: -1.25rem;
						margin-right: -1.25rem;
						border-top: @tariff-calculator-radio-branches-border;
					
						input[type=radio]:not(old) {
							margin: 0;
							padding: 0;
							font-size: 1em;
							opacity: 0;
						}
						input[type=radio]:not(old) + label {
							display: inline-block;
							width: 100%;
							float: left;
							background: @tariff-calculator-radio-branches-background-color none;
							border-right: @tariff-calculator-radio-branches-border;	

							div.radio-label {
								margin: 0px;
								padding: 0.5rem 0px;
								text-align: center;
								font-family: @tariff-calculator-radio-branches-font-family;
								font-weight: @tariff-calculator-radio-branches-font-weight;
								font-size: @tariff-calculator-radio-branches-font-size;
								color: @tariff-calculator-radio-branches-color;
								
								.radio-branch-icon {
									display: inline-block;
									width: 27px;
									height: 27px;
									vertical-align: bottom;	
								}
								.radio-branch-icon.power {
									background: transparent @tariff-calculator-radio-branches-icon-power no-repeat center center;
									background-size: @tariff-calculator-radio-branches-icon-power-size;
								}
								.radio-branch-icon.gas {
									background: transparent @tariff-calculator-radio-branches-icon-gas no-repeat center center;
									background-size: @tariff-calculator-radio-branches-icon-gas-size;
								}
								.radio-branch-icon.water {
									background: transparent @tariff-calculator-radio-branches-icon-water no-repeat center center;
									background-size: @tariff-calculator-radio-branches-icon-water-size;
								}
								.radio-branch-icon.thermal {
									background: transparent @tariff-calculator-radio-branches-icon-thermal no-repeat center center;
									background-size: @tariff-calculator-radio-branches-icon-thermal-size;
								}
								.radio-branch-icon.powerCharge {
									background: transparent @tariff-calculator-radio-branches-icon-powercharge no-repeat center center;
									background-size: @tariff-calculator-radio-branches-icon-powercharge-size;
								}
							}
							&:hover, &:focus {
								background: @tariff-calculator-radio-branches-background-color-hover-focus none;
								
								div.radio-label {
									color: @tariff-calculator-radio-branches-color-hover-focus;
									
									.radio-branch-icon.power {
										background: transparent @tariff-calculator-radio-branches-icon-power-hover-focus no-repeat center center;
										background-size: @tariff-calculator-radio-branches-icon-power-size;
									}
									.radio-branch-icon.gas {
										background: transparent @tariff-calculator-radio-branches-icon-gas-hover-focus no-repeat center center;
										background-size: @tariff-calculator-radio-branches-icon-gas-size;
									}
									.radio-branch-icon.water {
										background: transparent @tariff-calculator-radio-branches-icon-water-hover-focus no-repeat center center;
										background-size: @tariff-calculator-radio-branches-icon-water-size;
									}
									.radio-branch-icon.thermal {
										background: transparent @tariff-calculator-radio-branches-icon-thermal-hover-focus no-repeat center center;
										background-size: @tariff-calculator-radio-branches-icon-thermal-size;
									}
									.radio-branch-icon.powerCharge {
										background: transparent @tariff-calculator-radio-branches-icon-powercharge-hover-focus no-repeat center center;
										background-size: @tariff-calculator-radio-branches-icon-powercharge-size;
									}
								}
							}
						}
						input[type=radio]:not(old):checked + label {
							background: @tariff-calculator-radio-branches-checked-background-color none;
							
							div.radio-label {
								color: @tariff-calculator-radio-branches-checked-color;
								
								.radio-branch-icon.power {
									background: transparent @tariff-calculator-radio-branches-checked-icon-power no-repeat center center;
									background-size: @tariff-calculator-radio-branches-icon-power-size;
								}
								.radio-branch-icon.gas {
									background: transparent @tariff-calculator-radio-branches-checked-icon-gas no-repeat center center;
									background-size: @tariff-calculator-radio-branches-icon-gas-size;
								}
								.radio-branch-icon.water {
									background: transparent @tariff-calculator-radio-branches-checked-icon-water no-repeat center center;
									background-size: @tariff-calculator-radio-branches-icon-water-size;
								}
								.radio-branch-icon.thermal {
									background: transparent @tariff-calculator-radio-branches-checked-icon-thermal no-repeat center center;
									background-size: @tariff-calculator-radio-branches-icon-thermal-size;
								}
								.radio-branch-icon.powerCharge {
									background: transparent @tariff-calculator-radio-branches-checked-icon-powercharge no-repeat center center;
									background-size: @tariff-calculator-radio-branches-icon-powercharge-size;
								}
							}
							
							&:hover, &:focus {
								background: @tariff-calculator-radio-branches-checked-background-color-hover-focus none;
								
								div.radio-label {
									color: @tariff-calculator-radio-branches-checked-color-hover-focus;
									
									.radio-branch-icon.power {
										background: transparent @tariff-calculator-radio-branches-checked-icon-power-hover-focus no-repeat center center;
										background-size: @tariff-calculator-radio-branches-icon-power-size;
									}
									.radio-branch-icon.gas {
										background: transparent @tariff-calculator-radio-branches-checked-icon-gas-hover-focus no-repeat center center;
										background-size: @tariff-calculator-radio-branches-icon-gas-size;
									}
									.radio-branch-icon.water {
										background: transparent @tariff-calculator-radio-branches-checked-icon-water-hover-focus no-repeat center center;
										background-size: @tariff-calculator-radio-branches-icon-water-size;
									}
									.radio-branch-icon.thermal {
										background: transparent @tariff-calculator-radio-branches-checked-icon-thermal-hover-focus no-repeat center center;
										background-size: @tariff-calculator-radio-branches-icon-thermal-size;
									}
									.radio-branch-icon.powerCharge {
										background: transparent @tariff-calculator-radio-branches-checked-icon-powercharge-hover-focus no-repeat center center;
										background-size: @tariff-calculator-radio-branches-icon-powercharge-size;
									}
								}
							}
						}
						input[type=radio]:not(old) + label > span {
							display: none;
						}

						> *[class^='col-'] {    
							&:last-child {
								input[type=radio]:not(old) + label {
									border-right: 0px;
								}
							}
						}					
					}

					.tariff-calculator-forms {
						margin-top: 1rem;
					}	
				}
			}
		}	
	}

}
@tariff-calculator: tariff-calculator;


.mixin(tariff-cards) {

	.main-process .card .tariffs-display-container {
		
		.tariff-cards-display {
	
			margin-left: -1rem;
			
			.tariff-card {
				
				width: calc(~'33.333333% - 1rem');
				margin: 0px 0px 1rem 1rem;
				border: @tariff-card-border;
				border-radius: @tariff-card-border-radius;
				
				&:nth-child(2) {
					order: 1;
				}
				&:nth-child(3),
				&:nth-child(4),
				&:nth-child(5),
				&:nth-child(6),
				&:nth-child(7),
				&:nth-child(8),
				&:nth-child(9) {
					order: 3;
				}
						
				.card-header {
					border-top-left-radius: @tariff-card-border-radius;
					border-top-right-radius: @tariff-card-border-radius;
					background-color: @tariff-card-header-background-color;
					border-bottom: @tariff-card-header-border-bottom;

					.card-title {
						font-family: @tariff-card-title-font-family;
						font-weight: @tariff-card-title-font-weight; 
						font-size: @tariff-card-title-font-size;
						color: @tariff-card-title-color;
					}

					.card-title-price,
					.fa-caret-down,
					.fa-caret-up {
						display: none;
					}	
				}
				.card-body {
					padding: 0px;
					background-color: @tariff-card-body-background-color;
					
					.tariff-img-container {
						height: @tariff-card-image-height;
					}
					.advantages {
						background-color: @tariff-card-advantages-background-color;
						padding: 0.5rem 1rem;
						
						ul {
							margin-bottom: 0px;
							padding-left: 22px;
							list-style-type: none;
							
							li {
								&:before {
									font-family: 'FontAwesome';
									font-weight: 900;
									content: '\f058';
									color: @tariff-card-advantages-icon-color;
									margin-right: 6px;
									margin-left: -22px;
								}
							}	
						}
					}
				}
				.card-footer {
					border-bottom-left-radius: @tariff-card-border-radius;
					border-bottom-right-radius: @tariff-card-border-radius;
					background-color: @tariff-card-footer-background-color;
					padding-left: 0px;
					padding-right: 0px;
					
					.prices-row {
						text-align: center;
						padding: 0.5rem 1rem;
						background-color: @tariff-card-prices-row-background-color;
						font-size: @tariff-card-prices-row-font-size;
						
						.gross-price-label {
							vertical-align: top;					
							.fa {
								font-size: @font-size;
							}
						}
						.gross-price {	
							font-family: @tariff-card-prices-row-gross-price-font-family;
							font-weight: @tariff-card-prices-row-gross-price-font-weight;
							font-size: @tariff-card-prices-row-gross-price-font-size;
							color: @tariff-card-prices-row-gross-price-color;
							padding-top: 0.25rem;
							padding-bottom: 0.25rem;
						}
						.saving {
							font-family: @tariff-card-prices-row-saving-font-family;
							font-weight: @tariff-card-prices-row-saving-font-weight;
							font-size: @tariff-card-prices-row-saving-font-size;
							color: @tariff-card-prices-row-saving-color;
						}	
					}
					.tariff-details-link {
						padding: 0.5rem 1rem;
						text-align: center;
						background-color: @tariff-card-tariff-details-link-background-color;
						
						.form-link {
							.fa-chevron-circle-down {
								display: inline-block;
							}
							.fa-chevron-circle-up {
								display: none;
							}
						}
					}
					.tariff-details-row {
						padding: 0.5rem 1rem;
						background-color: @tariff-card-tariff-details-row-background-color;
						font-size: @tariff-card-tariff-details-row-font-size;
						color: @tariff-card-tariff-details-row-color;

						.d-table {
							width: 100%;
						}		
					}
					.tariff-informations-link {
						padding: 0.5rem 1rem;
						text-align: center;
						background-color: @tariff-card-tariff-informations-link-background-color;

						.form-link {
							.fa-chevron-circle-down {
								display: inline-block;
							}
							.fa-chevron-circle-up {
								display: none;
							}
						}
					}
					.tariff-informations-row {
						padding: 0.5rem 1rem;
						background-color: @tariff-card-tariff-informations-row-background-color;
						font-size: @tariff-card-tariff-informations-row-font-size;
						color: @tariff-card-tariff-informations-row-color;

						.d-table {
							width: 100%;
						}
					}
					.future-tariff-details-row {
						padding: 0.5rem 1rem;
						background-color: @tariff-card-future-tariff-details-row-background-color;
						font-size: @tariff-card-future-tariff-details-row-font-size;
						color: @tariff-card-future-tariff-details-row-color;

						.d-table {
							width: 100%;
						}		
					}
					.tariff-card-btn-row {
						padding: 1rem;
						margin-left: 0px;
						margin-right: 0px;
						margin-bottom: 0px;
						border-bottom-left-radius: @tariff-card-border-radius;
						border-bottom-right-radius: @tariff-card-border-radius;
					}
				}

				.card-body {
					.prices-row {
						text-align: center;
						padding: 0.5rem 1rem;
						background-color: @tariff-card-prices-row-background-color;
						font-size: @tariff-card-prices-row-font-size;
						
						.gross-price-label {
							vertical-align: top;					
							.fa {
								font-size: @font-size;
							}
						}
						.gross-price {	
							font-family: @tariff-card-prices-row-gross-price-font-family;
							font-weight: @tariff-card-prices-row-gross-price-font-weight;
							font-size: @tariff-card-prices-row-gross-price-font-size;
							color: @tariff-card-prices-row-gross-price-color;
							padding-top: 0.25rem;
							padding-bottom: 0.25rem;
						}
						.saving {
							font-family: @tariff-card-prices-row-saving-font-family;
							font-weight: @tariff-card-prices-row-saving-font-weight;
							font-size: @tariff-card-prices-row-saving-font-size;
							color: @tariff-card-prices-row-saving-color;
						}	
					}
					.tariff-details-link {
						padding: 0.5rem 1rem;
						text-align: center;
						background-color: @tariff-card-tariff-details-link-background-color;
						
						.form-link {
							.fa-chevron-circle-down {
								display: inline-block;
							}
							.fa-chevron-circle-up {
								display: none;
							}
						}
					}
					.tariff-details-row {
						padding: 0.5rem 1rem;
						background-color: @tariff-card-tariff-details-row-background-color;
						font-size: @tariff-card-tariff-details-row-font-size;
						color: @tariff-card-tariff-details-row-color;

						.d-table {
							width: 100%;
						}		
					}
					.tariff-informations-link {
						padding: 0.5rem 1rem;
						text-align: center;
						background-color: @tariff-card-tariff-informations-link-background-color;

						.form-link {
							.fa-chevron-circle-down {
								display: inline-block;
							}
							.fa-chevron-circle-up {
								display: none;
							}
						}
					}
					.tariff-informations-row {
						padding: 0.5rem 1rem;
						background-color: @tariff-card-tariff-informations-row-background-color;
						font-size: @tariff-card-tariff-informations-row-font-size;
						color: @tariff-card-tariff-informations-row-color;

						.d-table {
							width: 100%;
						}
					}
					.future-tariff-details-row {
						padding: 0.5rem 1rem;
						background-color: @tariff-card-future-tariff-details-row-background-color;
						font-size: @tariff-card-future-tariff-details-row-font-size;
						color: @tariff-card-future-tariff-details-row-color;

						.d-table {
							width: 100%;
						}		
					}
				}				
			}
			
			.tariff-card.show-tariff-details {
				.card-body,
				.card-footer {
					.tariff-details-link {
						.form-link {
							.fa-chevron-circle-down {
								display: none;
							}
							.fa-chevron-circle-up {
								display: inline-block;
							}
						}
					}
				}
			}

			.tariff-card.show-tariff-informations {
				.card-body,
				.card-footer {
					.tariff-informations-link {
						.form-link {
							.fa-chevron-circle-down {
								display: none;
							}
							.fa-chevron-circle-up {
								display: inline-block;
							}
						}
					}
				}
			}
			
			.tariff-card.power:nth-child(2) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-2-power-url no-repeat center top; 
			}
			.tariff-card.power:nth-child(3) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-3-power-url no-repeat center top; 
			}
			.tariff-card.power:nth-child(4) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-4-power-url no-repeat center top;
			}
			.tariff-card.power:nth-child(5) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-5-power-url no-repeat center top; 
			}
			.tariff-card.power:nth-child(6) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-6-power-url no-repeat center top; 
			}
			.tariff-card.power:nth-child(7) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-7-power-url no-repeat center top; 
			}
			.tariff-card.power:nth-child(8) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-8-power-url no-repeat center top; 
			}
			.tariff-card.power:nth-child(9) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-9-power-url no-repeat center top; 
			}
			
			.tariff-card.powerCharge:nth-child(2) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-2-powercharge-url no-repeat center top; 
			}
			.tariff-card.powerCharge:nth-child(3) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-3-powercharge-url no-repeat center top; 
			}
			.tariff-card.powerCharge:nth-child(4) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-4-powercharge-url no-repeat center top;
			}
			.tariff-card.powerCharge:nth-child(5) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-5-powercharge-url no-repeat center top; 
			}
			.tariff-card.powerCharge:nth-child(6) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-6-powercharge-url no-repeat center top; 
			}
			.tariff-card.powerCharge:nth-child(7) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-7-powercharge-url no-repeat center top; 
			}
			.tariff-card.powerCharge:nth-child(8) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-8-powercharge-url no-repeat center top; 
			}
			.tariff-card.powerCharge:nth-child(9) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-9-powercharge-url no-repeat center top; 
			}
			
			.tariff-card.gas:nth-child(2) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-2-gas-url no-repeat center top; 
			}
			.tariff-card.gas:nth-child(3) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-3-gas-url no-repeat center top; 
			}
			.tariff-card.gas:nth-child(4) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-4-gas-url no-repeat center top;
			}
			.tariff-card.gas:nth-child(5) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-5-gas-url no-repeat center top; 
			}
			.tariff-card.gas:nth-child(6) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-6-gas-url no-repeat center top; 
			}
			.tariff-card.gas:nth-child(7) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-7-gas-url no-repeat center top; 
			}
			.tariff-card.gas:nth-child(8) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-8-gas-url no-repeat center top; 
			}
			.tariff-card.gas:nth-child(9) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-9-gas-url no-repeat center top; 
			}
			
			.tariff-card.water:nth-child(2) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-2-water-url no-repeat center top; 
			}
			.tariff-card.water:nth-child(3) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-3-water-url no-repeat center top; 
			}
			.tariff-card.water:nth-child(4) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-4-water-url no-repeat center top;
			}
			.tariff-card.water:nth-child(5) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-5-water-url no-repeat center top; 
			}
			.tariff-card.water:nth-child(6) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-6-water-url no-repeat center top; 
			}
			.tariff-card.water:nth-child(7) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-7-water-url no-repeat center top; 
			}
			.tariff-card.water:nth-child(8) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-8-water-url no-repeat center top; 
			}
			.tariff-card.water:nth-child(9) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-9-water-url no-repeat center top; 
			}
			
			.tariff-card.thermal:nth-child(2) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-2-thermal-url no-repeat center top; 
			}
			.tariff-card.thermal:nth-child(3) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-3-thermal-url no-repeat center top; 
			}
			.tariff-card.thermal:nth-child(4) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-4-thermal-url no-repeat center top;
			}
			.tariff-card.thermal:nth-child(5) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-5-thermal-url no-repeat center top; 
			}
			.tariff-card.thermal:nth-child(6) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-6-thermal-url no-repeat center top; 
			}
			.tariff-card.thermal:nth-child(7) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-7-thermal-url no-repeat center top; 
			}
			.tariff-card.thermal:nth-child(8) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-8-thermal-url no-repeat center top; 
			}
			.tariff-card.thermal:nth-child(9) .tariff-img-container {
				background: @tariff-card-image-background-color @tariff-card-image-nth-child-9-thermal-url no-repeat center top; 
			}
			
			
			.tariff-card.recommended-tariff {
				order: 2;
				border: @recommended-tariff-card-border;
				border-radius: @recommended-tariff-card-border-radius;
				
				.card-header {
					border-top-left-radius: @recommended-tariff-card-border-radius;
					border-top-right-radius: @recommended-tariff-card-border-radius;
					background-color: @recommended-tariff-card-header-background-color;
					border-bottom: @recommended-tariff-card-header-border-bottom;
					position: relative;
					
					.card-title {
						font-family: @recommended-tariff-card-title-font-family;
						font-weight: @recommended-tariff-card-title-font-weight;
						font-size: @recommended-tariff-card-title-font-size;
						color: @recommended-tariff-card-title-color;					
					}
					
					.top-tariff-label {
						position: absolute;
						top: @recommended-tariff-card-top-tariff-label-top;
						right: @recommended-tariff-card-top-tariff-label-right;
						background-color: @recommended-tariff-card-top-tariff-label-background-color;
						font-family: @recommended-tariff-card-top-tariff-label-font-family;
						font-weight: @recommended-tariff-card-top-tariff-label-font-weight;
						font-size: @recommended-tariff-card-top-tariff-label-font-size;
						color: @recommended-tariff-card-top-tariff-label-color;
						text-transform: @recommended-tariff-card-top-tariff-label-text-transform;
						padding: 0px 0.5rem;
						letter-spacing: 1px;	
					}
					
					.card-title-price,
					.fa-caret-down,
					.fa-caret-up {
						display: none;
					}
				}
				.card-body {
					background-color: @recommended-tariff-card-body-background-color;
					
					.tariff-img-container {
						background-color: @recommended-tariff-card-image-background-color;
					}
					.advantages	{
						backgrround-color: @recommended-tariff-card-advantages-background-color;
						
						ul {
							li {
								&:before {
									color: @recommended-tariff-card-advantages-icon-color;
								}
							}
						}
					}
				}
				.card-footer {
					background-color: @recommended-tariff-card-footer-background-color;
					border-bottom-left-radius: @recommended-tariff-card-border-radius;
					border-bottom-right-radius: @recommended-tariff-card-border-radius;
					
					.prices-row {
						background-color: @recommended-tariff-card-prices-row-background-color;
						
						.gross-price {	
							font-family: @recommended-tariff-card-prices-row-gross-price-font-family;
							font-weight: @recommended-tariff-card-prices-row-gross-price-font-weight;
							font-size: @recommended-tariff-card-prices-row-gross-price-font-size;
							color: @recommended-tariff-card-prices-row-gross-price-color;
						}
						.saving {
							font-family: @recommended-tariff-card-prices-row-saving-font-family;
							font-weight: @recommended-tariff-card-prices-row-saving-font-weight;
							font-size: @recommended-tariff-card-prices-row-saving-font-size;
							color: @recommended-tariff-card-prices-row-saving-color;
						}	
					}
					.tariff-details-link {
						background-color: @recommended-tariff-card-tariff-details-link-background-color;
					}
					.tariff-details-row {
						background-color: @recommended-tariff-card-tariff-details-row-background-color;
						font-size: @recommended-tariff-card-tariff-details-row-font-size;
						color: @recommended-tariff-card-tariff-details-row-color;					
					}
					.tariff-informations-link {
						background-color: @recommended-tariff-card-tariff-informations-link-background-color;
					}
					.tariff-informations-row {
						background-color: @recommended-tariff-card-tariff-informations-row-background-color;
						font-size: @recommended-tariff-card-tariff-informations-row-font-size;
						color: @recommended-tariff-card-tariff-informations-row-color;
					}
					.future-tariff-details-row {
						background-color: @recommended-tariff-card-future-tariff-details-row-background-color;
						font-size: @recommended-tariff-card-future-tariff-details-row-font-size;
						color: @recommended-tariff-card-future-tariff-details-row-color;					
					}
				}
				.card-body {
					.prices-row {
						background-color: @recommended-tariff-card-prices-row-background-color;
						
						.gross-price {	
							font-family: @recommended-tariff-card-prices-row-gross-price-font-family;
							font-weight: @recommended-tariff-card-prices-row-gross-price-font-weight;
							font-size: @recommended-tariff-card-prices-row-gross-price-font-size;
							color: @recommended-tariff-card-prices-row-gross-price-color;
						}
						.saving {
							font-family: @recommended-tariff-card-prices-row-saving-font-family;
							font-weight: @recommended-tariff-card-prices-row-saving-font-weight;
							font-size: @recommended-tariff-card-prices-row-saving-font-size;
							color: @recommended-tariff-card-prices-row-saving-color;
						}	
					}
					.tariff-details-link {
						background-color: @recommended-tariff-card-tariff-details-link-background-color;
					}
					.tariff-details-row {
						background-color: @recommended-tariff-card-tariff-details-row-background-color;
						font-size: @recommended-tariff-card-tariff-details-row-font-size;
						color: @recommended-tariff-card-tariff-details-row-color;					
					}
					.tariff-informations-link {
						background-color: @recommended-tariff-card-tariff-informations-link-background-color;
					}
					.tariff-informations-row {
						background-color: @recommended-tariff-card-tariff-informations-row-background-color;
						font-size: @recommended-tariff-card-tariff-informations-row-font-size;
						color: @recommended-tariff-card-tariff-informations-row-color;
					}
					.future-tariff-details-row {
						background-color: @recommended-tariff-card-future-tariff-details-row-background-color;
						font-size: @recommended-tariff-card-future-tariff-details-row-font-size;
						color: @recommended-tariff-card-future-tariff-details-row-color;					
					}
				}
				
			}
			.tariff-card.recommended-tariff.power .tariff-img-container {
				background: @tariff-card-image-background-color @recommended-tariff-card-image-power-url no-repeat center top; 
			}
			.tariff-card.recommended-tariff.powerCharge .tariff-img-container {
				background: @tariff-card-image-background-color @recommended-tariff-card-image-powercharge-url no-repeat center top; 
			}
			.tariff-card.recommended-tariff.gas .tariff-img-container {
				background: @tariff-card-image-background-color @recommended-tariff-card-image-gas-url no-repeat center top; 
			}
			.tariff-card.recommended-tariff.water .tariff-img-container {
				background: @tariff-card-image-background-color @recommended-tariff-card-image-water-url no-repeat center top; 
			}
			.tariff-card.recommended-tariff.thermal .tariff-img-container {
				background: @tariff-card-image-background-color @recommended-tariff-card-image-thermal-url no-repeat center top; 
			}
			
			
			.tariff-card.tariff-placeholder {
				order: 4;
				border: @tariff-card-placeholder-border;
				border-radius: @tariff-card-placeholder-border-radius;
				background-color: @tariff-card-placeholder-background-color;			
			}
			
			
			@media (max-width: @tariff-display-2-cards-viewport-max-width) {
			
				.tariff-card {				
					width: calc(~'50% - 1rem');
										
					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4),
					&:nth-child(5),
					&:nth-child(6),
					&:nth-child(7),
					&:nth-child(8),
					&:nth-child(9) {
						order: 2;
					}
				}
				.tariff-card.recommended-tariff {
					order: 1;
				}
				.tariff-card.tariff-placeholder {
					order: 3;
				}		
			}
			
			@media (max-width: @tariff-display-1-card-viewport-max-width) {
				.tariff-card {				
					width: 100%;
					
					.card-header {
						cursor: pointer;
						
						.card-title-price {
							font-family: @tariff-card-title-price-font-family;
							font-weight: @tariff-card-title-price-font-weight;
							font-size: @tariff-card-title-price-font-size;
							color: @tariff-card-title-price-color;
							display: inline-block;
							margin-right: 0.25rem;	
						}
						.fa-caret-down {
							display: none;
							color: @tariff-card-title-price-color;
						}
						.fa-caret-up {
							display: inline-block;
							color: @tariff-card-title-price-color;
						}
					}
				}
				.tariff-card.tariff-closed-mobile {
					.card-header {
						border-bottom: 0px;
						border-bottom-left-radius: @tariff-card-border-radius;
						border-bottom-right-radius: @tariff-card-border-radius;
						
						.fa-caret-down {
							display: inline-block;
						}
						.fa-caret-up {
							display: none;
						}	
					}
					.card-body,
					.card-footer {
						display: none !important;
					}
				}
				.tariff-card.recommended-tariff {
					.card-header {
						.card-title-price {
							font-family: @recommended-tariff-card-title-price-font-family;
							font-weight: @recommended-tariff-card-title-price-font-weight;
							font-size: @recommended-tariff-card-title-price-font-size;
							color: @recommended-tariff-card-title-price-color;
							display: inline-block;
							margin-right: 0.25rem;
						}
						.fa-caret-down {
							display: none;
							color: @recommended-tariff-card-title-price-color;
						}
						.fa-caret-up {
							display: inline-block;
							color: @recommended-tariff-card-title-price-color;
						}
					}
				}	
				.tariff-card.recommended-tariff.tariff-closed-mobile {
					.card-header {
						border-bottom: 0px;
						border-bottom-left-radius: @recommended-tariff-card-border-radius;
						border-bottom-right-radius: @recommended-tariff-card-border-radius;
						
						.fa-caret-down {
							display: inline-block;
						}
						.fa-caret-up {
							display: none;
						}	
					}
					.card-body,
					.card-footer {
						display: none !important;
					}
				}
				.tariff-card.tariff-placeholder {
					display: none;
				}	
			}
		}
		
		.more-tariffs-button-row {
			margin-bottom: 1rem;
		}
	}

	.main-process .card .tariffs-display-container.quantity-9 {
		.tariff-cards-display {
			.tariff-card.recommended-tariff:nth-child(1),
			.tariff-card:nth-child(2),
			.tariff-card:nth-child(3) {
				display: flex;
			}
			.tariff-card:nth-child(4),
			.tariff-card:nth-child(5),
			.tariff-card:nth-child(6),
			.tariff-card:nth-child(7),
			.tariff-card:nth-child(8),
			.tariff-card:nth-child(9),
			.tariff-card.tariff-placeholder:nth-child(10),
			.tariff-card.tariff-placeholder:nth-child(11) {
				display: none;
			}
		}
		.tariff-cards-display.display-all-tariffs {
			.tariff-card.recommended-tariff:nth-child(1),
			.tariff-card:nth-child(2),
			.tariff-card:nth-child(3),
			.tariff-card:nth-child(4),
			.tariff-card:nth-child(5),
			.tariff-card:nth-child(6),
			.tariff-card:nth-child(7),
			.tariff-card:nth-child(8),
			.tariff-card:nth-child(9) {
				display: flex; 
			}	
			.tariff-card.tariff-placeholder:nth-child(10),
			.tariff-card.tariff-placeholder:nth-child(11) {
				display: none;
			}
		}

		@media (max-width: @tariff-display-2-cards-viewport-max-width) {
			.tariff-cards-display {
				.tariff-card.recommended-tariff:nth-child(1),
				.tariff-card:nth-child(2) {
					display: flex;
				}
				.tariff-card:nth-child(3),
				.tariff-card:nth-child(4),
				.tariff-card:nth-child(5),
				.tariff-card:nth-child(6),
				.tariff-card:nth-child(7),
				.tariff-card:nth-child(8),
				.tariff-card:nth-child(9),
				.tariff-card.tariff-placeholder:nth-child(10),
				.tariff-card.tariff-placeholder:nth-child(11) {
					display: none;
				}
			}
			.tariff-cards-display.display-all-tariffs {
				.tariff-card.recommended-tariff:nth-child(1),
				.tariff-card:nth-child(2),
				.tariff-card:nth-child(3),
				.tariff-card:nth-child(4),
				.tariff-card:nth-child(5),
				.tariff-card:nth-child(6),
				.tariff-card:nth-child(7),
				.tariff-card:nth-child(8),
				.tariff-card:nth-child(9),
				.tariff-card.tariff-placeholder:nth-child(10) {
					display: flex; 
				}				
				.tariff-card.tariff-placeholder:nth-child(11) {
					display: none;
				}
			}
		}
		@media (max-width: @tariff-display-1-card-viewport-max-width) {
			.tariff-cards-display,
			.tariff-cards-display.display-all-tariffs {
				.tariff-card.recommended-tariff:nth-child(1),
				.tariff-card:nth-child(2),
				.tariff-card:nth-child(3),
				.tariff-card:nth-child(4),
				.tariff-card:nth-child(5),
				.tariff-card:nth-child(6),
				.tariff-card:nth-child(7),
				.tariff-card:nth-child(8),
				.tariff-card:nth-child(9) {
					display: flex;

					.placeholder-row {
						display: none !important;
					}	
				}	
				.tariff-card.tariff-placeholder:nth-child(10),
				.tariff-card.tariff-placeholder:nth-child(11) {
					display: none;
				}
			}			
			.more-tariffs-button-row {
				display: none;
			}	
		}	
	}
	.main-process .card .tariffs-display-container.quantity-8 {
		.tariff-cards-display {		
			.tariff-card.recommended-tariff:nth-child(1),
			.tariff-card:nth-child(2),
			.tariff-card:nth-child(3) {
				display: flex;
			}
			.tariff-card:nth-child(4),
			.tariff-card:nth-child(5),
			.tariff-card:nth-child(6),
			.tariff-card:nth-child(7),
			.tariff-card:nth-child(8),
			.tariff-card.tariff-placeholder:nth-child(9),
			.tariff-card.tariff-placeholder:nth-child(10) {
				display: none;
			}
		}
		.tariff-cards-display.display-all-tariffs {
			.tariff-card.recommended-tariff:nth-child(1),
			.tariff-card:nth-child(2),
			.tariff-card:nth-child(3),
			.tariff-card:nth-child(4),
			.tariff-card:nth-child(5),
			.tariff-card:nth-child(6),
			.tariff-card:nth-child(7),
			.tariff-card:nth-child(8),
			.tariff-card.tariff-placeholder:nth-child(9) {
				display: flex;
			}
			.tariff-card.tariff-placeholder:nth-child(10) {
				display: none;
			}
		}

		@media (max-width: @tariff-display-2-cards-viewport-max-width) {
			.tariff-cards-display {
				.tariff-card.recommended-tariff:nth-child(1),
				.tariff-card:nth-child(2) {
					display: flex;
				}
				.tariff-card:nth-child(3),
				.tariff-card:nth-child(4),
				.tariff-card:nth-child(5),
				.tariff-card:nth-child(6),
				.tariff-card:nth-child(7),
				.tariff-card:nth-child(8),
				.tariff-card.tariff-placeholder:nth-child(9),
				.tariff-card.tariff-placeholder:nth-child(10) {
					display: none;
				}	
			}
			.tariff-cards-display.display-all-tariffs {
				.tariff-card.recommended-tariff:nth-child(1),
				.tariff-card:nth-child(2),
				.tariff-card:nth-child(3),
				.tariff-card:nth-child(4),
				.tariff-card:nth-child(5),
				.tariff-card:nth-child(6),
				.tariff-card:nth-child(7),
				.tariff-card:nth-child(8) {
					display: flex;
				}
				.tariff-card.tariff-placeholder:nth-child(9),
				.tariff-card.tariff-placeholder:nth-child(10) {
					display: none;
				}
			}
		}
		@media (max-width: @tariff-display-1-card-viewport-max-width) {
			.tariff-cards-display,
			.tariff-cards-display.display-all-tariffs {
				.tariff-card.recommended-tariff:nth-child(1),
				.tariff-card:nth-child(2),
				.tariff-card:nth-child(3),
				.tariff-card:nth-child(4),
				.tariff-card:nth-child(5),
				.tariff-card:nth-child(6),
				.tariff-card:nth-child(7),
				.tariff-card:nth-child(8) {
					display: flex; 
				}	
				.tariff-card.tariff-placeholder:nth-child(9),
				.tariff-card.tariff-placeholder:nth-child(10) {
					display: none;
				}
			}			
			.more-tariffs-button-row {
				display: none;
			}	
		}	
	}
	.main-process .card .tariffs-display-container.quantity-7 {
		.tariff-cards-display {
			.tariff-card.recommended-tariff:nth-child(1),
			.tariff-card:nth-child(2),
			.tariff-card:nth-child(3) {
				display: flex;
			}
			.tariff-card:nth-child(4),
			.tariff-card:nth-child(5),
			.tariff-card:nth-child(6),
			.tariff-card:nth-child(7),
			.tariff-card.tariff-placeholder:nth-child(8),
			.tariff-card.tariff-placeholder:nth-child(9) {
				display: none;
			}
		}
		.tariff-cards-display.display-all-tariffs {
			.tariff-card.recommended-tariff:nth-child(1),
			.tariff-card:nth-child(2),
			.tariff-card:nth-child(3),
			.tariff-card:nth-child(4),
			.tariff-card:nth-child(5),
			.tariff-card:nth-child(6),
			.tariff-card:nth-child(7),
			.tariff-card.tariff-placeholder:nth-child(8),
			.tariff-card.tariff-placeholder:nth-child(9) {
				display: flex;
			}
		}

		@media (max-width: @tariff-display-2-cards-viewport-max-width) {
			.tariff-cards-display {
				.tariff-card.recommended-tariff:nth-child(1),
				.tariff-card:nth-child(2) {
					display: flex;
				}
				.tariff-card:nth-child(3),
				.tariff-card:nth-child(4),
				.tariff-card:nth-child(5),
				.tariff-card:nth-child(6),
				.tariff-card:nth-child(7),
				.tariff-card.tariff-placeholder:nth-child(8),
				.tariff-card.tariff-placeholder:nth-child(9) {
					display: none;
				}
			}
			.tariff-cards-display.display-all-tariffs {
				.tariff-card.recommended-tariff:nth-child(1),
				.tariff-card:nth-child(2),
				.tariff-card:nth-child(3),
				.tariff-card:nth-child(4),
				.tariff-card:nth-child(5),
				.tariff-card:nth-child(6),
				.tariff-card:nth-child(7),
				.tariff-card.tariff-placeholder:nth-child(8) {
					display: flex;
				}
				.tariff-card.tariff-placeholder:nth-child(9) {
					display: none;
				}				
			}
		}
		@media (max-width: @tariff-display-1-card-viewport-max-width) {
			.tariff-cards-display,
			.tariff-cards-display.display-all-tariffs {
				.tariff-card.recommended-tariff:nth-child(1),
				.tariff-card:nth-child(2),
				.tariff-card:nth-child(3),
				.tariff-card:nth-child(4),
				.tariff-card:nth-child(5),
				.tariff-card:nth-child(6),
				.tariff-card:nth-child(7) {
					display: flex; 
				}	
				.tariff-card.tariff-placeholder:nth-child(8),
				.tariff-card.tariff-placeholder:nth-child(9) {
					display: none;
				}
			}			
			.more-tariffs-button-row {
				display: none;
			}	
		}		
	}
	.main-process .card .tariffs-display-container.quantity-6 {
		.tariff-cards-display {			
			.tariff-card.recommended-tariff:nth-child(1),
			.tariff-card:nth-child(2),
			.tariff-card:nth-child(3) {
				display: flex;
			}
			.tariff-card:nth-child(4),
			.tariff-card:nth-child(5),
			.tariff-card:nth-child(6),
			.tariff-card.tariff-placeholder:nth-child(7),
			.tariff-card.tariff-placeholder:nth-child(8) {
				display: none;
			}
		}
		.tariff-cards-display.display-all-tariffs {
			.tariff-card.recommended-tariff:nth-child(1),
			.tariff-card:nth-child(2),
			.tariff-card:nth-child(3),
			.tariff-card:nth-child(4),
			.tariff-card:nth-child(5),
			.tariff-card:nth-child(6) {
				display: flex;
			}
			.tariff-card.tariff-placeholder:nth-child(7),
			.tariff-card.tariff-placeholder:nth-child(8) {
				display: none;
			}
		}

		@media (max-width: @tariff-display-2-cards-viewport-max-width) {
			.tariff-cards-display {
				.tariff-card.recommended-tariff:nth-child(1),
				.tariff-card:nth-child(2) {
					display: flex;
				}
				.tariff-card:nth-child(3),	
				.tariff-card:nth-child(4),
				.tariff-card:nth-child(5),
				.tariff-card:nth-child(6),
				.tariff-card.tariff-placeholder:nth-child(7),
				.tariff-card.tariff-placeholder:nth-child(8) {
					display: none;
				}
			}
			.tariff-cards-display.display-all-tariffs {
				.tariff-card.recommended-tariff:nth-child(1),
				.tariff-card:nth-child(2),
				.tariff-card:nth-child(3),
				.tariff-card:nth-child(4),
				.tariff-card:nth-child(5),
				.tariff-card:nth-child(6) {
					display: flex;
				}
				.tariff-card.tariff-placeholder:nth-child(7),
				.tariff-card.tariff-placeholder:nth-child(8) {
					display: none;
				}
			}
		}
		@media (max-width: @tariff-display-1-card-viewport-max-width) {
			.tariff-cards-display,
			.tariff-cards-display.display-all-tariffs {
				.tariff-card.recommended-tariff:nth-child(1),
				.tariff-card:nth-child(2),
				.tariff-card:nth-child(3),
				.tariff-card:nth-child(4),
				.tariff-card:nth-child(5),
				.tariff-card:nth-child(6) {
					display: flex; 
				}	
				.tariff-card.tariff-placeholder:nth-child(7),
				.tariff-card.tariff-placeholder:nth-child(8) {
					display: none;
				}
			}			
			.more-tariffs-button-row {
				display: none;
			}	
		}			
	}
	.main-process .card .tariffs-display-container.quantity-5 {
		.tariff-cards-display {		
			.tariff-card.recommended-tariff:nth-child(1),
			.tariff-card:nth-child(2),
			.tariff-card:nth-child(3) {
				display: flex;
			}
			.tariff-card:nth-child(4),
			.tariff-card:nth-child(5),
			.tariff-card.tariff-placeholder:nth-child(6),
			.tariff-card.tariff-placeholder:nth-child(7) {
				display: none;
			}
		}
		.tariff-cards-display.display-all-tariffs {
			.tariff-card.recommended-tariff:nth-child(1),
			.tariff-card:nth-child(2),
			.tariff-card:nth-child(3),
			.tariff-card:nth-child(4),
			.tariff-card:nth-child(5),
			.tariff-card.tariff-placeholder:nth-child(6) {
				display: flex;
			}
			.tariff-card.tariff-placeholder:nth-child(7) {
				display: none;
			}
		}

		@media (max-width: @tariff-display-2-cards-viewport-max-width) {
			.tariff-cards-display {
				.tariff-card.recommended-tariff:nth-child(1),
				.tariff-card:nth-child(2) {
					display: flex;
				}
				.tariff-card:nth-child(3),
				.tariff-card:nth-child(4),
				.tariff-card:nth-child(5),
				.tariff-card.tariff-placeholder:nth-child(6),
				.tariff-card.tariff-placeholder:nth-child(7) {
					display: none;
				}				
			}
			.tariff-cards-display.display-all-tariffs {
				.tariff-card.recommended-tariff:nth-child(1),
				.tariff-card:nth-child(2),
				.tariff-card:nth-child(3),
				.tariff-card:nth-child(4),
				.tariff-card:nth-child(5),
				.tariff-card.tariff-placeholder:nth-child(6) {
					display: flex;
				}
				.tariff-card.tariff-placeholder:nth-child(7) {
					display: none;
				}
			}
		}
		@media (max-width: @tariff-display-1-card-viewport-max-width) {
			.tariff-cards-display,
			.tariff-cards-display.display-all-tariffs {
				.tariff-card.recommended-tariff:nth-child(1),
				.tariff-card:nth-child(2),
				.tariff-card:nth-child(3),
				.tariff-card:nth-child(4),
				.tariff-card:nth-child(5) {
					display: flex; 
				}	
				.tariff-card.tariff-placeholder:nth-child(6),
				.tariff-card.tariff-placeholder:nth-child(7) {
					display: none;
				}
			}			
			.more-tariffs-button-row {
				display: none;
			}	
		}			
	}
	.main-process .card .tariffs-display-container.quantity-4 {
		.tariff-cards-display {
			.tariff-card.recommended-tariff:nth-child(1),
			.tariff-card:nth-child(2),
			.tariff-card:nth-child(3) {
				display: flex;
			}
			.tariff-card:nth-child(4),
			.tariff-card.tariff-placeholder:nth-child(5),
			.tariff-card.tariff-placeholder:nth-child(6) {
				display: none;
			}
		}
		.tariff-cards-display.display-all-tariffs {
			.tariff-card.recommended-tariff:nth-child(1),
			.tariff-card:nth-child(2),
			.tariff-card:nth-child(3),
			.tariff-card:nth-child(4),
			.tariff-card.tariff-placeholder:nth-child(5),
			.tariff-card.tariff-placeholder:nth-child(6) {
				display: flex;
			}
		}
			
		@media (max-width: @tariff-display-2-cards-viewport-max-width) {
			.tariff-cards-display {
				.tariff-card.recommended-tariff:nth-child(1),
				.tariff-card:nth-child(2) {
					display: flex;
				}
				.tariff-card:nth-child(3),	
				.tariff-card:nth-child(4),
				.tariff-card.tariff-placeholder:nth-child(5),
				.tariff-card.tariff-placeholder:nth-child(6) {
					display: none;
				}
			}
			.tariff-cards-display.display-all-tariffs {
				.tariff-card.recommended-tariff:nth-child(1),
				.tariff-card:nth-child(2),
				.tariff-card:nth-child(3),
				.tariff-card:nth-child(4) {
					display: flex;
				}
				.tariff-card.tariff-placeholder:nth-child(5),
				.tariff-card.tariff-placeholder:nth-child(6) {
					display: none;
				}
			}
		}
		@media (max-width: @tariff-display-1-card-viewport-max-width) {
			.tariff-cards-display,
			.tariff-cards-display.display-all-tariffs {
				.tariff-card.recommended-tariff:nth-child(1),
				.tariff-card:nth-child(2),
				.tariff-card:nth-child(3),
				.tariff-card:nth-child(4) {
					display: flex; 
				}	
				.tariff-card.tariff-placeholder:nth-child(6),
				.tariff-card.tariff-placeholder:nth-child(7) {
					display: none;
				}
			}			
			.more-tariffs-button-row {
				display: none;
			}	
		}				
	}
	.main-process .card .tariffs-display-container.quantity-3 {
		.tariff-cards-display {		
			.tariff-card.recommended-tariff:nth-child(1),
			.tariff-card:nth-child(2),
			.tariff-card:nth-child(3) {
				display: flex;
			}
			.tariff-card.tariff-placeholder:nth-child(4),
			.tariff-card.tariff-placeholder:nth-child(5) {
				display: none;
			}
		}		
		.more-tariffs-button-row {
			display: none;
		}

		@media (max-width: @tariff-display-2-cards-viewport-max-width) {
			.tariff-cards-display {
				.tariff-card.recommended-tariff:nth-child(1),
				.tariff-card:nth-child(2) {
					display: flex;
				}
				.tariff-card:nth-child(3),
				.tariff-card.tariff-placeholder:nth-child(4),
				.tariff-card.tariff-placeholder:nth-child(5) {
					display: none;
				}
			}
			.tariff-cards-display.display-all-tariffs {
				.tariff-card.recommended-tariff:nth-child(1),
				.tariff-card:nth-child(2),
				.tariff-card:nth-child(3),
				.tariff-card.tariff-placeholder:nth-child(4) {
					display: flex;
				}
				.tariff-card.tariff-placeholder:nth-child(5) {
					display: none;
				}
			}
			.more-tariffs-button-row {
				display: block;
			}
		}
		@media (max-width: @tariff-display-1-card-viewport-max-width) {
			.tariff-cards-display,
			.tariff-cards-display.display-all-tariffs {
				.tariff-card.recommended-tariff:nth-child(1),
				.tariff-card:nth-child(2),
				.tariff-card:nth-child(3) {
					display: flex; 
				}	
				.tariff-card.tariff-placeholder:nth-child(6),
				.tariff-card.tariff-placeholder:nth-child(7) {
					display: none;
				}
			}			
			.more-tariffs-button-row {
				display: none;
			}	
		}			
	}
	.main-process .card .tariffs-display-container.quantity-2 {
		.tariff-cards-display {
			.tariff-card {				
				width: calc(~'50% - 1rem');
			}	
			.tariff-card.recommended-tariff:nth-child(1) {
				display: flex;
				order: 1;
			}
			.tariff-card:nth-child(2) {
				display: flex;
				order: 2;
			}
			.tariff-card.tariff-placeholder:nth-child(3),
			.tariff-card.tariff-placeholder:nth-child(4) {
				display: none;
			}
		}		
		.more-tariffs-button-row {
			display: none;
		}
		
		@media (max-width: @tariff-display-2-cards-viewport-max-width) {
			.tariff-cards-display {
				.tariff-card {				
					width: calc(~'50% - 1rem');
				}
			}	
		}
		@media (max-width: @tariff-display-1-card-viewport-max-width) {
			.tariff-cards-display {
				.tariff-card {				
					width: 100%;
				}
			}		
		}	
	}
	.main-process .card .tariffs-display-container.quantity-1 {
		.tariff-cards-display {
			.tariff-card {				
				width: 50%;
				margin: 0px 0px 1rem calc(~'25% + 1rem');
			}	
			.tariff-card.recommended-tariff:nth-child(1) {
				display: flex;
				order: 1;
			}
			.tariff-card.tariff-placeholder:nth-child(2),
			.tariff-card.tariff-placeholder:nth-child(3) {
				display: none;
			}
		}		
		.more-tariffs-button-row {
			display: none;
		}

		@media (max-width: @tariff-display-2-cards-viewport-max-width) {
			.tariff-cards-display {
				.tariff-card {				
					width: 50%;
					margin: 0px 0px 1rem calc(~'25% + 1rem');
				}
			}		
		}
		@media (max-width: @tariff-display-1-card-viewport-max-width) {
			.tariff-cards-display {
				.tariff-card {				
					width: 100%;
					margin: 0px 0px 1rem 1rem;
				}
			}		
		}		
	}
}
@tariff-cards: tariff-cards;


.mixin(base-supplier-card) {
	.main-process .card .base-supplier-card {
		.card-body {
			background-color: @base-supplier-card-body-background-color;
			
			.base-supplier-selection-row {
				background-color: @base-supplier-selection-row-background-color;
				padding: 0.75rem 0.25rem 0.5rem 0.25rem;
				margin-left: -1rem;
				margin-right: -1rem;
				margin-bottom: 0px;
				
				.gross-price-label {
					font-size: @base-supplier-gross-price-label-font-size;
					vertical-align: text-top;	
				}
				.gross-price {
					font-family: @base-supplier-gross-price-font-family;
					font-weight: @base-supplier-gross-price-font-weight;
					font-size: @base-supplier-gross-price-font-size;
					color: @base-supplier-gross-price-color;
					margin-right: 1px;
				}
				
				@media (max-width: @viewport-md-max) {
					padding: 0.5rem 0.25rem;
				}
			}
		}
	}
}
@base-supplier-card: base-supplier-card;