
/* ******************************** */
/*       Default Customizing        */
/* ******************************** */

.mixin(@body-bg-cockpit,@cockpit-bg-type);
.mixin(@body-bg-process,@process-bg-type);
.mixin(@base-layout);
.mixin(@header);
.mixin(@header-menu);
.mixin(@alerts);
.mixin(@popovers);
.mixin(@main-login-card);
.mixin(@cockpit-cards);
.mixin(@context-menu);
.mixin(@process-cards);
.mixin(@form-rows);
.mixin(@form-labels);
.mixin(@form-inputs);
.mixin(@input-groups);
.mixin(@calendar);
.mixin(@file-input);
.mixin(@form-floating);
.mixin(@buttons);
.mixin(@links);
.mixin(@slider);
.mixin(@selects);
.mixin(@autocomplete);
.mixin(@radios);
.mixin(@checkboxes);
.mixin(@meter-value-display);
.mixin(@tabs);
.mixin(@modal-dialogue);
.mixin(@tables);
.mixin(@datatables);
.mixin(@nomoretable);
.mixin(@multi-meter-value-input-table);
.mixin(@tariff-calculator);
.mixin(@tariff-cards);
.mixin(@base-supplier-card);
.mixin(@accordion);
.mixin(@marginal-columns);
.mixin(@footer);
.mixin(@service-status);
.mixin(@cash-payments);
.mixin(@example-invoice);


/* ++++++++++++++++++++++ */
/* Additional Customizing */
/* ++++++++++++++++++++++ */


.btn-customer-message {
	display: block;	
	background-color: @button-primary-background-color;
	font-family: @button-primary-font-family;
	font-weight: @button-primary-font-weight;
	font-size: @button-primary-font-size;
	color: @button-primary-color;
	padding: @button-primary-padding;
	text-decoration: none;
	position: fixed;
	right: -22px;
	top: calc(~'50% - 95.5px');
	z-index: 1035;
	width: 42px;
	height: 191px;
	overflow: hidden;

	span {
		display: inline-block;	
		transform: rotate(-90deg);
		margin-left: -73px;
		margin-top: 74px;
	}
	
	&:hover, &:focus {
		text-decoration: none;
		background-color: @button-primary-background-color-hover-focus;
		color: @button-primary-color-hover-focus;
	}
}

.swjp-head-nav {

	position: absolute;
	top: 120px;
	left: 0px;
	right: 0px;
	z-index: 1031;
	margin: 0px auto 0px auto;

	ul {
		list-style-type: none;
		padding-left: 0px;
		column-gap: 2.4rem;
		margin-bottom: 0px;

		li {
			a {
				text-decoration: none;
				color: @resolution-blue;
				font-family: @font-regular;
				font-weight: @font-regular-weight;
				font-size: 18px;

				&:hover, &:focus {
					text-decoration: none;
					text-shadow: 0 0 1px @resolution-blue;
				}
			}			
			
			&:first-child {
				a {
					color: @lochmara;
					font-weight: @font-bold-weight;
				}	
			}	
		}
	}

	@media (max-width: @viewport-md-max) {

		top: 122px;

		ul li a {
			font-size: 16px;
		}
	}
}	


header {
	#headerContext {
		border-bottom: 1px solid @resolution-blue-80;

		#headerContextToggle {
			padding: 10px;
			width: 40px;
			height: 40px;
			margin-left: 6px;

			.context-toggle-menu-bar {
				display: block;
				width: 20px;
				height: 2px;
				border-radius: 2px;
				background-color: @resolution-blue;
				margin-top: 6px;

				&:first-child {
					margin-top: 0px;
				}
			}			
		}

		.header-context-3-lines,
		.header-context-2-lines,
		.header-context-1-line {
			padding-left: 6px;
		}

		@media (max-width: @viewport-sm-max) {

			#headerContextToggle {
				float: right;
				margin-left: 0px;
				margin-right: 17px;
			}

			.header-context-3-lines,
			.header-context-2-lines,
			.header-context-1-line {
				padding-left: 16px;
			}	
		}
	}

	#header-cage {
		height: @header-height;
		border-bottom: 1px solid @resolution-blue-80;

		@media (max-width: @viewport-sm-max) {
			height: @header-height-sm;
		}
	}
}

#header-menu {

	border: 1px solid @black;
	border-top: 0px;
	border-left: 0px;
	border-right: 0px;
	border-image: linear-gradient(90deg, #002c7700 0%, #002c7780 16%, #002c77cc 26%, #002c77 50%) 1 stretch;
	margin-right: 16px;
	height: 52px;

	.postbox-badge {
		padding: 3px 6px 2px 6px;
	}

	#logout {
		border: 1px solid @white;
		outline: 1px solid @resolution-blue;
		padding: 9px 16px 7px 16px;

		&:hover, &:focus {
			border-color: @resolution-blue-60;
			outline: 1px solid @resolution-blue-60;
		}

		@media (max-width: @viewport-sm-max) {
			padding-top: 3px;
			padding-left: 12px;
			padding-right: 12px;		
		}
	}

	ul.dropdown-menu.show {
		> li {
			> a.dropdown-item {
				&:hover, &:focus {
					text-decoration: underline;
				}
			}
		}
	}
}

.main-panorama .main-login,
.main-panorama .main-context {
	border: 1px solid @black;
	border-top: 0px;
	border-left: 0px;
	border-right: 0px;
	border-image: linear-gradient(90deg, #002c7700 0%, #002c7780 16%, #002c77cc 26%, #002c77 50%) 1 stretch;

	@media (max-width: @viewport-sm-max) {
		border: 0px;
	}
}

.main-panorama {
	.main-login {
		.card {
			.card-header {
				.card-title {
					&:before {
						background: transparent none;
					}
				}
			}
		}
	}
	.main-panorama-image {
		.main-panorama-claim {
			background-color: @gallery-90;
			width: auto;
			line-height: 32px;
			padding: 24px 16px;
			max-width: calc(~'100% - 24px - 24px');
		}
	}

	@media (max-width: @viewport-sm-max) {
		.main-login {
			.card {
				.card-header {
					.card-title {
						&:before {
							width: 0px;
							margin-right: 0px;
						}
					}
				}
			}
		}
	}
}

body.cockpit {
	.main-panorama {
		#main-cockpit-panorama-cage {
			.main-panorama-image {
				background-position: center center;
			}
		}
	}
}

footer {
	.footer-bottom-nav {
		border-top: 1px solid @horizon;

		#footer-bottom-nav-cage {

			@media (max-width: @viewport-sm-max) {
				padding-right: 8px;
			}

			.footer-bottom-nav-link {
				&:hover, &:focus {
					text-shadow: 0 0 1px @resolution-blue;
				}
			}

			.row {
				margin-left: 4px;
				margin-right: 4px;

				> div {
					&:nth-child(1) {
						width: 23%;
					}
					&:nth-child(2) {
						width: 56%;						
						margin-left: -16px;
					}
					&:nth-child(3) {
						width: 23%;
					}

					@media (max-width: @viewport-lg-max) {
						&:nth-child(2) {
							margin-left: 0px;
						}
					}
					@media (max-width: @viewport-md-max) {
						&:nth-child(1) {
							width: 30%;
						}
						&:nth-child(2) {
							width: 70%;							
							text-align: left;
							padding-left: 0px;
							padding-right: 0px;
						}
					}
					@media (max-width: @viewport-sm-max) {
						&:nth-child(1) {
							width: 100%;
							text-align: center;
						}
						&:nth-child(2) {
							width: 100%;
							text-align: center;
						}
					}	
				}
			}	

			.social-media-links {
				text-align: left;

				a {
					color: @resolution-blue;
					text-decoration: none;
					font-size: 18px;
					display: inline-block;
					margin-top: 7px;
					padding-right: 16px;
					
					&:hover, &:focus {
						color: @resolution-blue;
						text-decoration: none;
					}

					&:last-child {
						padding-right: 0px;
					}
				}				
			}	
		}
	}
}

body.cockpit.out #cockpit-cards-container {
	.card {
		.card-body {
			padding-bottom: 1rem;
		}
	}
}

#cockpit-cards-container {
	.card {
		border-left: 0px;
		border-right: 0px;
		border-image: linear-gradient(90deg, rgb(0, 44, 119) 50%, rgba(0, 44, 119, 0) 100%) 1 stretch;

		.alert {
			left: 0px;
			right: 0px;
		}

		.card-body {
			padding-bottom: 0px;
		}
	}
	.card.login,
	.card.product-calculator,
	.card.meter-reading,
	.card.postbox,
	.card.invoice,
	.card.meter,
	.card.prepayment,
	.card.customer,
	.card.contract,
	.card.quick-links,
	.card.customer-account,
	.card.bankdata,
	.card.order-status,
	.card.e-mobility {
		.card-header {
			.card-title {
				&:before {
					background: transparent none;
				}
			}
		}
	}

	form {
		display: contents;

		.card-footer {
			padding-top: 0px;
			padding-bottom: 1rem;
			background-color: @cockpit-card-background-color;
			border-top: 0px;

			.card-button-line {
				padding-top: 0.5rem;
			}
		}
	}

	.card.contract {
		.card-body {
			.branch-icon-before-text.heatingCurrent {
				display: inline-block;
				background: transparent url(../../images/de_DE/svg/icon-heizstrom.svg) no-repeat -1px 5px;
				background-size: 15px 15px;
				padding-left: 15px;	
			}
		}
	}			

	.card.product-calculator {
		.form-floating .bootstrap-select.form-control {
			padding-top: 0.9rem;
			padding-left: 0px;
			padding-right: 0px;

			.btn.dropdown-toggle {
				border-top: 0px;
				border-left: 0px;
				border-right: 0px;
			}
		}

		.radio-branches-row {
			input[type=radio]:not(old) + label {
				div.radio-label {
					.radio-branch-icon.heatingCurrent {
						background: transparent url(../../images/de_DE/svg/icon-heizstrom.svg) no-repeat center center;
						background-size: 18px;
					}
				}
				&:hover, &:focus {
					div.radio-label {
						.radio-branch-icon.heatingCurrent {
							background: transparent url(../../images/de_DE/svg/icon-heizstrom-white.svg) no-repeat center center;
							background-size: 18px;
						}
					}
				}
			}
			input[type=radio]:not(old):checked + label {
				div.radio-label {
					.radio-branch-icon.heatingCurrent {
							background: transparent url(../../images/de_DE/svg/icon-heizstrom-white.svg) no-repeat center center;
							background-size: 18px;
					}
				}
				&:hover, &:focus {
					div.radio-label {
						.radio-branch-icon.heatingCurrent {
								background: transparent url(../../images/de_DE/svg/icon-heizstrom-white.svg) no-repeat center center;
								background-size: 18px;
						}
					}
				}	
			}
		}

		.consumption-buttons.persons {
			margin-top: 0.1rem;

			.consumption-button {
				background: transparent none !important;
				cursor: default;
				height: 25px;
				margin: 8px 0px 12px 0px;
				text-align: center;

				.fa-user {
					color: @resolution-blue-60;
					margin-top: 1px;
					font-size: 24px;
				}

				&:nth-child(1) {
					text-align: left;
					.fa-user {
						margin-left: 16px;
					}	
				}
				&:nth-child(2) {
					text-align: left;
					.fa-user {
						margin-left: 27%;
					}	
				}
				&:nth-child(4) {
					text-align: right;
					.fa-user {
						margin-right: 27%;
					}	
				}
				&:nth-child(5) {
					text-align: right;
					.fa-user {
						margin-right: 16px;
					}	
				}
			}
			.consumption-button.active {
				.fa-user {
					color: @resolution-blue;
				}
			}
		}

		.persons-slider,
		.houses-slider {
			.fa-minus {
				font-size: 12px;
				margin-right: 8px;
			}
			.fa-plus {
				font-size: 12px;
				margin-left: 8px;
			}		
		}
		
		.slider {
			margin-top: 0px;
			margin-left: 8px;
			margin-right: 8px;
			
			.slider-handle {
				cursor: pointer;
			}
		}

		.consumption-buttons.houses {
			margin-top: 0.1rem;

			padding-left: 13px;
			padding-right: 3px;

			.consumption-button {
				width: 27px;
				height: 27px;
				float: left;
				background: transparent url(../../images/de_DE/svg/calc-icon-house-inactive.svg) no-repeat center center;
				background-size: 100%;
				margin: 9px 9px 9px 0px;
				cursor: default;
				flex: initial;
			}
			.consumption-button.active {
				background: transparent url(../../images/de_DE/svg/calc-icon-house.svg) no-repeat center center;
				background-size: 100%;
				&:first-child {
					background-size: 64%;
					background-position-y: bottom;
				}
			}		
		
		}

		.house-size {
			font-size: 14px;

			padding-top: 17px;
		}		
	}	

	@media (max-width: @viewport-sm-max) {
		.card {
			border: 0px;

			.alert {
				top: 0px;
				left: @main-login-card-header-caret-width;
			}

			.card-footer {
				display: none;
			}
			&:nth-child(2n-1) {
				.card-footer {
					background-color: @cockpit-card-body-background-color-sm-uneven;
				}
			}
			&:nth-child(2n) {
				.card-footer {
					background-color: @cockpit-card-body-background-color-sm-even;
				}
			}
		}

		.card.opened-sm {
			.card-footer {
				display: block;
			}
		}

		.card.login,
		.card.product-calculator,
		.card.meter-reading,
		.card.postbox,
		.card.invoice,
		.card.meter,
		.card.prepayment,
		.card.customer,
		.card.contract,
		.card.quick-links,
		.card.customer-account,
		.card.bankdata,
		.card.order-status,
		.card.e-mobility {
			.card-header {
				.card-title {
					&:before {
						width: 0px;
						margin-right: 0px;
					}
				}
			}
		}
	}
}

.btn-primary {
	outline: 1px solid @lochmara;

	&:hover, &:focus {
		outline: 1px solid @curious-blue;
	}
}
.btn-primary.btn-inactive {
	outline: 0px;
	border: 0px;
}
.btn-default {
	outline: 1px solid @resolution-blue;

	&:hover, &:focus {
		outline: 1px solid @resolution-blue-60;
	}
}
.btn-default.btn-inactive {
	outline: 0px;
	border: 0px;
}

.btn-default.more-tariffs {
	outline: 1px solid @resolution-blue;

	&:hover, &:focus {
		outline: 1px solid @resolution-blue-60;
	}
}

.file-input .file-caption .btn-primary.btn-file,
.file-input .file-caption .btn-default.btn-file	{
	outline: 0px;
}

.input-group .btn-primary,
.input-group .btn-default {
	padding: 0.4rem 0.75rem;
	outline: 0px;
}

.bootstrap-select.form-control .btn.dropdown-toggle {
	padding: 0.425rem 0.75rem;

	&:after {
		content: '\f078';
		font-size: 16px;
	}
}
.bootstrap-select.form-control.dropup .btn.dropdown-toggle {
	&:after {
		content: '\f077';
	}
}

.bootstrap-select.form-control .dropdown-menu {
	z-index: 1050;
	max-height: 200px;
}

.dual-choice-radio-button-group {
	input[type="radio"]:not(old) {
		+ label {
			outline: 1px solid @resolution-blue;

			&:hover, &:focus {
				outline: 1px solid @resolution-blue-60;
			}
		}
	}
	input[type=radio]:not(old):checked + label {
		outline: 1px solid @lochmara;

		> span {
			border-color: @lochmara;

			> span {
				border-color: @lochmara;
				background: none @lochmara;
				background-image: -moz-linear-gradient(@lochmara,@lochmara);
				background-image: -ms-linear-gradient(@lochmara,@lochmara);
				background-image: -o-linear-gradient(@lochmara,@lochmara);
				background-image: -webkit-linear-gradient(@lochmara,@lochmara);
				background-image:  linear-gradient(@lochmara,@lochmara);
			}
		}
	}
}

.nav-tabs .nav-link {
	border-radius: 0px;
	border-top-color: @gallery;
	border-left-color: @gallery;
	border-right-color: @gallery;

	&:hover {
		border-top-color: @gallery;
		border-left-color: @gallery;
		border-right-color: @gallery;
	}
}


.main-process {
	.card {
		.tariffs-display-container {
			.tariff-cards-display {
				.tariff-card {
					border-left: 0px;
					border-right: 0px;
					border-image: linear-gradient(90deg, rgb(0, 44, 119) 50%, rgba(0, 44, 119, 0) 100%) 1 stretch;
				}
			}
		}
	}
}

.main-process {
	.card {
		.tariffs-display-container {
			.tariff-cards-display {
				.tariff-card.recommended-tariff {
					border-left: 0px;
					border-right: 0px;
					border-image: linear-gradient(90deg, rgb(0, 44, 119) 50%, rgba(0, 44, 119, 0) 100%) 1 stretch;
				}
			}
		}
	}
}

input[type=radio]:not(old) + label {
	div.radio-label.radio-label-branch-icon.heatingCurrent {
		background: transparent url(../../images/de_DE/svg/icon-heizstrom.svg) no-repeat 12px 1px;
		background-size: 20px 20px;
		padding-left: 38px;
	}	
}
input[type=checkbox]:not(old) + label {
	div.radio-label.checkbox-label-branch-icon.heatingCurrent {
		background: transparent url(../../images/de_DE/svg/icon-heizstrom.svg) no-repeat 12px 1px;
		background-size: 20px 20px;
		padding-left: 38px;
	}	
}

.main-process .card .tariff-calculator-card {
	.accordion {
		.accordion-item {
			.accordion-body {
				.radio-branches-row {
					input[type=radio]:not(old) + label {
						div.radio-label {
							.radio-branch-icon.heatingCurrent {
								background: transparent url(../../images/de_DE/svg/icon-heizstrom.svg) no-repeat center center;
								background-size: 18px;
							}
						}
						&:hover, &:focus {
							div.radio-label {
								.radio-branch-icon.heatingCurrent {
									background: transparent url(../../images/de_DE/svg/icon-heizstrom-white.svg) no-repeat center center;
									background-size: 18px;
								}
							}
						}
					}
					input[type=radio]:not(old):checked + label {
						div.radio-label {
							.radio-branch-icon.heatingCurrent {
								background: transparent url(../../images/de_DE/svg/icon-heizstrom-white.svg) no-repeat center center;
								background-size: 18px;
							}
						}
						&:hover, &:focus {
							div.radio-label {
								.radio-branch-icon.heatingCurrent {
									background: transparent url(../../images/de_DE/svg/icon-heizstrom-white.svg) no-repeat center center;
									background-size: 18px;
								}
							}
						}
					}
				}

				.consumption-buttons.persons {
					margin-top: -9px;

					.consumption-button {
						background: transparent none !important;
						cursor: default;
						height: 25px;
						margin: 8px 0px 12px 0px;
						text-align: center;

						.fa-user {
							color: @resolution-blue-60;
							margin-top: 1px;
							font-size: 24px;
						}

						&:nth-child(1) {
							text-align: left;
							.fa-user {
								margin-left: 16px;
							}	
						}
						&:nth-child(2) {
							text-align: left;
							.fa-user {
								//margin-left: 27%;
							}	
						}
						&:nth-child(4) {
							text-align: right;
							.fa-user {
								//margin-right: 27%;
							}	
						}
						&:nth-child(5) {
							text-align: right;
							.fa-user {
								margin-right: 16px;
							}	
						}
					}
					.consumption-button.active {
						.fa-user {
							color: @resolution-blue;
						}
					}
				}

				.persons-slider,
				.houses-slider {
					.fa-minus {
						font-size: 12px;
						margin-right: 8px;
					}
					.fa-plus {
						font-size: 12px;
						margin-left: 8px;
					}		
				}
				
				.slider {
					margin-top: 0px;
					margin-left: 8px;
					margin-right: 8px;
					
					.slider-handle {
						cursor: pointer;
					}
				}

				.consumption-buttons.houses {
					margin-top: -9px;

					padding-left: 13px;
					padding-right: 3px;

					.consumption-button {
						width: 27px;
						height: 27px;
						float: left;
						background: transparent url(../../images/de_DE/svg/calc-icon-house-inactive.svg) no-repeat center center;
						background-size: 100%;
						margin: 9px 9px 9px 0px;
						cursor: default;
						flex: initial;
					}
					.consumption-button.active {
						background: transparent url(../../images/de_DE/svg/calc-icon-house.svg) no-repeat center center;
						background-size: 100%;
						&:first-child {
							background-size: 64%;
							background-position-y: bottom;
						}
					}		
				
				}

				.house-size {
					font-size: 14px;

					padding-top: 17px;
				}		
			}
		}
	}	
}

.main-marginal-column {
	.card.shopping-cart {
		.card-body {
			.accordion-item {
				.accordion-header {
					.accordion-button {
						.branch-icon-before-text.heatingCurrent {
							display: inline-block;
							background: transparent url(../../images/de_DE/svg/icon-heizstrom-white.svg) no-repeat 0px 2px;
							background-size: 15px 15px;
							padding-left: 20px;	
						}
					}
				}
			}
		}
	}
}

.code-red {
	color: #FF0000 !important;
}
.code-green {
	color: #32a817 !important;
}
.code-blue {
	color: #0a6aa0 !important;
}

::-ms-reveal {
	display: none;
}

.higher-cell {
	padding-bottom: 0.5em;
}