
.mixin(form-rows) {

	.form-row {
		margin-bottom: 1.0rem;
		
		@media (max-width: @viewport-sm-max) {
			margin-bottom: 0.5rem;
		}	
	}
	
	.two-column-form-row {
		margin-bottom: 0.5rem;
	}
}
@form-rows: form-rows;



.mixin(form-labels) {

	.col-form-label {
		font-family: @label-font-family;
		font-weight: @label-font-weight;
		color: @label-color;
		font-size: @label-font-size;
	}	
	
	.col-form-label.alert-danger {
		color: @alert-danger-txt-color;
		background-color: transparent;
	}
	.col-form-label.alert-warning {
		color: @alert-warning-txt-color;
		background-color: transparent;
	}
	.col-form-label.alert-info {
		color: @alert-info-txt-color;
		background-color: transparent;
	}
	
	.confirm-page-label {
		font-family: @confirm-page-label-font-family;
		font-weight: @confirm-page-label-font-weight;
		color: @confirm-page-label-color;
		font-size: @confirm-page-label-font-size;
		
		@media (max-width: @viewport-sm-max) {
			padding-bottom: 0px;
		}
	}
	.confirm-page-value {
		font-family: @confirm-page-value-font-family;
		font-weight: @confirm-page-value-font-weight;
		color: @confirm-page-value-color;
		font-size: @confirm-page-value-font-size;
		
		@media (max-width: @viewport-sm-max) {
			padding-top: 0px;
		}
	}

	.details-page-icon {
		img {
			/*width: 33px;*/
			height: 32px;
			margin-top: 6px;
		}	
	}	
}
@form-labels: form-labels;



.mixin(form-inputs) {

	.form-control::placeholder {
		color: @form-control-placeholder-color;  
	}	
	.form-control.alert-danger::placeholder {
		color: @alert-danger-txt-color;  
	}	
	.form-control.alert-warning::placeholder {
		color: @alert-warning-txt-color;  
	}	
	.form-control.alert-info::placeholder {
		color: @alert-info-txt-color;  
	}
	
	.form-control {
		background-color: @form-control-background-color;
		color: @form-control-color;
		font-family: @form-control-font-family;
		font-weight: @form-control-font-weight;
		font-size: @form-control-font-size;
		border: @form-control-border;
		border-radius: @form-control-border-radius;
		
		&:focus {
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
			background-color: @form-control-background-color-focus;
			color: @form-control-color-focus;
			font-family: @form-control-font-family-focus;
			font-weight: @form-control-font-weight-focus;
			font-size: @form-control-font-size-focus;
			border: @form-control-border-focus;			
		}		
		
		@media (max-width: @viewport-sm-max) {
			font-size: @form-control-font-size-sm;
			
			&:focus {
				font-size: @form-control-font-size-sm-focus;
			}
		}
	}
	
	.form-control:read-only,
	.form-control:read-only:focus {
		background-color: @form-control-readonly-background-color;
		color: @form-control-readonly-color;
		border-color: @form-control-readonly-border-color;
		cursor: default;
	}
	
	.form-control.alert-danger {
		color: @alert-danger-txt-color;
		background-color: @alert-danger-bg-color;
		border-color: @alert-danger-color;
		&:focus {
			color: @alert-danger-txt-color;
			background-color: @alert-danger-bg-color;
			border-color: @alert-danger-color;			
		}
	}
	.form-control.alert-warning {
		color: @alert-warning-txt-color;
		background-color: @alert-warning-bg-color;
		border-color: @alert-warning-color;
		&:focus {
			color: @alert-warning-txt-color;
			background-color: @alert-warning-bg-color;
			border-color: @alert-warning-color;			
		}
	}
	.form-control.alert-info {
		color: @alert-info-txt-color;
		background-color: @alert-info-bg-color;
		border-color: @alert-info-color;
		&:focus {
			color: @alert-info-txt-color;
			background-color: @alert-info-bg-color;
			border-color: @alert-info-color;			
		}
	}

	.form-control.alert-password-weak {
		background-color: @alert-password-weak-bg-color;
		border-color: @alert-password-weak-color;
		&:focus {
			background-color: @alert-password-weak-bg-color;
			border-color: @alert-password-weak-color;	
		}		
	}
	.form-control.alert-password-medium {
		background-color: @alert-password-medium-bg-color;
		border-color: @alert-password-medium-color;	
		&:focus {
			background-color: @alert-password-medium-bg-color;
			border-color: @alert-password-medium-color;	
		}		
	}
	.form-control.alert-password-strong {
		background-color: @alert-password-strong-bg-color;
		border-color: @alert-password-strong-color;
		&:focus {
			background-color: @alert-password-strong-bg-color;
			border-color: @alert-password-strong-color;	
		}		
	}	
	
	textarea {
		resize: none;
	}

	.form-control-plaintext {
		&:hover, &:focus {
			outline: 0px;
			cursor: default;
		}	
	}
	.form-control-plaintext.padded {
		border: 1px solid @main-process-card-background-color;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}

}
@form-inputs: form-inputs;



.mixin(input-groups) {
	.input-group {
		.input-group-text {
			border-radius: 0px;
			border: @input-group-text-border;
			color: @input-group-text-color;
			background-color: @input-group-text-background-color;
			
			&:first-child {
				border-top-left-radius: @form-control-border-radius;
				border-bottom-left-radius: @form-control-border-radius;
			}
			&:last-child {
				border-top-right-radius: @form-control-border-radius;
				border-bottom-right-radius: @form-control-border-radius;
			}
		}
		.input-group-text.readonly {
			background-color: @form-control-readonly-border-color;
			color: @form-control-readonly-color;
			border-color: @form-control-readonly-border-color;
		}
		.input-group-text.alert-danger {
			color: @alert-danger-txt-color;
			background-color: @alert-danger-bg-color;
			border-color: @alert-danger-color;
		}
		.input-group-text.alert-warning {
			color: @alert-warning-txt-color;
			background-color: @alert-warning-bg-color;
			border-color: @alert-warning-color;
		}
		.input-group-text.alert-info {
			color: @alert-info-txt-color;
			background-color: @alert-info-bg-color;
			border-color: @alert-info-color;
		}

		.input-group-text.alert-password-weak {
			color: @alert-password-weak-color;
			background-color: @alert-password-weak-bg-color;
			border-color: @alert-password-weak-color;
		}
		.input-group-text.alert-password-medium {
			color: @alert-password-medium-color;
			background-color: @alert-password-medium-bg-color;
			border-color: @alert-password-medium-color;
		}
		.input-group-text.alert-password-strong {
			color: @alert-password-strong-color;
			background-color: @alert-password-strong-bg-color;
			border-color: @alert-password-strong-color;
		}		
	}
	
	.input-group.password-show-hide {
		.form-control {
			&:focus {
				border-right: 0px;
				
				~ .input-group-append {
					.btn {
						border: @form-control-border-focus;
						background-color: @form-control-background-color-focus;
						color: @form-control-color-focus;
					}	
				}	
			}
		}
		.form-control.alert-danger {
			~ .input-group-append {
				.btn {
					border-color: @alert-danger-color;
					background-color: @alert-danger-bg-color;
					color: @alert-danger-txt-color;
				}	
			}
		}
		.form-control.alert-warning {
			~ .input-group-append {
				.btn {
					border-color: @alert-warning-color;
					background-color: @alert-warning-bg-color;
					color: @alert-warning-txt-color;
				}	
			}
		}
		.form-control.alert-info {
			~ .input-group-append {
				.btn {
					border-color: @alert-info-color;
					background-color: @alert-info-bg-color;
					color: @alert-info-txt-color;
				}	
			}
		}
		.form-control.alert-password-weak {
			~ .input-group-append {
				.btn {
					background-color: @alert-password-weak-bg-color;
					border-color: @alert-password-weak-color;
					color: @alert-password-weak-color;
				}	
			}
		}
		.form-control.alert-password-medium {
			~ .input-group-append {
				.btn {
					background-color: @alert-password-medium-bg-color;
					border-color: @alert-password-medium-color;
					color: @alert-password-medium-color;
				}	
			}
		}
		.form-control.alert-password-strong {			
			~ .input-group-append {
				.btn {
					background-color: @alert-password-strong-bg-color;
					border-color: @alert-password-strong-color;
					color: @alert-password-strong-color;
				}	
			}
		}
		.input-group-append {
			.btn {
				background-color: @form-control-background-color;
				border-radius: 0px;
				border-left: 0px !important;
				border: @form-control-border;
				color: @form-control-color;
												
				&:hover {
					background-color: @form-control-background-color;
					border: @form-control-border;
					color: @form-control-color;
					-webkit-box-shadow: none;
					-moz-box-shadow: none;
					box-shadow: none;
				}
				&:focus {
					-webkit-box-shadow: none;
					-moz-box-shadow: none;
					box-shadow: none;
				}
			}
			
			&:last-child {
				.btn {
					border-top-right-radius: @form-control-border-radius;
					border-bottom-right-radius: @form-control-border-radius;
				}
			}
		}
	}
	
	.input-group-text.input-group-seperator {
		border: 0px;
		background-color: transparent;
	}

	.input-group {
		.btn-primary,
		.btn-default {
			padding: 0.375rem 0.75rem;
			border-radius: 0;
			border-top-right-radius: @form-control-border-radius;
			border-bottom-right-radius: @form-control-border-radius;
		}
	}

	.input-group {
		.bootstrap-select.form-control {
			.btn.dropdown-toggle {
				border-top-right-radius: 0px;
				border-bottom-right-radius: 0px;
			}
		}
	}

	.input-group {
		.input-group-text-radio-checkbox.alert-danger,
		.input-group-text-radio-checkbox.alert-warning,
		.input-group-text-radio-checkbox.alert-info {
			align-items: start;
			padding-top: 0.7rem;
			background-color: transparent;
			border-color: transparent;
		}
	}		
}
@input-groups: input-groups;



.mixin(file-input) {
	.file-input {
		.file-caption {
			.form-control {
				background-color: @form-control-background-color;
				border: @form-control-border;
				border-top-left-radius: @form-control-border-radius;
				border-bottom-left-radius: @form-control-border-radius;
				color: @form-control-color;
				
				&:focus {
					-webkit-box-shadow: none;
					-moz-box-shadow: none;
					box-shadow: none;
					color: @form-control-color-focus;
					border-color: @file-input-border-color-focus;	
				}
			}	
			.form-control.file-processing {
				border: @form-control-border-focus;
				opacity: 1;
			}
			.btn-primary.btn-file {
				border: @file-input-btn-primary-border;
				border-radius: 0;
				border-top-right-radius: @form-control-border-radius;
				border-bottom-right-radius: @form-control-border-radius;
				padding: 0.375rem 0.75rem;
					
				&:hover, &:focus {
					border: @file-input-btn-primary-border-hover;
				}
			}
			.btn-default.btn-file {
				border: @file-input-btn-default-border;
				padding: 0.375rem 0.75rem;
				
				&:hover, &:focus {
					border: @file-input-btn-default-border-hover;
					-webkit-box-shadow: none;
					-moz-box-shadow: none;
					box-shadow: none;
				}
			}
		}
	}
	
	.input-file-alert-danger,
	.input-file-alert-warning,
	.input-file-alert-info {
		.file-input {
			flex-grow: 1;
		}
		.alert {
			border: 0px;
			border-radius: 0px;
			background-color: transparent;
			font-size: @file-input-alert-font-size;
			padding: 0.5rem calc(~'0.75rem + 1px');
		}	
	}
	.input-file-alert-danger {
		.file-caption {
			.form-control::placeholder {
				color: @alert-danger-txt-color;  
			}
			.form-control {
				color: @alert-danger-txt-color;
				background-color: @alert-danger-bg-color;
				border-color: @alert-danger-color;
				&:focus {
					color: @alert-danger-txt-color;
					background-color: @alert-danger-bg-color;
					border-color: @alert-danger-color;			
				}
			}
		}
	}
	.input-file-alert-warning {
		.file-caption {
			.form-control::placeholder {
				color: @alert-warning-txt-color;  
			}
			.form-control {
				color: @alert-warning-txt-color;
				background-color: @alert-warning-bg-color;
				border-color: @alert-warning-color;
				&:focus {
					color: @alert-warning-txt-color;
					background-color: @alert-warning-bg-color;
					border-color: @alert-warning-color;			
				}
			}
		}
	}
	.input-file-alert-info {
		.file-caption {
			.form-control::placeholder {
				color: @alert-info-txt-color;  
			}
			.form-control {
				color: @alert-info-txt-color;
				background-color: @alert-info-bg-color;
				border-color: @alert-info-color;
				&:focus {
					color: @alert-info-txt-color;
					background-color: @alert-info-bg-color;
					border-color: @alert-info-color;			
				}
			}
		}
	}
}
@file-input: file-input;



.mixin(form-floating) {
	.form-floating {

		> .form-control.alert-danger::placeholder {
			color: transparent;  
		}
		.form-control.alert-warning::placeholder {
			color: transparent;  
		}		
		.form-control.alert-info::placeholder {
			color: transparent;  
		}
	
		.form-control {
			border-top: 0px;
			border-left: 0px;
			border-right: 0px;
			padding: 0.5rem 0.75rem 1rem 0.75rem;
			height: calc(~'3rem + 2px');
			background-color: @form-floating-form-control-background-color;
			border-radius: 0px;
			&:focus {
				background-color: @form-floating-form-control-background-color-focus;				
			}			
		}

		label {
			font-size: @form-floating-label-font-size;
			padding: 1rem 0.75rem 0.75rem 0.75rem;
			color: @form-floating-label-color;
			max-width: 100%;
			white-space: nowrap;
			overflow: hidden;
		}

		.form-control.alert-danger,
		.form-control.alert-warning,
		.form-control.alert-info {
			background-color: @form-floating-form-control-background-color;
			&:focus {
				background-color: @form-floating-form-control-background-color-focus;				
			}
		}	

		label.alert-danger {
			background-color: transparent;
			color: @alert-danger-txt-color;
		}
		label.alert-warning {
			background-color: transparent;
			color: @alert-warning-txt-color;
		}
		label.alert-info {
			background-color: transparent;
			color: @alert-info-txt-color;
		}

		> .form-control:focus ~ label,
		> .form-control:not(:placeholder-shown) ~ label {
			opacity: 1;
			transform: translate(-0.75rem, -1rem);
			font-size: @form-floating-label-font-size-focus;	
			color: @form-floating-label-color-focus;
			max-width: calc(~'100% + 0.75rem');
		}

		> .form-control.alert-danger:focus ~ label.alert-danger,
		> .form-control.alert-danger:not(:placeholder-shown) ~ label.alert-danger {
			color: @alert-danger-txt-color;
		}
		> .form-control.alert-warning:focus ~ label.alert-warning,
		> .form-control.alert-warning:not(:placeholder-shown) ~ label.alert-warning {
			color: @alert-warning-txt-color;
		}
		> .form-control.alert-info:focus ~ label.alert-info,
		> .form-control.alert-info:not(:placeholder-shown) ~ label.alert-info {
			color: @alert-info-txt-color;
		}
		
		.form-control:read-only ~ label,
		.form-control:read-only:focus ~ label,
		.form-control:read-only:not(:placeholder-shown) ~ label {
			color: @form-control-readonly-color;
			transform: none;
			font-size: @form-floating-label-font-size;
			max-width: 100%;	
		}

		@media (max-width: @viewport-sm-max) {
			label {
				font-size: @form-floating-label-font-size-sm;
			}
		}		
	}	
	
	.input-group > .form-floating {
		flex: 1 1 auto;
		width: 1%;
		min-width: 0;
		
		~ .input-group-text.info-default,
		~ .input-group-text.alert-danger,
		~ .input-group-text.alert-warning,
		~ .input-group-text.alert-info {
			border-top: 0px;
			border-right: 0px;
			border-left: 0px;
			padding-top: 1.25rem;
			background-color: @form-floating-form-control-background-color;
		}	
	}
	.input-group > .form-floating:focus-within {
		~ .input-group-text.alert-danger,
		~ .input-group-text.alert-warning,
		~ .input-group-text.alert-info {
			background-color: @form-floating-form-control-background-color-focus;
		}
		~ .input-group-text.info-default {
			background-color: @form-floating-form-control-background-color-focus;
			border: @form-control-border-focus;
			border-top: 0px;
			border-right: 0px;
			border-left: 0px;
			transition: border-color 0.15s ease-in-out;
		}
	}
	
	.input-group.password-show-hide > .form-floating {		
			
		.input-group-append {
			.btn {
				background-color: @form-floating-form-control-background-color;
				border-top: 0px !important;
				border-right: 0px !important;
				border-color: @form-control-border;
				height: 100%;
				padding-top: 0.95rem;
				padding-bottom: 0px;
			}
		}
		
		.form-control {
			&:focus {
				~ .input-group-append {
					.btn {
						background-color: @form-floating-form-control-background-color-focus;
					}
				}			
			}
		}				
	
		.form-control.alert-danger,
		.form-control.alert-warning,
		.form-control.alert-info {
			&:focus {
				~ .input-group-append {
					.btn {
						background-color: @form-floating-form-control-background-color-focus !important;
					}
				}
				~ .input-group-text {
					background-color: @form-floating-form-control-background-color-focus;
				}		
			}
			~ .input-group-append {
					.btn {
						background-color: @form-floating-form-control-background-color !important;
					}
			}					
			~ .input-group-text {
				background-color: @form-floating-form-control-background-color;
			}				
		}
		
		.input-group-text {
			border-top: 0px;
			border-left: 0px;
			border-right: 0px;
			padding-top: 1.25rem;
		}
	}	
	
	#cockpit-cards-container {
		@media (max-width: @viewport-sm-max) {
			.card {		
				&:nth-child(2n-1) {
					.card-body {					
						
						.input-group > .form-floating {
							~ .input-group-text.info-default,
							~ .input-group-text.alert-danger,
							~ .input-group-text.alert-warning,
							~ .input-group-text.alert-info {
								background-color: @form-floating-form-control-background-color; //@cockpit-card-body-background-color-sm-uneven;
							}
						}					
					
						.form-control,
						.form-control.alert-danger,
						.form-control.alert-warning,
						.form-control.alert-info {
							background-color: @form-floating-form-control-background-color; //@cockpit-card-body-background-color-sm-uneven;
						}
						.input-group.password-show-hide > .form-floating .input-group-append .btn {
							background-color: @form-floating-form-control-background-color; //@cockpit-card-body-background-color-sm-uneven;
						}
						.input-group.password-show-hide > .form-floating {
							.form-control,
							.form-control.alert-danger,
							.form-control.alert-warning,
							.form-control.alert-info {
								&:focus {
									~ .input-group-append .btn {
										background-color: @form-floating-form-control-background-color !important; //@cockpit-card-body-background-color-sm-uneven !important;
									}
								}
								~ .input-group-append .btn {
									background-color: @form-floating-form-control-background-color !important; //@cockpit-card-body-background-color-sm-uneven !important;
								}
								~ .input-group-text {
									background-color: @form-floating-form-control-background-color; //@cockpit-card-body-background-color-sm-uneven;
								}
							}
						}	
					}
				}
				&:nth-child(2n) {
					.card-body {
					
						.input-group > .form-floating {
							~ .input-group-text.info-default,
							~ .input-group-text.alert-danger,
							~ .input-group-text.alert-warning,
							~ .input-group-text.alert-info {
								background-color: @form-floating-form-control-background-color; //@cockpit-card-body-background-color-sm-even;
							}
						}	
						
						.form-control,
						.form-control.alert-danger,
						.form-control.alert-warning,
						.form-control.alert-info {
							background-color: @form-floating-form-control-background-color; //@cockpit-card-body-background-color-sm-even;
						}
						.input-group.password-show-hide > .form-floating .input-group-append .btn {
							background-color: @form-floating-form-control-background-color; //@cockpit-card-body-background-color-sm-even;
						}
						.input-group.password-show-hide > .form-floating {
							.form-control,
							.form-control.alert-danger,
							.form-control.alert-warning,
							.form-control.alert-info {
								&:focus {
									~ .input-group-append .btn {
										background-color: @form-floating-form-control-background-color !important; //@cockpit-card-body-background-color-sm-even !important;
									}
								}
								~ .input-group-append .btn {
									background-color: @form-floating-form-control-background-color !important; //@cockpit-card-body-background-color-sm-even !important;
								}
								~ .input-group-text {
									background-color: @form-floating-form-control-background-color; //@cockpit-card-body-background-color-sm-even;
								}
							}
						}		
					}
				}
			}
		}
	}


	.main-login {
		.form-floating {

			.form-control {
				background-color: @form-control-login-background-color;
				border-color: @form-control-login-border-color;
				color: @form-control-login-color;

				&:focus {
					background-color: @form-control-login-background-color-focus;
					border-color: @form-control-login-border-color-focus;
					color: @form-control-login-color-focus;

					~ .input-group-append {
						.btn {
							border-top: 0px;
							border-right: 0px;
							background-color: @form-control-login-background-color-focus;
							border-color: @form-control-login-border-color-focus;							
						}
					}
				}	
			}
			
			.form-control:-webkit-autofill,
			.form-control:-webkit-autofill:hover,
			.form-control:-webkit-autofill:focus {
				-webkit-text-fill-color: @form-control-login-color;
				-webkit-box-shadow: 0 0 0px 1000px @form-control-login-background-color inset;
				transition: background-color 9999s ease-in-out 0s;
			}

			.form-control.alert-danger {
				border-color: @alert-danger-color;
				color: @alert-danger-txt-color;	
			}
			.form-control.alert-warning {
				border-color: @alert-warning-color;
				color: @alert-warning-txt-color;
			}
			.form-control.alert-info {
				border-color: @alert-info-color;
				color: @alert-info-txt-color;
			}

			> .form-control:focus ~ label,
			> .form-control:not(:placeholder-shown) ~ label {
				color: @form-control-login-label-color-focus;
			}

			label {
				color: @form-control-login-label-color;
			}

			label.alert-danger {
				background-color: transparent;
				color: @alert-danger-txt-color;
			}
			label.alert-warning {
				background-color: transparent;
				color: @alert-warning-txt-color;
			}
			label.alert-info {
				background-color: transparent;
				color: @alert-info-txt-color;
			}
			
			.input-group-append {
				.btn {
					background-color: @form-control-login-background-color;
				}
			}
			
			~ .input-group-text.alert-danger,
			~ .input-group-text.alert-warning,
			~ .input-group-text.alert-info {
				background-color: @form-control-login-background-color;
			}
		}		
		.form-floating:focus-within {
			~ .input-group-text.alert-danger,
			~ .input-group-text.alert-warning,
			~ .input-group-text.alert-info {
				background-color: @form-control-login-background-color-focus;
			}
		}
		
		.input-group.password-show-hide {		
			> .form-floating {
										
				.form-control {
					&:focus {					
						~ .input-group-append {
							.btn {
								background-color: @form-control-login-background-color-focus !important;
								border-color: @form-control-login-border-color-focus !important;
								color: @form-control-login-icon-color-focus !important; 	
							}
						}
					}
					~ .input-group-append {
						.btn {
							background-color: @form-control-login-background-color !important;
							border-color: @form-control-login-border-color !important;
							color: @form-control-login-icon-color !important;
						}
					}
				}
				.form-control.alert-danger {
					&:focus {					
						~ .input-group-append {
							.btn {
								background-color: @form-control-login-background-color-focus !important;
								border-color: @alert-danger-color !important;
								color: @alert-danger-txt-color !important; 
							}
						}
						~ .input-group-text {
							background-color: @form-control-login-background-color-focus;
						}
					}
					~ .input-group-append {
							.btn {
								background-color: @form-control-login-background-color !important;
								border-color: @alert-danger-color !important;
								color: @alert-danger-txt-color !important;
							}
					}					
					~ .input-group-text {
						background-color: @form-control-login-background-color;
					}
				}
				.form-control.alert-warning {
					&:focus {					
						~ .input-group-append {
							.btn {
								background-color: @form-control-login-background-color-focus !important;
								border-color: @alert-warning-color !important;
								color: @alert-warning-txt-color !important; 
							}
						}
						~ .input-group-text {
							background-color: @form-control-login-background-color-focus;
						}
					}
					~ .input-group-append {
							.btn {
								background-color: @form-control-login-background-color !important;
								border-color: @alert-warning-color !important;
								color: @alert-warning-txt-color !important;
							}
					}		
					~ .input-group-text {
						background-color: @form-control-login-background-color;
					}
				}
				.form-control.alert-info {
					&:focus {					
						~ .input-group-append {
							.btn {
								background-color: @form-control-login-background-color-focus !important;
								border-color: @alert-info-color !important;
								color: @alert-info-txt-color !important; 	
							}
						}
						~ .input-group-text {
							background-color: @form-control-login-background-color-focus;
						}
					}
					~ .input-group-append {
							.btn {
								background-color: @form-control-login-background-color !important;
								border-color: @alert-info-color !important;
								color: @alert-info-txt-color !important;
							}
					}					
					~ .input-group-text {
						background-color: @form-control-login-background-color;
					}
				}
			}
		}
	}

}
@form-floating: form-floating;