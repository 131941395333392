
.mixin(service-status) {

	.service-status {
		text-align: center;
		border-right: 1px solid @main-process-card-background-color;
		padding: 3.5rem 1rem 1rem 1rem;
		font-family: @service-status-font-family;
		font-weight: @service-status-font-weight;
		font-size: @service-status-font-size;
		color: @service-status-color;
		background: @service-status-background-color @service-status-background-image-url no-repeat;
		background-position: @service-status-background-image-position;	
		
		&:last-child {
			border-right: 0px;
		}
		
		@media (max-width: @viewport-sm-max) {
			border-right: 0px;
			text-align: left;
			margin-bottom: 0.5rem;
			background-position: @service-status-background-image-position-sm;
			padding: 1rem 1rem 1rem 3.5rem;
		}
	}
	.service-status.active {
		font-family: @service-status-active-font-family;
		font-weight: @service-status-active-font-weight;
		font-size: @service-status-active-font-size;
		color: @service-status-active-color;
		background: @service-status-active-background-color @service-status-active-background-image-url no-repeat;	
		background-position: @service-status-background-image-position;
		
		@media (max-width: @viewport-sm-max) {
			background-position: @service-status-background-image-position-sm;
		}
	}
	.service-status.finished {
		font-family: @service-status-finished-font-family;
		font-weight: @service-status-finished-font-weight;
		font-size: @service-status-finished-font-size;
		color: @service-status-finished-color;
		background: @service-status-finished-background-color @service-status-finished-background-image-url no-repeat;	
		background-position: @service-status-background-image-position;
		
		@media (max-width: @viewport-sm-max) {
			background-position: @service-status-background-image-position-sm;
		}
	}
}
@service-status: service-status;