
.mixin(tables) {

	.table-responsive {
		border: @table-responsive-border;
		
		.table > :not(:first-child) {
			border-top: 0px;
		}
		.table-bordered > :not(caption) > * {
			border: 0px;
		}
			
		table {
			margin-bottom: 0px;
			
			thead {
				th {
					background-color: @thead-th-background-color;
					font-family: @thead-th-font-family;
					font-weight: @thead-th-font-weight;
					font-size: @thead-font-size;
					color: @thead-th-color;
					border-left: @thead-th-border-left;
					border-top: @thead-th-border-top;
					border-right: @thead-th-border-right;
					border-bottom: @thead-th-border-bottom;
				}
			}
			tbody {
				td {
					border-left: @tbody-td-border-left;
					border-top: @tbody-td-border-top;
					border-right: @tbody-td-border-right;
					border-bottom: @tbody-td-border-bottom;
				}				
			}	
		}
		.table-striped > tbody > tr:nth-of-type(2n+1) > * {
			--bs-table-accent-bg: none;
		}	
		.table-striped > tbody > tr:nth-child(2n+1) > td {
			background-color: @tbody-td-background-color-uneven;
			color: @tbody-td-color-uneven;
		}
		.table-striped > tbody > tr:nth-child(2n) > td {
			background-color: @tbody-td-background-color-even;
			color: @tbody-td-color-even;
		}

		.table-striped > tbody > tr.table-collapse-row {
			td {
				background-color: @tbody-td-collapse-row-background-color;
				
				.fa-minus {
					display: inline-block;
				}
				.fa-plus {
					display: none;
				}
			}
			td.collapsed {
				.fa-minus {
					display: none;
				}
				.fa-plus {
					display: inline-block;
				}
			}
		}	
	}
}
@tables: tables;


.mixin(datatables) {

	.table-responsive.dt-responsive {
		border: 0px;
		overflow-y: hidden;
	}
	
	table.table-bordered.dataTable {
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		
		th {
			border-left: @thead-th-border-left !important;
			border-top: @thead-th-border-top !important;
			border-right: @thead-th-border-right !important;
			border-bottom: @thead-th-border-bottom;
		}
		thead .sorting::after {
			font-family: 'FontAwesome';
			font-weight: 900;
			content: '\f30c\f309';
			//left: 0.5rem;
			opacity: 1.0;
		}
		thead .sorting_asc::after {
			font-family: 'FontAwesome';
			font-weight: 900;
			content: '\f885';
			//left: 0.5rem;
			opacity: 1.0;
		}
		thead .sorting_desc::after {
			font-family: 'FontAwesome';
			font-weight: 900;
			content: '\f160';
			//left: 0.5rem;
			opacity: 1.0;
		}
		thead .sorting::before,
		thead .sorting_asc::before,
		thead .sorting_desc::before	{
			display: none;
		}
		thead .postbox-first-column.sorting::after,
		thead .postbox-first-column.sorting_asc::after,
		thead .postbox-first-column.sorting_desc::after {
			left: 0.5rem;
		}
		td {
			border-left: @tbody-td-border-left !important;
			border-top: @tbody-td-border-top;
			border-right: @tbody-td-border-right !important;
			border-bottom: @tbody-td-border-bottom;
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
		}
		
		tr {
			.fa-envelope {
				display: inline-block;
				color: @postbox-icon-color;
			}
			.fa-envelope-open {
				display: none;
				color: @postbox-icon-read-color;
			}
			
			.datatable-subject {
				cursor: pointer;
				
				.datatable-subject-text {
					font-family: @postbox-link-font-family;
					font-weight: @postbox-link-font-weight;
					color: @postbox-link-color;
					text-decoration: @postbox-link-text-decoration;
					
					&:hover, &:focus {
						color: @postbox-link-color-hover-focus;
						text-decoration: @postbox-link-text-decoration-hover-focus;
					}
				}
				
				.fa-paperclip {
					margin-top: 4px;
					color: @postbox-link-color;
					text-decoration: none;
					
					&:hover, &:focus {
						color: @postbox-link-color-hover-focus;
						text-decoration: none;
					}
				}				
			}
			
			.fa-trash-alt {
				color: @postbox-link-color;
				text-decoration: none;
				
				&:hover, &:focus {
					color: @postbox-link-color-hover-focus;
					text-decoration: none;
				}
			}
			
		}
		tr.read {
			.fa-envelope {
				display: none;
			}
			.fa-envelope-open {
				display: inline-block;
			}
			
			.datatable-subject {
				.datatable-subject-text {
					font-family: @postbox-link-read-font-family;
					font-weight: @postbox-link-read-font-weight;
					color: @postbox-link-read-color;
					
					&:hover, &:focus {
						color: @postbox-link-read-color-hover-focus;
					}
				}
			}	
		}	
		
		.postboxTableMessageContent {
		    background-color: @datatable-postboxTable-message-content-background-color;
			margin: -0.5rem;
			padding: 1rem;
			
			ul {
				margin-left: -1rem;
			}
		}
	}
	
	div.dataTables_wrapper {
		div.dataTables_info {
			padding-top: 1rem;
			padding-bottom: .5rem;
		}
		div.dataTables_paginate ul.pagination {
			
			margin-top: 1rem;
			
			.paginate_button {
				a {
					text-decoration: none;
					padding: .375rem .75rem;
					font-family: @pagination-font-family;
					font-weight: @pagination-font-weight;
					background-color: @pagination-background-color;
					color: @pagination-color;	
					border: @pagination-border;
					-webkit-box-shadow: none !important;
					-moz-box-shadow: none !important;
					box-shadow: none !important;
					
					&:hover, &:focus {
						background-color: @pagination-background-color-hover-focus;
						font-family: @pagination-font-family-hover-focus;
						font-weight: @pagination-font-weight-hover-focus;
						color: @pagination-color-hover-focus;
					}	
				}				
				&:first-child a {
					border-top-left-radius: @pagination-border-radius;
					border-bottom-left-radius: @pagination-border-radius;
				}
				&:last-child a {
					border-top-right-radius: @pagination-border-radius;
					border-bottom-right-radius: @pagination-border-radius;
				}
				/*
				&:not(:first-child) a {
					border-left: 0px;
				}*/			
			}
			.paginate_button.active {
				a {
					font-family: @pagination-active-font-family;
					font-weight: @pagination-active-font-weight;
					background-color: @pagination-active-background-color;
					color: @pagination-active-color;
					
					&:hover, &:focus {
						background-color: @pagination-active-background-color-hover-focus;
						font-family: @pagination-active-font-family-hover-focus;
						font-weight: @pagination-active-font-weight-hover-focus;
						color: @pagination-active-color-hover-focus;
					}	
				}				
			}
			.paginate_button.disabled {
				a {
					background-color: @pagination-disabled-background-color;
					color: @pagination-disabled-color;
					
					&:hover, &:focus {
						background-color: @pagination-disabled-background-color;
						color: @pagination-disabled-color;
						cursor: not-allowed;
					}	
				}				
			}	
		}
		
		@media (max-width: @viewport-sm-max) {
			div.dataTables_info {
				text-align: left;
			}
			div.dataTables_paginate ul.pagination {
				justify-content: right !important;
			}	
		}
		@media (max-width: @viewport-xs-max) {
			div.dataTables_info {
				text-align: center;
			}
			div.dataTables_paginate ul.pagination {
				margin-top: 0px;
				margin-bottom: 0.5rem;
				justify-content: center !important;
			}
		}		
	}
}
@datatables: datatables;

.mixin(nomoretable) {  
	.nomoretable{
	
		table {
			width: 100%;
		}
		
		tr:nth-child(2n+1) > td {
			background-color: @tbody-td-background-color-uneven;
		}
		tr:nth-child(2n) > td {
			background-color: @tbody-td-background-color-even;
		}  
		td, th { 
		  padding: 0.5rem 0.5rem;
		  text-align: left;
		}
		@media (max-width: @viewport-sm-max) {

			table, thead, tbody, th, td, tr { 
				display: block; 
			}
			
			thead tr { 
				position: absolute;
				top: -9999px;
				left: -9999px;
			}
												
			td { 
				position: relative;
				padding-left: 50%;
				text-align: left;
			}
									
			td:before { 
				content: attr(data-title);
				font-family: @thead-th-font-family;
				font-weight: @thead-th-font-weight;
				font-size: @thead-font-size;
				color: @thead-th-color;
				position: absolute;
				top: 0px;
				left: 0px;
				width: calc(~'50% - 0.5rem');
				height: 100%;
				padding-right: 0.5rem;
				padding-left: 0.5rem;
				padding-top: 0.5rem;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}
@nomoretable: nomoretable;


.mixin(multi-meter-value-input-table) {

	.multi-meter-value-input-table {
		width: 100%;
		
		.multi-meter-value-input-table-thead-tr {
			width: 100%;
			
			.multi-meter-value-input-table-thead-tr-th {
				background-color: @thead-th-background-color;
				font-family: @thead-th-font-family;
				font-weight: @thead-th-font-weight;
				font-size: @thead-font-size;
				color: @thead-th-color;
				border-left: @thead-th-border-left;
				border-top: @thead-th-border-top;
				border-right: @thead-th-border-right;
				border-bottom: @thead-th-border-bottom;
				padding: 0.5rem 0.5rem;
				width: 17%;
				vertical-align: middle;
				
				&:first-child {
					width: 25%;
				}
				&:last-child {
					width: 58%;
					padding: 0px;
					
					.d-table {
						width: 100%;
							
						.d-table-cell {
							padding: 0.5rem 0.5rem;
							width: 50%;
						}	
					}
				}
			}
		}
		.multi-meter-value-input-table-tbody-tr {
			width: 100%;
			
			&:nth-child(2n) {
				background-color: @tbody-td-background-color-uneven;				
			}
			&:nth-child(2n+1) {
				background-color: @tbody-td-background-color-even;
			}
			
			.multi-meter-value-input-table-tbody-tr-td {
				border-left: @tbody-td-border-left;
				border-top: @tbody-td-border-top;
				border-right: @tbody-td-border-right;
				border-bottom: @tbody-td-border-bottom;
				padding: 0.5rem 0.5rem;
				width: 17%;
				
				&:first-child {
					width: 25%;
				}
				&:last-child {
					width: 58%;
					padding: 0px;
					
					.d-sm-table {
						width: 100%;
					
						.d-sm-table-cell {
							padding: 0.5rem 0.5rem;
							width: 50%;						
						}	
					}
					
					.last-reading-date {
						font-size: 80%;
						margin-top: .25rem;
						white-space: nowrap;
					}
					.last-meter-value {
						font-size: 80%;
						margin-top: 0.25rem;
						white-space: nowrap;
					}
					
					.prefixed-multi-meter {
					
						.input-group {
							.col-form-label {
								padding-right: 0.5rem;
							}
						}
						.last-reading-date {
							margin-left: 26px;
						}
					}
				}
				
				.table-head-sm {
					font-family: @thead-th-font-family;
					font-weight: @thead-th-font-weight;
					font-size: @thead-font-size;
					color: @thead-th-color;
					padding-top: 0px;
				}
			}			
		}
		
		@media (max-width: @viewport-sm-max) {
			
			.multi-meter-value-input-table-tbody-tr {
				clear: both;
				float: left;
								
				.multi-meter-value-input-table-tbody-tr-td {
					width: 50%;
					float: left;
					
					&:first-child {
						width: 50%;
					}					
					&:last-child {
						width: 100%;
					}
				}
			}
		}
		
		@media (max-width: @viewport-xs-max) {
			
			.multi-meter-value-input-table-tbody-tr {								
				.multi-meter-value-input-table-tbody-tr-td {
					width: 100%;

					&:first-child {
						width: 100%;
					}	
					&:last-child {
						.d-sm-table {
							.d-sm-table-cell {
								width: 100%;
							}	
						}
						
						.prefixed-multi-meter {
							.last-meter-value {
								margin-left: 26px;
							}	
						}
					}	
				}
			}
		}	
	}	
}
@multi-meter-value-input-table: multi-meter-value-input-table;