
.mixin(body-bg-cockpit, @cockpit-bg-type) {
	body.cockpit when (@cockpit-bg-type = bgcolor) {
		.mixin(@body-bg-color);
	}
	body.cockpit when (@cockpit-bg-type = bgimage) {
		.mixin(@body-bg-image);
		
		@media (max-width: @viewport-md-max) {
			.mixin(@body-bg-color);
		}
	}
}
@body-bg-cockpit: body-bg-cockpit;


.mixin(body-bg-process, @process-bg-type) {
	body.process when (@process-bg-type = bgcolor) { 
		.mixin(@body-bg-color);
	}
	body.process when (@process-bg-type = bgimage) { 
		.mixin(@body-bg-image);
		
		@media (max-width: @viewport-md-max) {
			.mixin(@body-bg-color);
		}
	}	
}
@body-bg-process: body-bg-process;


.mixin(body-bg-image) {
	background: @body-background-start-color @body-background-image-url fixed no-repeat @body-background-image-position;  
	background-size: @body-background-image-size;
}
@body-bg-image: body-bg-image;


.mixin(body-bg-color) {
	background: none @body-background-start-color; /* old browsers */
	background: -moz-linear-gradient(top, @body-background-start-color 0%, @body-background-end-color 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, @body-background-start-color 0%, @body-background-end-color 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(top, @body-background-start-color 0%, @body-background-end-color 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@body-background-start-color', endColorstr='@body-background-end-color', GradientType=1); /* Gradient IE6-9 */ 
	background-size: auto;
	background-attachment: fixed;
}
@body-bg-color: body-bg-color;
