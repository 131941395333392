
.mixin(main-login-card) {

	.main-panorama {
		.main-login {
			.card {
			
				form {
					display: contents;
				}	
			
				border-radius: @main-login-card-border-radius;
				border: @main-login-card-border;
				background-color: @main-login-card-background-color;
				margin: @main-login-card-margin;
				padding-left: @main-login-card-padding-left;

				.card-header {
					background-color: @main-login-card-header-background-color;
					border-bottom: @main-login-card-header-border-bottom;
					border-radius: 0px;
					border-top-left-radius: @main-login-card-border-radius;
					border-top-right-radius: @main-login-card-border-radius;
					position: relative;
				
					.card-header-caret {
						position: absolute;
						top: 0px;
						left: -@main-login-card-padding-left;
						width: @main-login-card-header-caret-width;
						height: 100%;
						text-align: center;					
						display: none;	
						
						.fa.fa-caret-right {
							display: block;
							color: @main-login-card-header-caret-color;
							font-size: 26px;
							margin-top: 12px;
							margin-left: 3px;	
						}					
						.fa.fa-caret-down {
							display: none;
							color: @main-login-card-header-caret-color;
							font-size: 26px;
							margin-top: 12px;
						}
					}

					.card-title {
						font-family: @main-login-card-title-font-family;
						font-weight: @main-login-card-title-font-weight;
						font-size: @main-login-card-title-font-size;
						color: @main-login-card-title-color;
						border-bottom: @main-login-card-title-border-bottom;
						margin-top: 0px;
						margin-bottom: 0.5rem;
						padding-bottom: 0px;

						&:before {
							content: '';
							width: @main-login-card-header-icon-width;
							height: @main-login-card-header-icon-height;
							display: inline-block;
							margin-left: -70px;
							margin-right: 13px;
							margin-bottom: -17px;
							background: transparent @main-login-card-header-icon-url no-repeat @main-login-card-header-icon-background-position;
							background-size: @main-login-card-header-icon-background-size;
								
							@media (max-width: @viewport-sm-max) {
								margin-left: 0px;
								width: @main-login-card-header-icon-width-sm;
								height: @main-login-card-header-icon-height-sm;
								margin-bottom: -13px;
								margin-right: 10px;
								background: transparent @main-login-card-header-icon-url-sm no-repeat @main-login-card-header-icon-background-position-sm;
								background-size: @main-login-card-header-icon-background-size-sm;
							}
						}
					}					
				}
				.card-body {
					padding-top: 0px;
					padding-bottom: 0.5rem;
					background-color: @main-login-card-body-background-color;
					border-radius: 0px;
										
					.main-login-card-links {
						margin-top: 0.5rem;
					}
					.card-text {
						padding-top: 0px;
					}		
				}
				.card-footer {
					border-top: @main-login-card-footer-border-top;
					border-bottom-left-radius: @main-login-card-border-radius;
					border-bottom-right-radius: @main-login-card-border-radius;
					background-color: @main-login-card-footer-background-color;
					padding-top: 0px;
					padding-bottom: 1rem;
					
					.card-button-line {
						padding-top: 0.5rem;

						.btn-primary {
							color: @main-login-button-primary-color;
							border: @main-login-button-primary-border;
							background-color: @main-login-button-primary-background-color;
							border-radius: @main-login-button-border-radius;
							font-family: @main-login-button-primary-font-family;
							font-weight: @main-login-button-primary-font-weight;
							font-size: @main-login-button-primary-font-size;
							padding: @main-login-button-primary-padding;
							
							&:hover, &:focus {
								color: @main-login-button-primary-color-hover-focus;
								border: @main-login-button-primary-border-hover-focus;
								background-color: @main-login-button-primary-background-color-hover-focus;								
							}
						}	
					}
				}

				.alert {
					position: absolute;
					top: -@main-login-card-border-width;
					left: -@main-login-card-border-width;
					right: -@main-login-card-border-width;
					border-radius: @main-login-card-border-radius;
					z-index: 5;

					.btn-close {
						background: transparent none;
						opacity: 1;
						
						&:focus,&:active {
							border: 0px !important;
							outline: none !important;
							box-shadow: none;
						}
						&:before {
							font-size: 18px;
						}	
					}
				}
			}	

			@media (max-width: @viewport-md-max) {
				.card {
					.card-header {
						.card-title {
							font-size: @main-login-card-title-font-size-md;
							
							&:before {
								margin-bottom: -19px;
							}
						}
					}
				}
			}

			@media (max-width: @viewport-sm-max) {
				
				.card {
					background: -moz-linear-gradient(left, @main-login-card-header-caret-background-color-sm 0%, @main-login-card-header-caret-background-color-sm @main-login-card-header-caret-width, @main-login-card-background-color-sm @main-login-card-header-caret-width, @main-login-card-background-color-sm 100%);
					background: -webkit-linear-gradient(left, @main-login-card-header-caret-background-color-sm 0%, @main-login-card-header-caret-background-color-sm @main-login-card-header-caret-width, @main-login-card-background-color-sm @main-login-card-header-caret-width, @main-login-card-background-color-sm 100%);
					background: linear-gradient(left, @main-login-card-header-caret-background-color-sm 0%, @main-login-card-header-caret-background-color-sm @main-login-card-header-caret-width, @main-login-card-background-color-sm @main-login-card-header-caret-width, @main-login-card-background-color-sm 100%);

					margin: @main-login-card-margin-sm;
					padding-left: @main-login-card-header-caret-width;			

					.card-header {
						background-color: @main-login-card-header-background-color-sm;
						cursor: pointer;
						padding: 6px 1rem 5px 1rem;
						border-top-left-radius: 0px;
						
						.card-header-caret {
							display: block;
							left: -@main-login-card-header-caret-width;	
						}
						.card-title {
							font-size: @main-login-card-title-font-size-sm;
							
							&:before {
								margin-bottom: -13px;
							}
						}
					}
					.card-body {
						background-color: @main-login-card-body-background-color-sm;
						
						.card-text {
							padding-top: 1rem;
						}	
					}
					.card-footer {
						background-color: @main-login-card-footer-background-color-sm;
						border-bottom-left-radius: 0px;
					}	
					
					.alert {
						left: @main-login-card-header-caret-width;
					}	
				}
				.card.opened-sm {
					.card-header-caret {
						.fa.fa-caret-right {
							display: none;							
						}					
						.fa.fa-caret-down {
							display: block;
						}
					}	
				}	
			}
		}
	}	
}
@main-login-card: main-login-card;



.mixin(cockpit-cards) {

	#cockpit-cards-container {
		background-color: @cockpit-cards-container-background-color;
		padding-bottom: @cockpit-cards-container-padding-bottom;
		
		.card {
		
			form {
				display: contents;
			}	
		
			border-radius: @cockpit-card-border-radius;
			border: @cockpit-card-border;
			background-color: @cockpit-card-background-color;
			margin: @cockpit-card-margin;
			width: @cockpit-card-width;
			padding-left: @cockpit-card-padding-left;
			
			.card-header {
				background-color: @cockpit-card-header-background-color;
				border-bottom: @cockpit-card-header-border-bottom;
				border-radius: 0px;
				border-top-left-radius: @cockpit-card-border-radius;
				border-top-right-radius: @cockpit-card-border-radius;
				position: relative;
				
				.card-header-caret {
					position: absolute;
					top: 0px;
					left: -@cockpit-card-padding-left;
					width: @cockpit-card-header-caret-width;
					height: 100%;
					text-align: center;					
					display: none;	
					
					.fa.fa-caret-right {
						display: block;
						color: @cockpit-card-header-caret-color;
						font-size: 26px;
						margin-top: 12px;
						margin-left: 3px;	
					}					
					.fa.fa-caret-down {
						display: none;
						color: @cockpit-card-header-caret-color;
						font-size: 26px;
						margin-top: 12px;
					}
				}
				
				.card-title {
					font-family: @cockpit-card-title-font-family;
					font-weight: @cockpit-card-title-font-weight;
					font-size: @cockpit-card-title-font-size;
					color: @cockpit-card-title-color;
					border-bottom: @cockpit-card-title-border-bottom;
					margin-top: 0px;
					margin-bottom: 0.5rem;
					padding-bottom: 0px;
				}
			}
			.card-body {
				padding-top: 0px;
				padding-bottom: 0.5rem;	
				background-color: @cockpit-card-body-background-color;
				border-radius: 0px;
				font-size: @cockpit-card-body-font-size;
				color: @cockpit-card-body-color;
				
				.card-button-line {
					padding-top: 1rem;
				}
				
				.main-login-card-links {
					margin-top: 0.5rem;
				}

				.card-body-ajax-loader {
					margin-left: -@cockpit-card-padding-left;
				}
				
				.card-text {
					padding-top: 0px;
				}	
			}
			.card-footer {
				border-top: @cockpit-card-footer-border-top;				
				border-bottom-left-radius: @cockpit-card-border-radius;
				border-bottom-right-radius: @cockpit-card-border-radius;
				background-color: @cockpit-card-footer-background-color;
				padding-top: 0px;
				padding-bottom: 1rem;
				
				.card-button-line {
					padding-top: 0.5rem;
				}
			}

			.alert {
				position: absolute;
				top: -@cockpit-card-border-width;
				left: -@cockpit-card-border-width;
				right: -@cockpit-card-border-width;
				border-radius: @cockpit-card-border-radius;
				z-index: 5;	

				.btn-close {
					background: transparent none;
					opacity: 1;
					
					&:focus,&:active {
						border: 0px !important;
						outline: none !important;
						box-shadow: none;
					}
					&:before {
						font-size: 18px;
					}	
				}
			}	
		}
		
		@media (max-width: @viewport-lg-max) {
			.card {
				width: @cockpit-card-width-lg;
			}	
		}
		
		@media (max-width: @viewport-md-max) {
			.card {
				width: @cockpit-card-width-md;
				
				.card-header {
					.card-title {
						font-size: @cockpit-card-title-font-size-md;
						
						&:before {
							margin-bottom: -19px;
						}
					}
				}
			}
		}
		@media (max-width: @viewport-sm-max) {
			padding-bottom: @cockpit-cards-container-padding-bottom-sm;
			
			.card {		
				&:nth-child(2n-1) {				
					background: -moz-linear-gradient(left, @cockpit-card-header-caret-background-color-sm-uneven 0%, @cockpit-card-header-caret-background-color-sm-uneven @cockpit-card-header-caret-width, @cockpit-card-background-color-sm-uneven @cockpit-card-header-caret-width, @cockpit-card-background-color-sm-uneven 100%);
					background: -webkit-linear-gradient(left, @cockpit-card-header-caret-background-color-sm-uneven 0%, @cockpit-card-header-caret-background-color-sm-uneven @cockpit-card-header-caret-width, @cockpit-card-background-color-sm-uneven @cockpit-card-header-caret-width, @cockpit-card-background-color-sm-uneven 100%);
					background: linear-gradient(left, @cockpit-card-header-caret-background-color-sm-uneven 0%, @cockpit-card-header-caret-background-color-sm-uneven @cockpit-card-header-caret-width, @cockpit-card-background-color-sm-uneven @cockpit-card-header-caret-width, @cockpit-card-background-color-sm-uneven 100%);

					.card-header {
						background-color: @cockpit-card-header-background-color-sm-uneven;
						
						.card-header-caret {
							background-color: @cockpit-card-header-caret-background-color-sm-uneven;
						}	
					}
					.card-body {
						background-color: @cockpit-card-body-background-color-sm-uneven;
					}
					.card-footer {
						background-color: @cockpit-card-footer-background-color-sm-uneven;
					}		
				}
				&:nth-child(2n) {				
					background: -moz-linear-gradient(left, @cockpit-card-header-caret-background-color-sm-even 0%, @cockpit-card-header-caret-background-color-sm-even @cockpit-card-header-caret-width, @cockpit-card-background-color-sm-even @cockpit-card-header-caret-width, @cockpit-card-background-color-sm-even 100%);
					background: -webkit-linear-gradient(left, @cockpit-card-header-caret-background-color-sm-even 0%, @cockpit-card-header-caret-background-color-sm-even @cockpit-card-header-caret-width, @cockpit-card-background-color-sm-even @cockpit-card-header-caret-width, @cockpit-card-background-color-sm-even 100%);
					background: linear-gradient(left, @cockpit-card-header-caret-background-color-sm-even 0%, @cockpit-card-header-caret-background-color-sm-even @cockpit-card-header-caret-width, @cockpit-card-background-color-sm-even @cockpit-card-header-caret-width, @cockpit-card-background-color-sm-even 100%);
						
					.card-header {
						background-color: @cockpit-card-header-background-color-sm-even;
						
						.card-header-caret {
							background-color: @cockpit-card-header-caret-background-color-sm-even;
						}	
					}
					.card-body {
						background-color: @cockpit-card-body-background-color-sm-even;
					}
					.card-footer {
						background-color: @cockpit-card-footer-background-color-sm-even;
					}		
				}			
			}
			
			.card {
				width: @cockpit-card-width-sm;
				margin: @cockpit-card-margin-sm;
				padding-left: @cockpit-card-header-caret-width;			
				
				.card-header {
					cursor: pointer;
					padding: 6px 1rem 5px 1rem;
					border-top-left-radius: 0px;
					
					.card-header-caret {
						display: block;
						left: -@cockpit-card-header-caret-width;
					}
					
					.card-title {
						font-size: @cockpit-card-title-font-size-sm;
						
						&:before {
							margin-bottom: -13px;
						}
					}
				}
				.card-body {
					display: none;

					.card-body-ajax-loader {
						margin-left: -@cockpit-card-header-caret-width;
					}
					
					.card-text {
						padding-top: 1rem;
					}	
				}
				.card-footer {
					display: none;
					border-bottom-left-radius: 0px;
				}

				.alert {
					left: @cockpit-card-header-caret-width;
				}	
			}
			.card.login.opened-sm {
				.card-body {
					display: block;
				}
				.card-footer {
					display: block;
				}	
			}
			.card.opened-sm {
				.card-header-caret {
					.fa.fa-caret-right {
						display: none;							
					}					
					.fa.fa-caret-down {
						display: block;
					}
				}					
			}	
		}
		
		.card.login,
		.card.product-calculator,
		.card.meter-reading,
		.card.postbox,
		.card.invoice,
		.card.meter,
		.card.prepayment,
		.card.customer,
		.card.contract,
		.card.quick-links,
		.card.customer-account,
		.card.bankdata,
		.card.order-status,
		.card.e-mobility,
		.card.co2-footprint,
		.card.cost-view {
			.card-header {
				.card-title {
					&:before {
						content: '';
						width: @cockpit-card-header-icon-width;
						height: @cockpit-card-header-icon-height;
						display: inline-block;
						margin-left: -70px;
						margin-right: 13px;
						margin-bottom: -17px;
						
						@media (max-width: @viewport-sm-max) {
							margin-left: 0px;
							width: @cockpit-card-header-icon-width-sm;
							height: @cockpit-card-header-icon-height-sm;
							margin-bottom: -13px;
							margin-right: 10px;
						}
					}	
				}
			}
		}
		.card.login .card-header .card-title {
			&:before {
				background: transparent @cockpit-card-header-icon-login-url no-repeat @cockpit-card-header-icon-login-background-position;
				background-size: @cockpit-card-header-icon-login-background-size;
				
				@media (max-width: @viewport-sm-max) {
					background: transparent @cockpit-card-header-icon-login-url-sm no-repeat @cockpit-card-header-icon-login-background-position-sm;
					background-size: @cockpit-card-header-icon-login-background-size-sm;
				}
			}			
		}
		.card.product-calculator .card-header .card-title {
			&:before {
				background: transparent @cockpit-card-header-icon-product-calculator-url no-repeat @cockpit-card-header-icon-product-calculator-background-position;
				background-size: @cockpit-card-header-icon-product-calculator-background-size;
			}	
		}
		.card.meter-reading .card-header .card-title {
			&:before {
				background: transparent @cockpit-card-header-icon-meter-reading-url no-repeat @cockpit-card-header-icon-meter-reading-background-position;
				background-size: @cockpit-card-header-icon-meter-reading-background-size;
			}		
		}
		.card.e-mobility .card-header .card-title {
			&:before {
				background: transparent @cockpit-card-header-icon-e-mobility-url no-repeat @cockpit-card-header-icon-e-mobility-background-position;
				background-size: @cockpit-card-header-icon-e-mobility-background-size;
			}		
		}
		.card.postbox .card-header .card-title {
			&:before {
				background: transparent @cockpit-card-header-icon-postbox-url no-repeat @cockpit-card-header-icon-postbox-background-position;
				background-size: @cockpit-card-header-icon-postbox-background-size;
			}		
		}
		.card.invoice .card-header .card-title {
			&:before {
				background: transparent @cockpit-card-header-icon-invoice-url no-repeat @cockpit-card-header-icon-invoice-background-position;
				background-size: @cockpit-card-header-icon-invoice-background-size;
			}		
		}
		.card.meter .card-header .card-title {
			&:before {
				background: transparent @cockpit-card-header-icon-meter-url no-repeat @cockpit-card-header-icon-meter-background-position;
				background-size: @cockpit-card-header-icon-meter-background-size;
			}		
		}
		.card.prepayment .card-header .card-title {
			&:before {
				background: transparent @cockpit-card-header-icon-prepayment-url no-repeat @cockpit-card-header-icon-prepayment-background-position;
				background-size: @cockpit-card-header-icon-prepayment-background-size;
			}		
		}
		.card.customer .card-header .card-title {
			&:before {
				background: transparent @cockpit-card-header-icon-customer-url no-repeat @cockpit-card-header-icon-customer-background-position;
				background-size: @cockpit-card-header-icon-customer-background-size;
			}		
		}
		.card.contract {
			.card-header .card-title {
				&:before {
					background: transparent @cockpit-card-header-icon-contract-url no-repeat @cockpit-card-header-icon-contract-background-position;
					background-size: @cockpit-card-header-icon-contract-background-size;
				}	
			}
			.card-body {
				.branch-icon-before-text.power {
					display: inline-block;
					background: transparent @cockpit-card-body-icon-contract-branch-icon-power-url no-repeat @cockpit-card-body-icon-contract-branch-icon-power-background-position;
					background-size: @cockpit-card-body-icon-contract-branch-icon-power-background-size;
					padding-left: 15px;	
				}			
				.branch-icon-before-text.gas {
					display: inline-block;
					background: transparent @cockpit-card-body-icon-contract-branch-icon-gas-url no-repeat @cockpit-card-body-icon-contract-branch-icon-gas-background-position;
					background-size: @cockpit-card-body-icon-contract-branch-icon-gas-background-size;
					padding-left: 16px;	
				}
				.branch-icon-before-text.water {
					display: inline-block;
					background: transparent @cockpit-card-body-icon-contract-branch-icon-water-url no-repeat @cockpit-card-body-icon-contract-branch-icon-water-background-position;
					background-size: @cockpit-card-body-icon-contract-branch-icon-water-background-size;
					padding-left: 20px;	
				}
				.branch-icon-before-text.thermal {
					display: inline-block;
					background: transparent @cockpit-card-body-icon-contract-branch-icon-thermal-url no-repeat @cockpit-card-body-icon-contract-branch-icon-thermal-background-position;
					background-size: @cockpit-card-body-icon-contract-branch-icon-thermal-background-size;
					padding-left: 20px;	
				}
				.branch-icon-before-text.powerCharge {
					display: inline-block;
					background: transparent @cockpit-card-body-icon-contract-branch-icon-powercharge-url no-repeat @cockpit-card-body-icon-contract-branch-icon-powercharge-background-position;
					background-size: @cockpit-card-body-icon-contract-branch-icon-powercharge-background-size;
					padding-left: 20px;	
				}
			}	
		}
		.card.customer-account .card-header .card-title {
			&:before {
				background: transparent @cockpit-card-header-icon-customer-account-url no-repeat @cockpit-card-header-icon-customer-account-background-position;
				background-size: @cockpit-card-header-icon-customer-account-background-size;
			}		
		}
		.card.bankdata .card-header .card-title {
			&:before {
				background: transparent @cockpit-card-header-icon-bankdata-url no-repeat @cockpit-card-header-icon-bankdata-background-position;
				background-size: @cockpit-card-header-icon-bankdata-background-size;
			}		
		}
		.card.order-status .card-header .card-title {
			&:before {
				background: transparent @cockpit-card-header-icon-order-status-url no-repeat @cockpit-card-header-icon-order-status-background-position;
				background-size: @cockpit-card-header-icon-order-status-background-size;
			}		
		}
		.card.co2-footprint .card-header .card-title {
			&:before {
				background: transparent @cockpit-card-header-icon-co2-footprint-url no-repeat @cockpit-card-header-icon-co2-footprint-background-position;
				background-size: @cockpit-card-header-icon-co2-footprint-background-size;
			}		
		}
		.card.cost-view .card-header .card-title {
			&:before {
				background: transparent @cockpit-card-header-icon-cost-view-url no-repeat @cockpit-card-header-icon-cost-view-background-position;
				background-size: @cockpit-card-header-icon-cost-view-background-size;
			}		
		}	
		
		.card.product-calculator {		
		
			.radio-customer-type-row {
				margin-left: 0px;
				margin-right: 0px;
				
				input[type=radio]:not(old) {
					margin: 0;
					padding: 0;
					font-size: 1em;
					opacity: 0;
				}
				input[type=radio]:not(old) + label {
					display: inline-block;
					width: 100%;
					float: left;
					background: @cockpit-card-product-calculator-radio-customer-type-background-color none;
					border-right: @cockpit-card-product-calculator-radio-customer-type-border;	

					div.radio-label {
						margin: 0px;
						padding: 0.15rem 0px;
						text-align: center;
						font-family: @cockpit-card-product-calculator-radio-customer-type-font-family;
						font-weight: @cockpit-card-product-calculator-radio-customer-type-font-weight;
						font-size: @cockpit-card-product-calculator-radio-customer-type-font-size;
						color: @cockpit-card-product-calculator-radio-customer-type-color;
					}
					&:hover, &:focus {
						background: @cockpit-card-product-calculator-radio-customer-type-background-color-hover-focus none;
						
						div.radio-label {
							color: @cockpit-card-product-calculator-radio-customer-type-color-hover-focus;
						}
					}
				}
				input[type=radio]:not(old):checked + label {
					background: @cockpit-card-product-calculator-radio-customer-type-checked-background-color none;
					
					div.radio-label {
						color: @cockpit-card-product-calculator-radio-customer-type-checked-color;
					}
					
					&:hover, &:focus {
						background: @cockpit-card-product-calculator-radio-customer-type-checked-background-color-hover-focus none;
						
						div.radio-label {
							color: @cockpit-card-product-calculator-radio-customer-type-checked-color-hover-focus;
						}
					}
				}
				input[type=radio]:not(old) + label > span {
					display: none;
				}

				> *[class^='col-'] { 
					&:first-child {
						input[type=radio]:not(old) + label {
							border-top-left-radius: @cockpit-card-product-calculator-radio-customer-type-border-radius;
						}						
					}
					&:last-child {
						input[type=radio]:not(old) + label {
							border-right: 0px;
							border-top-right-radius: @cockpit-card-product-calculator-radio-customer-type-border-radius;
						}
					}
				}					
			}
			
			.radio-branches-row {
				margin-left: 0rem;
				margin-right: 0rem;
				border-top: @cockpit-card-product-calculator-radio-branches-border;
			
				input[type=radio]:not(old) {
					margin: 0;
					padding: 0;
					font-size: 1em;
					opacity: 0;
				}
				input[type=radio]:not(old) + label {
					display: inline-block;
					width: 100%;
					float: left;
					background: @cockpit-card-product-calculator-radio-branches-background-color none;
					border-right: @cockpit-card-product-calculator-radio-branches-border;	

					div.radio-label {
						margin: 0px;
						padding: 0.15rem 0px;
						text-align: center;
						font-family: @cockpit-card-product-calculator-radio-branches-font-family;
						font-weight: @cockpit-card-product-calculator-radio-branches-font-weight;
						font-size: @cockpit-card-product-calculator-radio-branches-font-size;
						color: @cockpit-card-product-calculator-radio-branches-color;
						
						.radio-branch-icon {
							display: inline-block;
							width: 27px;
							height: 27px;
							vertical-align: bottom;	
						}
						.radio-branch-icon.power {
							background: transparent @cockpit-card-product-calculator-radio-branches-icon-power no-repeat center center;
							background-size: @cockpit-card-product-calculator-radio-branches-icon-power-size;
						}
						.radio-branch-icon.gas {
							background: transparent @cockpit-card-product-calculator-radio-branches-icon-gas no-repeat center center;
							background-size: @cockpit-card-product-calculator-radio-branches-icon-gas-size;
						}
						.radio-branch-icon.water {
							background: transparent @cockpit-card-product-calculator-radio-branches-icon-water no-repeat center center;
							background-size: @cockpit-card-product-calculator-radio-branches-icon-water-size;
						}
						.radio-branch-icon.thermal {
							background: transparent @cockpit-card-product-calculator-radio-branches-icon-thermal no-repeat center center;
							background-size: @cockpit-card-product-calculator-radio-branches-icon-thermal-size;
						}
						.radio-branch-icon.powerCharge {
							background: transparent @cockpit-card-product-calculator-radio-branches-icon-powercharge no-repeat center center;
							background-size: @cockpit-card-product-calculator-radio-branches-icon-powercharge-size;
						}
						.radio-branch-label-text {
							display: none;
						}
					}
					&:hover, &:focus {
						background: @cockpit-card-product-calculator-radio-branches-background-color-hover-focus none;
						
						div.radio-label {
							color: @cockpit-card-product-calculator-radio-branches-color-hover-focus;
							
							.radio-branch-icon.power {
								background: transparent @cockpit-card-product-calculator-radio-branches-icon-power-hover-focus no-repeat center center;
								background-size: @cockpit-card-product-calculator-radio-branches-icon-power-size;
							}
							.radio-branch-icon.gas {
								background: transparent @cockpit-card-product-calculator-radio-branches-icon-gas-hover-focus no-repeat center center;
								background-size: @cockpit-card-product-calculator-radio-branches-icon-gas-size;
							}
							.radio-branch-icon.water {
								background: transparent @cockpit-card-product-calculator-radio-branches-icon-water-hover-focus no-repeat center center;
								background-size: @cockpit-card-product-calculator-radio-branches-icon-water-size;
							}
							.radio-branch-icon.thermal {
								background: transparent @cockpit-card-product-calculator-radio-branches-icon-thermal-hover-focus no-repeat center center;
								background-size: @cockpit-card-product-calculator-radio-branches-icon-thermal-size;
							}
							.radio-branch-icon.powerCharge {
								background: transparent @cockpit-card-product-calculator-radio-branches-icon-powercharge-hover-focus no-repeat center center;
								background-size: @cockpit-card-product-calculator-radio-branches-icon-powercharge-size;
							}
						}
					}
				}
				input[type=radio]:not(old):checked + label {
					background: @cockpit-card-product-calculator-radio-branches-checked-background-color none;
					
					div.radio-label {
						color: @cockpit-card-product-calculator-radio-branches-checked-color;
						
						.radio-branch-icon.power {
							background: transparent @cockpit-card-product-calculator-radio-branches-checked-icon-power no-repeat center center;
							background-size: @cockpit-card-product-calculator-radio-branches-icon-power-size;
						}
						.radio-branch-icon.gas {
							background: transparent @cockpit-card-product-calculator-radio-branches-checked-icon-gas no-repeat center center;
							background-size: @cockpit-card-product-calculator-radio-branches-icon-gas-size;
						}
						.radio-branch-icon.water {
							background: transparent @cockpit-card-product-calculator-radio-branches-checked-icon-water no-repeat center center;
							background-size: @cockpit-card-product-calculator-radio-branches-icon-water-size;
						}
						.radio-branch-icon.thermal {
							background: transparent @cockpit-card-product-calculator-radio-branches-checked-icon-thermal no-repeat center center;
							background-size: @cockpit-card-product-calculator-radio-branches-icon-thermal-size;
						}
						.radio-branch-icon.powerCharge {
							background: transparent @cockpit-card-product-calculator-radio-branches-checked-icon-powercharge no-repeat center center;
							background-size: @cockpit-card-product-calculator-radio-branches-icon-powercharge-size;
						}
						
						.radio-branch-label-text {
							display: inline-block;
						}
					}
					
					&:hover, &:focus {
						background: @cockpit-card-product-calculator-radio-branches-checked-background-color-hover-focus none;
						
						div.radio-label {
							color: @cockpit-card-product-calculator-radio-branches-checked-color-hover-focus;
							
							.radio-branch-icon.power {
								background: transparent @cockpit-card-product-calculator-radio-branches-checked-icon-power-hover-focus no-repeat center center;
								background-size: @cockpit-card-product-calculator-radio-branches-icon-power-size;
							}
							.radio-branch-icon.gas {
								background: transparent @cockpit-card-product-calculator-radio-branches-checked-icon-gas-hover-focus no-repeat center center;
								background-size: @cockpit-card-product-calculator-radio-branches-icon-gas-size;
							}
							.radio-branch-icon.water {
								background: transparent @cockpit-card-product-calculator-radio-branches-checked-icon-water-hover-focus no-repeat center center;
								background-size: @cockpit-card-product-calculator-radio-branches-icon-water-size;
							}
							.radio-branch-icon.thermal {
								background: transparent @cockpit-card-product-calculator-radio-branches-checked-icon-thermal-hover-focus no-repeat center center;
								background-size: @cockpit-card-product-calculator-radio-branches-icon-thermal-size;
							}
							.radio-branch-icon.powerCharge {
								background: transparent @cockpit-card-product-calculator-radio-branches-checked-icon-powercharge-hover-focus no-repeat center center;
								background-size: @cockpit-card-product-calculator-radio-branches-icon-powercharge-size;
							}
						}
					}
				}
				input[type=radio]:not(old) + label > span {
					display: none;
				}				

				> *[class='flex-fill'] {    
					&:first-child {
						input[type=radio]:not(old) + label {
							border-bottom-left-radius: @cockpit-card-product-calculator-radio-branches-border-radius;
						}						
					}
					&:last-child {
						input[type=radio]:not(old) + label {
							border-right: 0px;
							border-bottom-right-radius: @cockpit-card-product-calculator-radio-branches-border-radius;
						}
					}
				}					
			}
			
			.cockpit-card-power-input-container,
			.cockpit-card-gas-input-container,
			.cockpit-card-water-input-container,
			.cockpit-card-thermal-input-container,
			.cockpit-card-power-charge-input-container {
				.col-form-label {
					padding-bottom: 0px;
					font-family: @cockpit-card-product-calculator-input-container-label-font-family;
					font-weight: @cockpit-card-product-calculator-input-container-label-font-weight;
					font-size: @cockpit-card-product-calculator-input-container-label-font-size;
					color: @cockpit-card-product-calculator-input-container-label-color;
				}
				.form-floating {
					margin-top: -0.25rem;
				}
			}
			
			.consumption-buttons {
				.consumption-button {
					height: @cockpit-card-product-calculator-consumption-button-height;
					flex: @cockpit-card-product-calculator-consumption-button-flex;
					border-right: @cockpit-card-product-calculator-consumption-button-border-right;
					cursor: pointer;		
					
					&:first-child {
						border-top-left-radius: @cockpit-card-product-calculator-consumption-button-border-radius;
						border-bottom-left-radius: @cockpit-card-product-calculator-consumption-button-border-radius;
					}
					&:last-child {
						border-right: 0px;
						border-top-right-radius: @cockpit-card-product-calculator-consumption-button-border-radius;
						border-bottom-right-radius: @cockpit-card-product-calculator-consumption-button-border-radius;
					}		
				}
				
				@media (max-width: @viewport-sm-max) {
					margin-top: 1rem;
				}
			}			
			.consumption-buttons.persons {
				.consumption-button:nth-child(1) {
					background: @cockpit-card-product-calculator-consumption-button-background-color @cockpit-card-product-calculator-consumption-button-persons-1-url no-repeat center center;
					background-size: auto 29px;
					
					&:hover {
						background: @cockpit-card-product-calculator-consumption-button-background-color-hover @cockpit-card-product-calculator-consumption-button-persons-1-hover-active-url no-repeat center center;
						background-size: auto 29px;
					}	
				}
				.consumption-button:nth-child(2) {
					background: @cockpit-card-product-calculator-consumption-button-background-color @cockpit-card-product-calculator-consumption-button-persons-2-url no-repeat center center;
					background-size: auto 29px;
					
					&:hover {
						background: @cockpit-card-product-calculator-consumption-button-background-color-hover @cockpit-card-product-calculator-consumption-button-persons-2-hover-active-url no-repeat center center;
						background-size: auto 29px;
					}	
				}
				.consumption-button:nth-child(3) {
					background: @cockpit-card-product-calculator-consumption-button-background-color @cockpit-card-product-calculator-consumption-button-persons-3-url no-repeat center center;
					background-size: auto 29px;
					
					&:hover {
						background: @cockpit-card-product-calculator-consumption-button-background-color-hover @cockpit-card-product-calculator-consumption-button-persons-3-hover-active-url no-repeat center center;
						background-size: auto 29px;
					}	
				}
				.consumption-button:nth-child(4) {
					background: @cockpit-card-product-calculator-consumption-button-background-color @cockpit-card-product-calculator-consumption-button-persons-4-url no-repeat center center;
					background-size: auto 29px;
					
					&:hover {
						background: @cockpit-card-product-calculator-consumption-button-background-color-hover @cockpit-card-product-calculator-consumption-button-persons-4-hover-active-url no-repeat center center;
						background-size: auto 29px;
					}	
				}
				.consumption-button:nth-child(5) {
					background: @cockpit-card-product-calculator-consumption-button-background-color @cockpit-card-product-calculator-consumption-button-persons-5-url no-repeat center center;
					background-size: auto 29px;
					
					&:hover {
						background: @cockpit-card-product-calculator-consumption-button-background-color-hover @cockpit-card-product-calculator-consumption-button-persons-5-hover-active-url no-repeat center center;
						background-size: auto 29px;
					}	
				}
				.consumption-button:nth-child(1).active,
				.consumption-button:nth-child(1).hoveractive {
					background: @cockpit-card-product-calculator-consumption-button-background-color-active @cockpit-card-product-calculator-consumption-button-persons-1-hover-active-url no-repeat center center;
					background-size: auto 29px;
					
					&:hover {
						background: @cockpit-card-product-calculator-consumption-button-background-color-active @cockpit-card-product-calculator-consumption-button-persons-1-hover-active-url no-repeat center center;
						background-size: auto 29px;
					}	
				}
				.consumption-button:nth-child(2).active,
				.consumption-button:nth-child(2).hoveractive {
					background: @cockpit-card-product-calculator-consumption-button-background-color-active @cockpit-card-product-calculator-consumption-button-persons-2-hover-active-url no-repeat center center;
					background-size: auto 29px;
					
					&:hover {
						background: @cockpit-card-product-calculator-consumption-button-background-color-active @cockpit-card-product-calculator-consumption-button-persons-2-hover-active-url no-repeat center center;
						background-size: auto 29px;
					}	
				}
				.consumption-button:nth-child(3).active,
				.consumption-button:nth-child(3).hoveractive {
					background: @cockpit-card-product-calculator-consumption-button-background-color-active @cockpit-card-product-calculator-consumption-button-persons-3-hover-active-url no-repeat center center;
					background-size: auto 29px;
					
					&:hover {
						background: @cockpit-card-product-calculator-consumption-button-background-color-active @cockpit-card-product-calculator-consumption-button-persons-3-hover-active-url no-repeat center center;
						background-size: auto 29px;
					}	
				}
				.consumption-button:nth-child(4).active,
				.consumption-button:nth-child(4).hoveractive {
					background: @cockpit-card-product-calculator-consumption-button-background-color-active @cockpit-card-product-calculator-consumption-button-persons-4-hover-active-url no-repeat center center;
					background-size: auto 29px;
					
					&:hover {
						background: @cockpit-card-product-calculator-consumption-button-background-color-active @cockpit-card-product-calculator-consumption-button-persons-4-hover-active-url no-repeat center center;
						background-size: auto 29px;
					}	
				}
				.consumption-button:nth-child(5).active,
				.consumption-button:nth-child(5).hoveractive {
					background: @cockpit-card-product-calculator-consumption-button-background-color-active @cockpit-card-product-calculator-consumption-button-persons-5-hover-active-url no-repeat center center;
					background-size: auto 29px;
					
					&:hover {
						background: @cockpit-card-product-calculator-consumption-button-background-color-active @cockpit-card-product-calculator-consumption-button-persons-5-hover-active-url no-repeat center center;
						background-size: auto 29px;
					}	
				}
			}
			.consumption-buttons.vehicles {
				.consumption-button:nth-child(1) {
					background: @cockpit-card-product-calculator-consumption-button-background-color @cockpit-card-product-calculator-consumption-button-vehicles-1-url no-repeat center center;
					background-size: auto 40px;
					
					&:hover {
						background: @cockpit-card-product-calculator-consumption-button-background-color-hover @cockpit-card-product-calculator-consumption-button-vehicles-1-hover-active-url no-repeat center center;
						background-size: auto 40px;
					}	
				}
				.consumption-button:nth-child(2) {
					background: @cockpit-card-product-calculator-consumption-button-background-color @cockpit-card-product-calculator-consumption-button-vehicles-2-url no-repeat center center;
					background-size: auto 40px;
					
					&:hover {
						background: @cockpit-card-product-calculator-consumption-button-background-color-hover @cockpit-card-product-calculator-consumption-button-vehicles-2-hover-active-url no-repeat center center;
						background-size: auto 40px;
					}	
				}
				.consumption-button:nth-child(3) {
					background: @cockpit-card-product-calculator-consumption-button-background-color @cockpit-card-product-calculator-consumption-button-vehicles-3-url no-repeat center center;
					background-size: auto 40px;
					
					&:hover {
						background: @cockpit-card-product-calculator-consumption-button-background-color-hover @cockpit-card-product-calculator-consumption-button-vehicles-3-hover-active-url no-repeat center center;
						background-size: auto 40px;
					}	
				}
				.consumption-button:nth-child(4) {
					background: @cockpit-card-product-calculator-consumption-button-background-color @cockpit-card-product-calculator-consumption-button-vehicles-4-url no-repeat center center;
					background-size: auto 40px;
					
					&:hover {
						background: @cockpit-card-product-calculator-consumption-button-background-color-hover @cockpit-card-product-calculator-consumption-button-vehicles-4-hover-active-url no-repeat center center;
						background-size: auto 40px;
					}	
				}
				.consumption-button:nth-child(5) {
					background: @cockpit-card-product-calculator-consumption-button-background-color @cockpit-card-product-calculator-consumption-button-vehicles-5-url no-repeat center center;
					background-size: auto 40px;
					
					&:hover {
						background: @cockpit-card-product-calculator-consumption-button-background-color-hover @cockpit-card-product-calculator-consumption-button-vehicles-5-hover-active-url no-repeat center center;
						background-size: auto 40px;
					}	
				}
				.consumption-button:nth-child(1).active,
				.consumption-button:nth-child(1).hoveractive {
					background: @cockpit-card-product-calculator-consumption-button-background-color-active @cockpit-card-product-calculator-consumption-button-vehicles-1-hover-active-url no-repeat center center;
					background-size: auto 40px;
					
					&:hover {
						background: @cockpit-card-product-calculator-consumption-button-background-color-active @cockpit-card-product-calculator-consumption-button-vehicles-1-hover-active-url no-repeat center center;
						background-size: auto 40px;
					}	
				}
				.consumption-button:nth-child(2).active,
				.consumption-button:nth-child(2).hoveractive {
					background: @cockpit-card-product-calculator-consumption-button-background-color-active @cockpit-card-product-calculator-consumption-button-vehicles-2-hover-active-url no-repeat center center;
					background-size: auto 40px;
					
					&:hover {
						background: @cockpit-card-product-calculator-consumption-button-background-color-active @cockpit-card-product-calculator-consumption-button-vehicles-2-hover-active-url no-repeat center center;
						background-size: auto 40px;
					}	
				}
				.consumption-button:nth-child(3).active,
				.consumption-button:nth-child(3).hoveractive {
					background: @cockpit-card-product-calculator-consumption-button-background-color-active @cockpit-card-product-calculator-consumption-button-vehicles-3-hover-active-url no-repeat center center;
					background-size: auto 40px;
					
					&:hover {
						background: @cockpit-card-product-calculator-consumption-button-background-color-active @cockpit-card-product-calculator-consumption-button-vehicles-3-hover-active-url no-repeat center center;
						background-size: auto 40px;
					}	
				}
				.consumption-button:nth-child(4).active,
				.consumption-button:nth-child(4).hoveractive	{
					background: @cockpit-card-product-calculator-consumption-button-background-color-active @cockpit-card-product-calculator-consumption-button-vehicles-4-hover-active-url no-repeat center center;
					background-size: auto 40px;
					
					&:hover {
						background: @cockpit-card-product-calculator-consumption-button-background-color-active @cockpit-card-product-calculator-consumption-button-vehicles-4-hover-active-url no-repeat center center;
						background-size: auto 40px;
					}	
				}
				.consumption-button:nth-child(5).active,
				.consumption-button:nth-child(5).hoveractive	{
					background: @cockpit-card-product-calculator-consumption-button-background-color-active @cockpit-card-product-calculator-consumption-button-vehicles-5-hover-active-url no-repeat center center;
					background-size: auto 40px;
					
					&:hover {
						background: @cockpit-card-product-calculator-consumption-button-background-color-active @cockpit-card-product-calculator-consumption-button-vehicles-5-hover-active-url no-repeat center center;
						background-size: auto 40px;
					}	
				}
			}			
			
		}

		.card.co2-footprint {
		
			.card-body {
				.co2-output-top {
					background-color: @cockpit-card-co2-footprint-output-top-background-color;
					height: @cockpit-card-co2-footprint-output-top-height;
					border-radius: @cockpit-card-co2-footprint-output-top-border-radius;
					margin-top: 5px;

					.icon-field {
						font-family: @cockpit-card-co2-footprint-output-top-icon-field-font-family;
						font-weight: @cockpit-card-co2-footprint-output-top-icon-field-font-weight;
						font-size: @cockpit-card-co2-footprint-output-top-icon-field-font-size;
						color: @cockpit-card-co2-footprint-output-top-icon-field-color;
					}				
					.output-field{
						font-family: @cockpit-card-co2-footprint-output-top-output-field-font-family;
						font-weight: @cockpit-card-co2-footprint-output-top-output-field-font-weight;
						font-size: @cockpit-card-co2-footprint-output-top-output-field-font-size;
						color: @cockpit-card-co2-footprint-output-top-output-field-color;
						padding: 0px 12px;
					}
					.unit-field{
						font-family: @cockpit-card-co2-footprint-output-top-unit-field-font-family;
						font-weight: @cockpit-card-co2-footprint-output-top-unit-field-font-weight;
						font-size: @cockpit-card-co2-footprint-output-top-unit-field-font-size;
						color: @cockpit-card-co2-footprint-output-top-unit-field-color;
					}
				}
				.co2-image-container {				
					background: @cockpit-card-co2-footprint-image-container-background-color @cockpit-card-co2-footprint-image-container-background-url no-repeat @cockpit-card-co2-footprint-image-container-background-position;
					background-size: @cockpit-card-co2-footprint-image-container-background-size;
					padding: 10px 10px 25px 10px;
					border-top: @cockpit-card-co2-footprint-image-container-border-top;
					border-radius: @cockpit-card-co2-footprint-image-container-border-radius;
					
					.level {
						float: right;
						white-space: nowrap;
						
						.img-field {
							height: @cockpit-card-co2-footprint-level-img-field-height;
							width: @cockpit-card-co2-footprint-level-img-field-width;
							display: inline-block;
							margin-left: 1px;
							margin-bottom: -3px;
						}

						.unit-field{
							font-family: @cockpit-card-co2-footprint-level-unit-field-font-family;
							font-weight: @cockpit-card-co2-footprint-level-unit-field-font-weight;
							color: @cockpit-card-co2-footprint-level-unit-field-color;
							margin-left: 1px;
						}	
					}						
					.level.level-germany {						
						.img-field {
							background: transparent @cockpit-card-co2-footprint-level-germany-url center center;
							background-size: cover;
						}
					}
					.level.level-europe {						
						.img-field{
							background: transparent @cockpit-card-co2-footprint-level-europe-url center center;
							background-size: cover;
						}
					}
					.level.level-world {						
						.img-field{
							background: transparent @cockpit-card-co2-footprint-level-world-url center center;
							background-size: cover;
						}
					}					
				}
			}
			
		}
		
		.card.cost-view {
		
			.card-body {
				.cost-view-prognosis-top {
					background-color: @cockpit-card-cost-view-prognosis-top-background-color;
					font-family: @cockpit-card-cost-view-prognosis-top-font-family;
					font-weight: @cockpit-card-cost-view-prognosis-top-font-weight;
					font-size: @cockpit-card-cost-view-prognosis-top-font-size;
					color: @cockpit-card-cost-view-prognosis-top-color;
					border-radius: @cockpit-card-cost-view-prognosis-top-border-radius;
					margin-top: 5px;
					padding: 0.5rem 1rem;
					border-bottom: @cockpit-card-cost-view-prognosis-top-border-bottom;

					.info-default {
						margin-left: 0.5rem;
					}
				}
				.cost-view-prognosis-bottom {
					background-color: @cockpit-card-cost-view-prognosis-bottom-background-color;
					height: @cockpit-card-cost-view-prognosis-bottom-height;
					border-radius: @cockpit-card-cost-view-prognosis-bottom-border-radius;

					.icon-field {
						font-family: @cockpit-card-cost-view-prognosis-bottom-icon-field-font-family;
						font-weight: @cockpit-card-cost-view-prognosis-bottom-icon-field-font-weight;
						font-size: @cockpit-card-cost-view-prognosis-bottom-icon-field-font-size;
						color: @cockpit-card-cost-view-prognosis-bottom-icon-field-color;
					}	
					.output-field{
						font-family: @cockpit-card-cost-view-prognosis-bottom-output-field-font-family;
						font-weight: @cockpit-card-cost-view-prognosis-bottom-output-field-font-weight;
						font-size: @cockpit-card-cost-view-prognosis-bottom-output-field-font-size;
						color: @cockpit-card-cost-view-prognosis-bottom-output-field-color;
						padding: 0px 12px;
					}
					.unit-field{
						font-family: @cockpit-card-cost-view-prognosis-bottom-unit-field-font-family;
						font-weight: @cockpit-card-cost-view-prognosis-bottom-unit-field-font-weight;
						font-size: @cockpit-card-cost-view-prognosis-bottom-unit-field-font-size;
						color: @cockpit-card-cost-view-prognosis-bottom-unit-field-color;
					}
				}
				.accordion {
					margin-top: 0.5rem;
		
					.accordion-item {
						background: transparent;
						
						.accordion-button::after,
						.accordion-button.collapsed::after {
							background: transparent none;
							width: 0px;
							height: 0px;
						}
						.accordion-button {
							border-radius: @button-border-radius @button-border-radius 0px 0px;

							&:hover, &:focus {
								-webkit-box-shadow: none;
								-moz-box-shadow: none;
								box-shadow: none;
								border: @button-default-border;
							}
		
							.fa-caret-down {
								display: none;
							}
							.fa-caret-up {
								display: inline-block;
							}		
						}
						.accordion-button:not(.collapsed) {
							background-color: @button-default-background-color;
							-webkit-box-shadow: none;
							-moz-box-shadow: none;
							box-shadow: none;
							color: @button-default-color;
							border: @button-default-border;	
						}
						.accordion-button.collapsed {
							border-radius: @button-border-radius @button-border-radius @button-border-radius @button-border-radius;
							
							.fa-caret-down {
								display: inline-block;
							}
							.fa-caret-up {
								display: none;
							}
						}
						.accordion-body {
							background-color: @cockpit-card-cost-view-prognosis-bottom-accordion-background-color;
							border-bottom-left-radius: @button-border-radius;
							border-bottom-right-radius: @button-border-radius;
							font-size: @font-size;
							padding: calc(~'0.5rem - 2px') 1rem 0.5rem 1rem;

							.info-default {
								font-size: 18px;
								margin-left: 0.5rem;
							}
						}
					}				
				}
			}
		}	
		
	}

	
	body.in #cockpit-cards-container {
		.card {
			.card-body {
				.card-button-line {
					padding-top: 0.5rem;
				}	
			}
		}
	}	
}
@cockpit-cards: cockpit-cards;	