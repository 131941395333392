
.mixin(cash-payments) {

	.barzahlen-quad-cards {
		.card {
			.card-body {
				padding: 0px;
				
				h3 {
					margin-top: 0px;
					margin-bottom: 0.5rem;
				}
				ol, ul {
					padding-left: 1rem;
					margin-bottom: 0px;
				}
				.partner-logo {
					margin: 0.5rem;
				}
				#barzahlenMap {
					margin-top: 0.5rem;
					width: 100%;
				}
			}
		}
	}
	
}
@cash-payments: cash-payments;