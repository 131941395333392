
.mixin(footer) {
	footer {
		margin-top: auto !important;
		background: none @footer-background-color;
		width: @footer-width;
		z-index: 55;	

		.footer-top-nav {
			width: @footer-top-nav-width;
			background-color: @footer-top-nav-background-color;
			
			#footer-top-nav-cage {
				width: @footer-top-nav-cage-width;
				max-width: @footer-top-nav-cage-max-width;
				text-align: @footer-top-nav-cage-text-align;
				margin: 0px auto;
				
				.footer-top-nav-link {
					display: inline-block;
					margin: 7px 10px 12px 10px;
					text-decoration: none;
					
					&:before {
						content: '';
						display: inline-block;
						width: 45px;
						height: 45px;
						margin-bottom: -14px;	
					}					
					&:hover, &:focus {
						text-decoration: none;
						
						.footer-top-nav-link-text {
							text-decoration: @footer-top-nav-link-text-decoration-hover-focus;
						}
					}
					&:after {				
						font-family: 'FontAwesome';
						font-weight: bold;
						font-size: 17px;
						content: '\f360';
						color: @footer-top-nav-link-after-color;
						display: block;
						float: right;
						margin-top: 12px;
						margin-left: 5px;	
					}
					.footer-top-nav-link-text {
						color: @footer-top-nav-link-color;
						text-decoration: @footer-top-nav-link-text-decoration;
						font-family: @footer-top-nav-link-font-family;
						font-weight: @footer-top-nav-link-font-weight;
						font-size: @footer-top-nav-link-font-size;					
						
						&:hover, &:focus {
							color: @footer-top-nav-link-color-hover-focus;
							text-decoration: @footer-top-nav-link-text-decoration-hover-focus;
							font-family: @footer-top-nav-link-font-family-hover-focus;
							font-weight: @footer-top-nav-link-font-weight-hover-focus;
							font-size: @footer-top-nav-link-font-size-hover-focus;
						}
					}
				}
				.footer-top-nav-link.payment-methods {				
					&:before {
						background: transparent @footer-top-nav-link-icon-payment-methods-url no-repeat center center;
						background-size: contain;	
						width: 33px;
					}
				}
				.footer-top-nav-link.data-protection {				
					&:before {
						background: transparent @footer-top-nav-link-icon-data-protection-url no-repeat center center;
						background-size: contain;	
					}
				}
				.footer-top-nav-link.house-connection {				
					&:before {
						background: transparent @footer-top-nav-link-icon-house-connection-url no-repeat center center;
						background-size: contain;	
					}
				}
				.footer-top-nav-link.contract {				
					&:before {
						background: transparent @footer-top-nav-link-icon-contract-url no-repeat center center;
						background-size: contain;	
					}
				}
				
				@media (max-width: @viewport-sm-max) {
				
					.footer-top-nav-link {
						margin: 6px 10px 11px 10px;
						
						&:before {
							width: 36px;
							height: 36px;
						}
						&:after {	
							margin-top: 6px;
						}	
						
						.footer-top-nav-link-text {
							font-size: @footer-top-nav-link-font-size-sm;
							
							&:hover, &:focus {
								font-size: @footer-top-nav-link-font-size-sm-hover-focus;
							}
						}
					}
					.footer-top-nav-link.payment-methods {				
						&:before {
							margin-bottom: -11px;
							width: 23px;
						}
					}
					.footer-top-nav-link.data-protection {				
						&:before {
							margin-bottom: -11px;
						}
					}
					.footer-top-nav-link.house-connection {				
						&:before {
							margin-bottom: -11px;
						}
					}	
				}	
			}	
		}
		.footer-bottom-nav {
			width: @footer-bottom-nav-width;
			background-color: @footer-bottom-nav-background-color;
			
			#footer-bottom-nav-cage {
				width: @footer-bottom-nav-cage-width;
				max-width: @footer-bottom-nav-cage-max-width;
				text-align: @footer-bottom-nav-cage-text-align;
				margin: 0px auto;
				
				.footer-bottom-nav-link {
					padding: @footer-bottom-nav-link-padding;
					display: inline-block;
					color: @footer-bottom-nav-link-color;
					text-decoration: @footer-bottom-nav-link-text-decoration;
					font-family: @footer-bottom-nav-link-font-family;
					font-weight: @footer-bottom-nav-link-font-weight;
					font-size: @footer-bottom-nav-link-font-size;			
				
					&:hover, &:focus {
						color: @footer-bottom-nav-link-color-hover-focus;
						text-decoration: @footer-bottom-nav-link-text-decoration-hover-focus;
						font-family: @footer-bottom-nav-link-font-family-hover-focus;
						font-weight: @footer-bottom-nav-link-font-weight-hover-focus;
						font-size: @footer-bottom-nav-link-font-size-hover-focus;					
					}
				}
				
				@media (max-width: @viewport-sm-max) {
					padding-top: 1px;
					padding-bottom: 3px;
					padding-right: 12px;
					
					.footer-bottom-nav-link {
						padding: @footer-bottom-nav-link-padding-sm;
						font-size: @footer-bottom-nav-link-font-size-sm;
							
						&:hover, &:focus {
							font-size: @footer-bottom-nav-link-font-size-sm-hover-focus;
						}
					}
				}
			}
		}
	}	
}
@footer: footer;