.mixin(buttons) {
	.btn-primary {
		color: @button-primary-color;
		border: @button-primary-border;
		background-color: @button-primary-background-color;
		border-radius: @button-border-radius;
		font-family: @button-primary-font-family;
		font-weight: @button-primary-font-weight;
		font-size: @button-primary-font-size;
		padding: @button-primary-padding;
		
		&:hover, &:focus {
			color: @button-primary-color-hover-focus;
			border: @button-primary-border-hover-focus;
			background-color: @button-primary-background-color-hover-focus;
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
		}
		&:active {
			-webkit-box-shadow: none !important;
			-moz-box-shadow: none !important;
			box-shadow: none !important;
		}
	}	
	
	.btn-default {
		color: @button-default-color;
		border: @button-default-border;
		background-color: @button-default-background-color;
		border-radius: @button-border-radius;
		font-family: @button-default-font-family;
		font-weight: @button-default-font-weight;
		font-size: @button-default-font-size;
		padding: @button-default-padding;
		
		&:hover, &:focus {
			color: @button-default-color-hover-focus;
			border: @button-default-border-hover-focus;
			background-color: @button-default-background-color-hover-focus;
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
		}
		&:active {
			-webkit-box-shadow: none !important;
			-moz-box-shadow: none !important;
			box-shadow: none !important;
		}
	}	
	
	.btn-primary.btn-inactive {
		color: @button-primary-inactive-color;
		border: @button-primary-inactive-border;
		background-color: @button-primary-inactive-background-color;
		cursor: default;
	}
	
	.btn-default.btn-inactive {
		color: @button-default-inactive-color;
		border: @button-default-inactive-border;
		background-color: @button-default-inactive-background-color;
		cursor: default;
	}

	#close-process,
	#close-process-marginal	{
		position: absolute;
		top: 0px;
		right: 0px;
		z-index: 998;
		border-radius: @button-close-border-radius;

		.btn-close {
			background: @button-close-background-color none;
			text-decoration: none;
			opacity: 1;
			text-align: center;
			display: inline-block;
			width: @button-close-width;
			height: @button-close-height;
			color: @button-close-color;
			border: @button-close-border;
			border-radius: @button-close-border-radius;
			border-top-right-radius: @main-process-card-border-radius;
			font-size: @button-close-font-size;
			padding: @button-close-padding;

			&:hover, &:focus {
				-webkit-box-shadow: none;
				-moz-box-shadow: none;
				box-shadow: none;
				border-color: @button-close-border-hover-focus;
				background: @button-close-background-color-hover-focus none;
				color: @button-close-color-hover-focus;   
			}
		}
	}
	#close-process.shadowed,
	#close-process-marginal.shadowed {
		-webkit-box-shadow: @button-close-shadowed-box-shadow;
		-moz-box-shadow: @button-close-shadowed-box-shadow;
		box-shadow: @button-close-shadowed-box-shadow;
		
		.btn-close {
			border-radius: @button-close-border-radius;
		}	
	}
	
	.btn-block {
		width: 100%;
	}
	
	.btn-default.more-tariffs {
		color: @button-more-tariffs-color;
		border: @button-more-tariffs-border;
		background-color: @button-more-tariffs-background-color;
		font-family: @button-more-tariffs-font-family;
		font-weight: @button-more-tariffs-font-weight;
		font-size: @button-more-tariffs-font-size;
		
		&:hover, &:focus {
			color: @button-more-tariffs-color-hover-focus;
			border: @button-more-tariffs-border-hover-focus;
			background-color: @button-more-tariffs-background-color-hover-focus;			
		}
	}
}
@buttons: buttons;	