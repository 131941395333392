
.mixin(accordion) {

	.accordion {
		.accordion-item {
			
			border: @main-process-card-in-card-border;
			border-radius: @main-process-card-in-card-border-radius;
			
			h3.accordion-header {
				margin-top: 0px;
				padding-bottom: 0px;
				border-bottom: 0px;
				border-radius: @main-process-card-in-card-border-radius;	
				
				.accordion-button::after,
				.accordion-button.collapsed::after {
					background: transparent none;
					width: 0px;
					height: 0px;
				}
				
				.accordion-button {
					padding: 0.5rem 1rem;
					background-color: @main-process-card-in-card-header-background-color;
					border-bottom: @main-process-card-in-card-header-border-bottom;
					font-family: @main-process-card-in-card-title-font-family;
					font-weight: @main-process-card-in-card-title-font-weight;
					color: @main-process-card-in-card-title-color;
					font-size: @main-process-card-in-card-title-font-size;
					border-radius: @main-process-card-in-card-border-radius;
					border-bottom-left-radius: 0px;
					border-bottom-right-radius: 0px;
					
					&:hover, &:focus {
						-webkit-box-shadow: none;
						-moz-box-shadow: none;
						box-shadow: none;
						border-bottom: @main-process-card-in-card-header-border-bottom;
					}				

					.fa-caret-down {
						display: none;
					}
					.fa-caret-up {
						display: inline-block;
					}		
				}
				.accordion-button:not(.collapsed) {
					-webkit-box-shadow: none;
					-moz-box-shadow: none;
					box-shadow: none;
				}
				.accordion-button.collapsed {
					border-bottom: 0px;
					border-bottom-left-radius: @main-process-card-in-card-border-radius;
					border-bottom-right-radius: @main-process-card-in-card-border-radius;
					
					.fa-caret-down {
					display: inline-block;
					}
					.fa-caret-up {
						display: none;
					}
				}
			}
			.accordion-body {
				background-color: @main-process-card-in-card-body-background-color;
				border-bottom-left-radius: @main-process-card-in-card-border-radius;
				border-bottom-right-radius: @main-process-card-in-card-border-radius;
			}
		}		
	}
	
	#energySavingAccordion {
		.accordion-item {
			
			margin-bottom: 1rem;
			
			.accordion-button {
				.fa-lightbulb,
				.fa-thermometer-three-quarters,
				.fa-snowflake,
				.fa-cogs,
				.fa-tachometer-alt,
				.fa-car,
				.fa-users {
					margin-right: 0.5rem;
				}
			}
		}
	}
	
}
@accordion: accordion;