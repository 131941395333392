.mixin(slider) {

	.slider-flex-row {
		margin-top: 0.5rem;
	}

	.slider .hide {
		display: none;
	}
	
	.slider {
		width: 100% !important;
		margin-left: 16px;
		margin-right: 16px;
		margin-top: 10px;
	}
	
	.slider-track {
		background: none @slider-track-background-color;
	}
	.slider-selection {
		background: none @slider-selection-background-color;
	}
	.slider-handle {
		background: none @slider-handle-background-color;
		border: @slider-handle-border;
		border-radius: @slider-handle-border-radius;
		opacity: 1;
	}	
	
	.input-group-text.flatmate {
		background: transparent @slider-flatmate-background-url no-repeat @slider-flatmate-background-position;
		background-size: @slider-flatmate-background-size;
		height: @slider-flatmate-background-height;
		min-width: @slider-flatmate-background-min-width;
		border: 0px;
		padding: 0px;
	}
	.input-group-text.flatmates {
		background: transparent @slider-flatmates-background-url no-repeat @slider-flatmates-background-position;
		background-size: @slider-flatmates-background-size;
		height: @slider-flatmates-background-height;
		min-width: @slider-flatmates-background-min-width;
		border: 0px;
		padding: 0px;
	}

	.input-group-text.house-small {
		background: transparent @slider-house-small-background-url no-repeat @slider-house-small-background-position;
		background-size: @slider-house-small-background-size;
		height: @slider-house-small-background-height;
		min-width: @slider-house-small-background-min-width;
		border: 0px;
		padding: 0px;
	}
	.input-group-text.house-large {
		background: transparent @slider-house-large-background-url no-repeat @slider-house-large-background-position;
		background-size: @slider-house-large-background-size;
		height: @slider-house-large-background-height;
		min-width: @slider-house-large-background-min-width;
		border: 0px;
		padding: 0px;
	}
}
@slider: slider;