
.mixin(meter-value-display) {

	.meter-value-display {
		display: table;
		width: 100%;
		margin-bottom: 0.5rem;

		.meter-value-display-row {
			display: table-row;

			.meter-value-display-cell {
				text-align: center;
				display: table-cell;
				font-size: @meter-value-display-font-size;
			}
			.mv-type {
				background-color: @meter-value-display-mv-type-background-color;
				color: @meter-value-display-mv-type-color;
				font-family: @meter-value-display-mv-type-font-family;
				font-weight: @meter-value-display-mv-type-font-weight;
				border-top-left-radius: @meter-value-display-border-radius;
				border-bottom-left-radius: @meter-value-display-border-radius;
				border-right: @meter-value-display-cell-border;
			}
			.mv-pre-comma.not-available {
				background-color: @meter-value-display-mv-pre-comma-not-available-background-color;
				color: @meter-value-display-mv-pre-comma-not-available-color;
				font-family: @meter-value-display-mv-pre-comma-not-available-font-family;
				font-weight: @meter-value-display-mv-pre-comma-not-available-font-weight;
				border-right: @meter-value-display-cell-border;
			}
			.mv-pre-comma {
				background-color: @meter-value-display-mv-pre-comma-background-color;
				color: @meter-value-display-mv-pre-comma-color;
				font-family: @meter-value-display-mv-pre-comma-font-family;
				font-weight: @meter-value-display-mv-pre-comma-font-weight;
				border-right: @meter-value-display-cell-border;
			}	
			.mv-comma {
				background-color: @meter-value-display-mv-comma-background-color;
				color: @meter-value-display-mv-comma-color;	
				font-family: @meter-value-display-mv-comma-font-family;
				font-weight: @meter-value-display-mv-comma-font-weight;
				border-right: @meter-value-display-cell-border;
				padding-left: 1px;
				padding-right: 1px;
			}
			.mv-post-comma {
				background-color: @meter-value-display-mv-post-comma-background-color;
				color: @meter-value-display-mv-post-comma-color;
				font-family: @meter-value-display-mv-post-comma-font-family;
				font-weight: @meter-value-display-mv-post-comma-font-weight;
				border-right: @meter-value-display-cell-border;
			}	
			.mv-unit {
				background-color: @meter-value-display-mv-unit-background-color;
				color: @meter-value-display-mv-unit-color;
				font-family: @meter-value-display-mv-unit-font-family;
				font-weight: @meter-value-display-mv-unit-font-weight;
				border-top-right-radius: @meter-value-display-border-radius;
				border-bottom-right-radius: @meter-value-display-border-radius;
			}
		}
	}
	
	.process {
		.meter-value-display {
			margin-top: 0.35rem;
		}
	}
}
@meter-value-display: meter-value-display;
