
.mixin(tabs) {

	.nav-tabs {
		border-bottom: 0px;
		
		.nav-link {
			background-color: @tab-nav-link-background-color;
			border-color: @tab-border-color;
			margin-right: 2px;
			font-family: @tab-nav-link-font-family;
			font-weight: @tab-nav-link-font-weight;
			font-size: @tab-nav-link-font-size;
			color: @tab-nav-link-color;
			
			&:hover {
				background-color: @tab-nav-link-background-color-hover;
				border-color: @tab-border-color;
				font-family: @tab-nav-link-font-family-hover;
				font-weight: @tab-nav-link-font-weight-hover;
				font-size: @tab-nav-link-font-size-hover;
				color: @tab-nav-link-color-hover;
			}
		}
		.nav-link.active {
			background-color: @tab-nav-link-active-background-color;
			border-color: @tab-border-color @tab-border-color @tab-nav-link-active-background-color;
			font-family: @tab-nav-link-active-font-family;
			font-weight: @tab-nav-link-active-font-weight;
			font-size: @tab-nav-link-active-font-size;
			color: @tab-nav-link-active-color;
			
			&:hover {
				background-color: @tab-nav-link-active-background-color-hover;
				border-color: @tab-border-color @tab-border-color @tab-nav-link-active-background-color-hover;
				font-family: @tab-nav-link-active-font-family-hover;
				font-weight: @tab-nav-link-active-font-weight-hover;
				font-size: @tab-nav-link-active-font-size-hover;
				color: @tab-nav-link-active-color-hover;
			}
		}
	}
	
	.tab-content {
		padding-left: 16px;
		padding-right: 16px;
		padding-top: 1rem;
		background-color: @tab-background-color;
		border: @tab-border;
		border-top-right-radius: @tab-border-radius;
		border-bottom-left-radius: @tab-border-radius;
		border-bottom-right-radius: @tab-border-radius;
	}
}
@tabs: tabs;