
.mixin(calendar) {
	.datepicker {
	
		&:before, &:after {
			opacity: 0;
		}
		
		border: @calendar-border;
		border-radius: @calendar-border-radius;
		background-color: @calendar-background-color;
		-webkit-box-shadow: @calendar-box-shadow;
		-moz-box-shadow: @calendar-box-shadow;
		box-shadow: @calendar-box-shadow;
		padding: 4px;
		
		th, td {
			padding: 5px 8px;			
		}
		
		th.prev {
			background-color: @calendar-th-prev-background-color;
			color: @calendar-th-prev-color;
			border-radius: 0px;
			height: 40px;
			padding-top: 2px;
			&:hover, &:focus {
				background-color: @calendar-th-prev-background-color-hover !important;
				color: @calendar-th-prev-color-hover;        
			}
		} 
		th.datepicker-switch {
			background-color: @calendar-th-switch-background-color;
			color: @calendar-th-switch-color;
			border-radius: 0px;
			height: 40px;
			&:hover, &:focus {
				background-color: @calendar-th-switch-background-color-hover !important;
				color: @calendar-th-switch-color-hover;        
			}
		}
		th.next {
			background-color: @calendar-th-next-background-color;
			color: @calendar-th-next-color;
			border-radius: 0px;
			height: 40px;
			padding-top: 2px;
			&:hover, &:focus {
				background-color: @calendar-th-next-background-color-hover !important;
				color: @calendar-th-next-color-hover;        
			}
		}
    
		th.dow {
			color: @calendar-th-dow-color;
		} 

		td.day, td span.month, td span.year {
			color: @calendar-td-color;
			border-radius: 0px;
			font-size: @calendar-td-font-size;
			
			&:hover {
				color: @calendar-td-color-hover;
				background-color: @calendar-td-background-color-hover;
				
			}	
		}

		td.day.old, td.day.new {
			color: @inactive-color;
		}

		td.today,
		td.today:hover,
		td.active:hover,
		td.active:hover:hover,
		td.active:focus,
		td.active:hover:focus,
		td.active:active,
		td.active:hover:active,
		td.active.active,
		td.active:hover.active,
		td.active.disabled,
		td.active:hover.disabled,
		td.active[disabled],
		td.active:hover[disabled],
		td span.active:hover,
		td span.active:focus,
		td span.active:active,
		td span.active.active,
		td span.active.disabled,
		td span.active[disabled] {
			color: @calendar-active-day-color;
			background: @calendar-active-day-background-color none;
			font-family: @calendar-active-day-font-family;
			font-weight: @calendar-active-day-font-weight;
			text-shadow: none;
			border: 0px;
			padding: 5px 7px;
		}	
	}
	
	.input-group .input-group-text.input-date-icon {
		cursor: pointer;
	}
}
@calendar: calendar;	