
.mixin(links) {

	.main-login-card-link {
		font-family: @main-login-card-link-font-family;
		font-weight: @main-login-card-link-font-weight;
		font-size: @main-login-card-link-font-size;
		color: @main-login-card-link-color;
		text-decoration: @main-login-card-link-text-decoration;

		&:hover,&:focus {
			color: @main-login-card-link-color-hover-focus;
			text-decoration: @main-login-card-link-text-decoration-hover-focus;
		}
	}

	.std-text-link {
		font-family: @link-font-family;
		font-weight: @link-font-weight;
		color: @link-color;
		text-decoration: @link-text-decoration;

		&:hover, &:focus {
			font-family: @link-font-family-hover-focus;
			font-weight: @link-font-weight-hover-focus;
			color: @link-color-hover-focus;
			text-decoration: @link-text-decoration-hover-focus;
		}

		.fa-file-pdf {
			margin-right: 0.5rem;
		}
	}

	.card.postbox {
		.postbox-entries {
		
			padding-top: 0px;
			
			.postbox-entry {
				clear: both;
				float: left;
				width: 100%;
				margin-bottom: 0.25rem;
						
				.postbox-date {
					display: block;
					float: left;
					width: 85px;
					white-space: nowrap;
				}
				
				.postbox-link {
					display: block;
					float: left;
					width: @postbox-link-width;
					font-family: @postbox-link-font-family;
					font-weight: @postbox-link-font-weight;
					color: @postbox-link-color;
					text-decoration: none;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					
					&:before {
						content: '\f0e0';
						font-family: 'FontAwesome'; 
						font-weight: 900;
						color: @postbox-icon-color;
						margin-right: 10px;
						margin-left: 10px;
						text-decoration-line: none;
					}

					> span {
						text-decoration: @postbox-link-text-decoration;
					}	
					
					&:hover, &:focus {
						color: @postbox-link-color-hover-focus;
						text-decoration: none;

						&:before {
							color: @postbox-link-color-hover-focus;
							text-decoration-line: none;
						}

						> span {
							text-decoration: @postbox-link-text-decoration-hover-focus;
						}		
					}				
				}	
			}
			.postbox-entry.read {
				.postbox-link {
					font-family: @postbox-link-read-font-family;
					font-weight: @postbox-link-read-font-weight;
					color: @postbox-link-read-color;
					
					&:before {
						content: '\f2b6';						
						color: @postbox-icon-read-color;
						text-decoration-line: none;
					}
					
					&:hover, &:focus {
						color: @postbox-link-read-color-hover-focus;
						
						&:before {
							color: @postbox-link-read-color-hover-focus;
							text-decoration-line: none;
						}
					}
				}	
			}
			
			@media (max-width: @viewport-sm-max) {
				padding-top: 1rem;
			}
		}	
	}
	
	.form-link {
	
		.form-link-icon {
			display: @form-link-icon-display;
			color: @form-link-icon-color;
			margin-right: 6px;
		}
		
		a, span {
			font-family: @form-link-font-family;
			font-weight: @form-link-font-weight;
			color: @form-link-color;
			font-size: @form-link-font-size;
			text-decoration: @form-link-text-decoration;
		}	
		
		&:hover, &:focus {
		
			.form-link-icon {
				color: @form-link-icon-color-hover-focus;
			}	
			
			a, span {
				font-family: @form-link-font-family-hover-focus;
				font-weight: @form-link-font-weight-hover-focus;
				color: @form-link-color-hover-focus;
				font-size: @form-link-font-size-hover-focus;
				text-decoration: @form-link-text-decoration-hover-focus;
			}
			span {
				cursor: pointer;
			}	
		}		
	}
	.form-link.inactive {
		.form-link-icon {
			color: @inactive-color;
		}
		span {
			font-family: @form-link-font-family;
			font-weight: @form-link-font-weight;
			color: @inactive-color;
			font-size: @form-link-font-size;
			text-decoration: @form-link-text-decoration;
		}	
	}	
}
@links: links;