.mixin(base-layout) {

	html {
		height: 100% !important;
	}

	body {
		height: 100% !important;
		min-width: @viewport-xs-min;
	}	

	#browser-notification {
		position: absolute;
		left: 0px;
		top: 0px;
		right: 0px;
		width: 100%;
		padding: 5px 30px;
		color: @browser-notification-color;
		background-color: @browser-notification-background-color;
		z-index: 999999;
		text-align: center;
		display: none;
	}

	div#cage {
		height: 100% !important;
		width: @cage-width;
		margin: 0px auto;
	}
	
	body.cockpit {
		div#cage {
			background-color: @cockpit-cage-background-color;
		}
		.main-panorama {
			background-color: @cockpit-main-panorama-background-color;
			
			#main-cockpit-panorama-cage {
				width: @main-cockpit-panorama-cage-width;
				max-width: @main-cockpit-panorama-cage-max-width;
				margin: 0px auto;
				min-height: @main-cockpit-panorama-cage-min-height;		
				
				.main-panorama-image {
					background: transparent @main-panorama-image-background-url no-repeat @main-panorama-image-background-position;
					background-size: @main-panorama-image-background-size;			
				}
				
				@media (max-width: @viewport-lg-max) {
				
					.main-panorama-image {
						background: transparent @main-panorama-image-background-url-lg no-repeat @main-panorama-image-background-position-lg;
						background-size: @main-panorama-image-background-size-lg;			
					}
					
				}
				@media (max-width: @viewport-md-max) {
					min-height: @main-cockpit-panorama-cage-min-height-md;
				}		
				@media (max-width: @viewport-sm-max) {
					min-height: 0;
				}
			}	
		}	
	}
	
	main {
		padding-top: @main-padding-top;
		
		@media (max-width: @viewport-sm-max) {
			padding-top: @main-padding-top-sm;
		}
	}
	body.in main {
		padding-top: @main-padding-top;
		
		@media (max-width: @viewport-sm-max) {
			padding-top: calc(~'@{main-padding-top-sm} + @{header-context-height}');
		}
	}
	
	.main-panorama {
							
		.main-login,
		.main-context {
			float: left;
			width: @main-login-and-context-width;
			position: relative;
			
			order: @main-login-and-context-order;
			
			@media (max-width: @viewport-lg-max) {
				width: @main-login-and-context-width-lg;
			}	
			@media (max-width: @viewport-md-max) {
				width: @main-login-and-context-width-md;
			}
			@media (max-width: @viewport-sm-max) {
				width: @main-login-and-context-width-sm;
			}		
		}
		
		.main-panorama-image {
			float: left;
			width: @main-panorama-image-width;			
			position: relative;
			
			order: @main-panorama-image-order;
			
			.main-panorama-claim {
				color: @main-panorama-claim-color;
				position: absolute;
				bottom: 24px;
				left: 24px;
				font-family: @main-panorama-claim-font-family;
				font-weight: @main-panorama-claim-font-weight;
				font-size: @main-panorama-claim-font-size;
				width: 60%;
				line-height: 54px;
				text-shadow: @main-panorama-claim-text-shadow;
			}
			
			@media (max-width: @viewport-lg-max) {
				width: @main-panorama-image-width-lg;
				
				.main-panorama-claim {
					font-size: @main-panorama-claim-font-size-lg;
					line-height: 45px;
					bottom: 18px;
				}
			}			
			@media (max-width: @viewport-md-max) {
				width: @main-panorama-image-width-md;
				
				.main-panorama-claim {
					font-size: @main-panorama-claim-font-size-md;
					line-height: 34px;
					bottom: 11px;
				}
			}
			@media (max-width: @viewport-sm-max) {
				display: none;
			}
		}
	}
	
	.main-process-top-stripe {
		width: @main-process-top-stripe-width;
		height: @main-process-top-stripe-height;
		background-color: @main-process-top-stripe-background-color;	

		@media (max-width: @viewport-md-max) {
			height: @main-process-top-stripe-height-md;
		}	
		
		@media (max-width: @viewport-sm-max) {
			height: 0px;
		}
	}
	
	#main-process-container {
		margin-top: -@main-process-top-stripe-height; 
		
		@media (max-width: @viewport-md-max) {
			margin-top: -@main-process-top-stripe-height-md;
		}
		
		@media (max-width: @viewport-sm-max) {		
			margin-top: 0px;
		}	
	}
	
	#main-process-cage {
		width: @main-process-cage-width;
		max-width: @main-process-cage-max-width;
		margin: 0px auto;
		
		.main-context {
			float: left;
			width: calc(~'(@{viewport-xxl-max} - @{main-process-max-width}) / 2');
			position: relative;
			
			order: 1;
			
			@media (max-width: @viewport-xxl-max) {
				width: calc(~'(100% - @{main-process-max-width}) / 2');
			}
			@media (max-width: calc(~'@{main-context-and-process-std-viewport-min-width} - 1px')) {
				width: calc(~'(@{viewport-xs-min} + (@{viewport-xs-min} / 2)) / 2');
			}
			@media (max-width: @viewport-lg-max) {
				width: calc(~'@{viewport-xs-min} / 2');
			}			
			@media (max-width: @viewport-md-max) {
				width: 100%;
				height: 0px;
				min-height: 0;				
			}	
		}
		.main-process-top-image {
			min-height: @main-process-top-image-min-height-md;
			float: right;
			width: 50%;
			height: 100%;
			background: transparent @main-process-top-image-background-url no-repeat @main-process-top-image-background-position;
			background-size: @main-process-top-image-background-size;
			display: none;

			@media (max-width: @viewport-md-max) {
				display: block;
			}
			@media (max-width: @viewport-sm-max) {
				display: none;
			}
		}
		
		.main-process {
			float: left;			
			background-color: @main-process-background-color;			
			width: @main-process-max-width;
			
			order: 2;
							
			@media (max-width: calc(~'@{main-context-and-process-std-viewport-min-width} - 1px')) {
				width: calc(~'100% - (2 * (@{viewport-xs-min} + (@{viewport-xs-min} / 2)) / 2)');
			}

			@media (max-width: @viewport-lg-max) {
				width: calc(~'100% - @{viewport-xs-min}');
			}
						
			@media (max-width: @viewport-md-max) {
				width: 100%;
			}
		}

		.main-process.marginal-col {
		
			@media (max-width: calc(~'@{main-context-and-process-std-viewport-min-width} - 1px')) {
				order: 3;
				
				margin-left: calc(~'(@{viewport-xs-min} + (@{viewport-xs-min} / 2)) / 2');
				
				#close-process {
					display: none;
				}
				.steps-container {
					margin-top: 24px;
				}	
			}
			@media (max-width: @viewport-lg-max) {
				margin-left: calc(~'@{viewport-xs-min} / 2');
			}
			@media (max-width: @viewport-md-max) {
				width: 100%;
				margin-left: 0px;
				
				.steps-container {
					margin-top: 9px;
				}	
			}
		}			
	}
	
	.main-marginal-column {
		width: calc(~'(100% - @{main-process-max-width}) / 2');
		order: 3;
		
		#close-process-marginal {
			display: none;
		}
				
		@media (max-width: calc(~'@{main-context-and-process-std-viewport-min-width} - 1px')) {
			width: calc(~'100% - @{viewport-xs-min} - (@{viewport-xs-min} / 2)');
			order: 2;
			
			background-color: transparent;
			
			#close-process-marginal {
				display: block;
			}
		}
		@media (max-width: @viewport-lg-max) {
			width: calc(~'100% - @{viewport-xs-min}');
		}
		@media (max-width: @viewport-md-max) {
			width: 100%;
		}
	}		

	body.in {
		#main-process-cage {
			.main-context {
				@media (max-width: calc(~'@{main-context-and-process-std-viewport-min-width} - 1px')) {
					width: @viewport-xs-min;
				}
				@media (max-width: @viewport-md-max) {
					width: 100%;
					min-height: @main-process-top-image-min-height-md;
					background-color: @main-context-background-color-md;
					height: auto;
				}
				@media (max-width: @viewport-sm-max) {
					height: 0px;
					min-height: 0;
				}		
			}
			.main-process {
				@media (max-width: calc(~'@{main-context-and-process-std-viewport-min-width} - 1px')) {
					width: calc(~'100% - @{viewport-xs-min} - (@{viewport-xs-min} / 2)');
				}
				@media (max-width: @viewport-lg-max) {
					width: calc(~'100% - @{viewport-xs-min}');
				}
				@media (max-width: @viewport-md-max) {
					width: 100%;
				}
			}
			.main-process.marginal-col {		
				@media (max-width: calc(~'@{main-context-and-process-std-viewport-min-width} - 1px')) {
					margin-left: @viewport-xs-min;
				}
				@media (max-width: @viewport-md-max) {
					margin-left: 0px;
				}
			}	
		}
	}		
	
	#cockpit-cards-cage {
		width: @cockpit-cards-cage-width;
		max-width: @cockpit-cards-cage-max-width;
		margin: 0px auto;
		-ms-flex-pack: @cockpit-cards-cage-justify-content;
		justify-content: @cockpit-cards-cage-justify-content;
						
		@media (max-width: @viewport-sm-max) {
			border-bottom: @cockpit-cards-cage-border-bottom-sm;				
		}
	}
		
}@base-layout: base-layout;