/*!
 * bootstrap-fileinput v5.2.6
 * http://plugins.krajee.com/file-input
 *
 * Krajee default styling for bootstrap-fileinput.
 *
 * Author: Kartik Visweswaran
 * Copyright: 2014 - 2021, Kartik Visweswaran, Krajee.com
 *
 * Licensed under the BSD-3-Clause
 * https://github.com/kartik-v/bootstrap-fileinput/blob/master/LICENSE.md
 */

.file-loading input[type=file],
input[type=file].file-loading {
    width: 0;
    height: 0;
}

.file-no-browse {
    position: absolute;
    left: 50%;
    bottom: 20%;
    width: 1px;
    height: 1px;
    font-size: 0;
    opacity: 0;
    border: none;
    background: none;
    outline: none;
    box-shadow: none;
}

.kv-hidden,
.file-caption-icon,
.file-zoom-dialog .modal-header:before,
.file-zoom-dialog .modal-header:after,
.file-input-new .file-preview,
.file-input-new .close,
.file-input-new .glyphicon-file,
.file-input-new .fileinput-remove-button,
.file-input-new .fileinput-upload-button,
.file-input-new .no-browse .input-group-btn,
.file-input-ajax-new .fileinput-remove-button,
.file-input-ajax-new .fileinput-upload-button,
.file-input-ajax-new .no-browse .input-group-btn,
.hide-content .kv-file-content,
.is-locked .fileinput-upload-button,
.is-locked .fileinput-remove-button {
    display: none;
}

.btn-file input[type=file],
.file-caption-icon,
.file-preview .fileinput-remove,
.krajee-default .file-thumb-progress,
.file-zoom-dialog .btn-navigate,
.file-zoom-dialog .floating-buttons {
    position: absolute;
}

.file-caption-icon .kv-caption-icon {
    line-height: inherit;
}

.file-input,
.file-loading:before,
.btn-file,
.file-caption,
.file-preview,
.krajee-default.file-preview-frame,
.krajee-default .file-thumbnail-footer,
.file-zoom-dialog .modal-dialog {
    position: relative;
}

.file-error-message pre,
.file-error-message ul,
.krajee-default .file-actions,
.krajee-default .file-other-error {
    text-align: left;
}

.file-error-message pre,
.file-error-message ul {
    margin: 0;
}

.krajee-default .file-drag-handle,
.krajee-default .file-upload-indicator {
    float: left;
    margin-top: 10px;
    width: 16px;
    height: 16px;
}

.file-thumb-progress .progress,
.file-thumb-progress .progress-bar {
    font-family: Verdana, Helvetica, sans-serif;
    font-size: 0.7rem;
}

.krajee-default .file-thumb-progress .progress,
.kv-upload-progress .progress {
    background-color: #ccc;
}

.krajee-default .file-caption-info,
.krajee-default .file-size-info {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 160px;
    height: 15px;
    margin: auto;
}

.file-zoom-content > .file-object.type-video,
.file-zoom-content > .file-object.type-flash,
.file-zoom-content > .file-object.type-image {
    max-width: 100%;
    max-height: 100%;
    width: auto;
}

.file-zoom-content > .file-object.type-video,
.file-zoom-content > .file-object.type-flash {
    height: 100%;
}

.file-zoom-content > .file-object.type-pdf,
.file-zoom-content > .file-object.type-html,
.file-zoom-content > .file-object.type-text,
.file-zoom-content > .file-object.type-default {
    width: 100%;
}

.file-loading:before {
    content: " Loading...";
    display: inline-block;
    padding-left: 20px;
    line-height: 16px;
    font-size: 13px;
    font-variant: small-caps;
    color: #999;
    background: transparent url(../../images/de_DE/fileinput-loading.gif) top left no-repeat;
}

.file-object {
    margin: 0 0 -5px 0;
    padding: 0;
}

.btn-file {
    overflow: hidden;
}

.btn-file input[type=file] {
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    text-align: right;
    opacity: 0;
    background: none repeat scroll 0 0 transparent;
    cursor: inherit;
    display: block;
}

.btn-file ::-ms-browse {
    font-size: 10000px;
    width: 100%;
    height: 100%;
}

.file-caption.icon-visible .file-caption-icon {
    display: inline-block;
}

.file-caption.icon-visible .file-caption-name {
    padding-left: 25px;
}

.file-caption.icon-visible > .input-group-lg .file-caption-name {
    padding-left: 30px;
}

.file-caption.icon-visible > .input-group-sm .file-caption-name {
    padding-left: 22px;
}

.file-caption-name:not(.file-caption-disabled) {
    background-color: transparent;
}

.file-caption-name.file-processing {
    font-style: italic;
    border-color: #bbb;
    opacity: 0.5;
}

.file-caption-icon {
    padding: 7px 5px;
    left: 4px;
}

.input-group-lg .file-caption-icon {
    font-size: 1.25rem;
}

.input-group-sm .file-caption-icon {
    font-size: 0.875rem;
    padding: 0.25rem;
}

.file-error-message {
    color: #a94442;
    background-color: #f2dede;
    margin: 5px;
    border: 1px solid #ebccd1;
    border-radius: 4px;
    padding: 15px;
}

.file-error-message pre {
    margin: 5px 0;
}

.file-caption-disabled {
    background-color: #eee;
    cursor: not-allowed;
    opacity: 1;
}

.file-preview {
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 8px;
    width: 100%;
    margin-bottom: 5px;
}

.file-preview .btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.file-preview .fileinput-remove {
    top: 1px;
    right: 1px;
    line-height: 10px;
}

.file-preview .clickable {
    cursor: pointer;
}

.file-preview-image {
    font: 40px Impact, Charcoal, sans-serif;
    color: #008000;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.krajee-default.file-preview-frame {
    margin: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    padding: 6px;
    float: left;
    text-align: center;
}

.krajee-default.file-preview-frame .kv-file-content {
    width: 213px;
    height: 160px;
}

.krajee-default .file-preview-other-frame {
    display: flex;
    align-items: center;
    justify-content: center;
}

.krajee-default.file-preview-frame .kv-file-content.kv-pdf-rendered {
    width: 400px;
}

.krajee-default.file-preview-frame[data-template="audio"] .kv-file-content {
    width: 240px;
    height: 55px;
}

.krajee-default.file-preview-frame .file-thumbnail-footer {
    height: 70px;
}

.krajee-default.file-preview-frame:not(.file-preview-error):hover {
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
}

.krajee-default .file-preview-text {
    color: #428bca;
    border: 1px solid #ddd;
    outline: none;
    resize: none;
}

.krajee-default .file-preview-html {
    border: 1px solid #ddd;
}

.krajee-default .file-other-icon {
    font-size: 6em;
    line-height: 1;
}

.krajee-default .file-footer-buttons {
    float: right;
}

.krajee-default .file-footer-caption {
    display: block;
    text-align: center;
    padding-top: 4px;
    font-size: 11px;
    color: #777;
    margin-bottom: 30px;
}

.file-upload-stats {
    font-size: 10px;
    text-align: center;
    width: 100%;
}

.kv-upload-progress .file-upload-stats {
    font-size: 12px;
    margin: -10px 0 5px;
}

.krajee-default .file-preview-error {
    opacity: 0.65;
    box-shadow: none;
}

.krajee-default .file-thumb-progress {
    top: 37px;
    left: 0;
    right: 0;
}

.krajee-default.kvsortable-ghost {
    background: #e1edf7;
    border: 2px solid #a1abff;
}

.krajee-default .file-preview-other:hover {
    opacity: 0.8;
}

.krajee-default .file-preview-frame:not(.file-preview-error) .file-footer-caption:hover {
    color: #000;
}

.kv-upload-progress .progress {
    height: 20px;
    margin: 10px 0;
    overflow: hidden;
}

.kv-upload-progress .progress-bar {
    height: 20px;
    font-family: Verdana, Helvetica, sans-serif;
}


/*noinspection CssOverwrittenProperties*/

.file-zoom-dialog .file-other-icon {
    font-size: 22em;
    font-size: 50vmin;
}

.file-zoom-dialog .modal-dialog {
    width: auto;
}

.file-zoom-dialog .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.file-zoom-dialog .btn-navigate {
    margin: 0 0.1rem;
    padding: 0;
    font-size: 1.2rem;
    width: 2.4rem;
    height: 2.4rem;
    top: 50%;
    border-radius: 50%;
    text-align:center;
}

.btn-navigate * {
    width: auto;
}

.file-zoom-dialog .floating-buttons {
    top: 5px;
    right: 10px;
}

.file-zoom-dialog .btn-kv-prev {
    left: 0;
}

.file-zoom-dialog .btn-kv-next {
    right: 0;
}

.file-zoom-dialog .kv-zoom-caption {
    max-width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.file-zoom-dialog .kv-zoom-header {
    padding: 0.5rem;
}

.file-zoom-dialog .kv-zoom-body {
    padding: 0.25rem 0.5rem 0.25rem 0;
}

.file-zoom-dialog .kv-zoom-description {
    position: absolute;
    opacity: 0.8;
    font-size: 0.8rem;
    background-color: #1a1a1a;
    padding: 1rem;
    text-align: center;
    border-radius: 0.5rem;
    color: #fff;
    left: 15%;
    right: 15%;
    bottom: 15%;
}

.file-zoom-dialog .kv-desc-hide {
    float: right;
    color: #fff;
    padding: 0 0.1rem;
    background: none;
    border: none;
}

.file-zoom-dialog .kv-desc-hide:hover {
    opacity: 0.7;
}

.file-zoom-dialog .kv-desc-hide:focus {
    opacity: 0.9;
}

.file-input-new .no-browse .form-control {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.file-input-ajax-new .no-browse .form-control {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.file-caption {
    width: 100%;
    position: relative;
}

.file-thumb-loading {
    background: transparent url(../../images/de_DE/fileinput-loading.gif) no-repeat scroll center center content-box !important;
}

.file-drop-zone {
    border: 1px dashed #aaa;
    min-height: 260px;
    border-radius: 4px;
    text-align: center;
    vertical-align: middle;
    margin: 12px 15px 12px 12px;
    padding: 5px;
}

.file-drop-zone.clickable:hover {
    border: 2px dashed #999;
}

.file-drop-zone.clickable:focus {
    border: 2px solid #5acde2;
}

.file-drop-zone .file-preview-thumbnails {
    cursor: default;
}

.file-drop-zone-title {
    color: #aaa;
    font-size: 1.6em;
    text-align: center;
    padding: 85px 10px;
    cursor: default;
}

.file-highlighted {
    border: 2px dashed #999 !important;
    background-color: #eee;
}

.file-uploading {
    background: url(../../images/de_DE/fileinput-loading-sm.gif) no-repeat center bottom 10px;
    opacity: 0.65;
}

.file-zoom-fullscreen .modal-dialog {
    min-width: 100%;
    margin: 0;
}

.file-zoom-fullscreen .modal-content {
    border-radius: 0;
    box-shadow: none;
    min-height: 100vh;
}

.file-zoom-fullscreen .kv-zoom-body {
    overflow-y: auto;
}

.floating-buttons {
    z-index: 3000;
}

.floating-buttons .btn-kv {
    margin-left: 3px;
    z-index: 3000;
}

.kv-zoom-actions .btn-kv {
    margin-left: 3px;
}

.file-zoom-content {
    text-align: center;
    white-space: nowrap;
    min-height: 300px;
}

.file-zoom-content:hover {
    background: transparent;
}

.file-zoom-content > * {
    display: inline-block;
    vertical-align: middle;
}

.file-zoom-content .kv-spacer {
    height: 100%;
}

.file-zoom-content .file-preview-image {
    max-height: 100%;
}

.file-zoom-content .file-preview-video {
    max-height: 100%;
}

.file-zoom-content > .file-object.type-image {
    height: auto;
    min-height: inherit;
}

.file-zoom-content > .file-object.type-audio {
    width: auto;
    height: 30px;
}

@media (min-width: 576px) {
    .file-zoom-dialog .modal-dialog {
        max-width: 500px;
    }
}

@media (min-width: 992px) {
    .file-zoom-dialog .modal-lg {
        max-width: 800px;
    }
}

@media (max-width: 767px) {
    .file-preview-thumbnails {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .file-zoom-dialog .modal-header {
        flex-direction: column;
    }
}

@media (max-width: 350px) {
    .krajee-default.file-preview-frame:not([data-template="audio"]) .kv-file-content {
        width: 160px;
    }
}

@media (max-width: 420px) {
    .krajee-default.file-preview-frame .kv-file-content.kv-pdf-rendered {
        width: 100%;
    }
}

.file-loading[dir=rtl]:before {
    background: transparent url(../../images/de_DE/fileinput-loading.gif) top right no-repeat;
    padding-left: 0;
    padding-right: 20px;
}

.clickable .file-drop-zone-title {
    cursor: pointer;
}

.file-sortable .file-drag-handle:hover {
    opacity: 0.7;
}

.file-sortable .file-drag-handle {
    cursor: grab;
    opacity: 1;
}

.file-grabbing,
.file-grabbing * {
    cursor: not-allowed !important;
}

.file-grabbing .file-preview-thumbnails * {
    cursor: grabbing !important;
}

.file-preview-frame.sortable-chosen {
    background-color: #d9edf7;
    border-color: #17a2b8;
    box-shadow: none !important;
}

.file-preview .kv-zoom-cache {
    display: none;
}