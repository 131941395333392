
.mixin(alerts) {
	.alert-danger, .alert-danger:focus {
		border-color: @alert-danger-color;
		color: @alert-danger-txt-color;
		background-color: @alert-danger-bg-color;

		a {
			color: @alert-danger-txt-color;
			text-decoration: underline;
			&:hover, &:focus {
				color: @alert-danger-txt-color;
				text-decoration: underline;	
			}
		}

		.btn-close {
			&:before {
				color: @alert-danger-txt-color;
			}		
		}	
	}
	.alert-warning, .alert-warning:focus {
		border-color: @alert-warning-color;
		color: @alert-warning-txt-color;
		background-color: @alert-warning-bg-color;
		
		a {
			color: @alert-warning-txt-color;
			text-decoration: underline;
			&:hover, &:focus {
				color: @alert-warning-txt-color;
				text-decoration: underline;	
			}
		}
		
		.btn-close {
			&:before {
				color: @alert-warning-txt-color;
			}		
		}	
 	}
	.alert-info, .alert-info:focus {
		border-color: @alert-info-color;
		color: @alert-info-txt-color;
		background-color: @alert-info-bg-color;
		
		a {
			color: @alert-info-txt-color;
			text-decoration: underline;
			&:hover, &:focus {
				color: @alert-info-txt-color;
				text-decoration: underline;	
			}
		}
		
		.btn-close {
			&:before {
				color: @alert-info-txt-color;
			}		
		}	
 	}
	
	.alert-password-weak, .alert-password-weak:focus {
		border-color: @alert-password-weak-color;
		background-color: @alert-password-weak-bg-color;
 	}
	.alert-password-medium, .alert-password-medium:focus {
		border-color: @alert-password-medium-color;
		background-color: @alert-password-medium-bg-color;
 	}
	.alert-password-strong, .alert-password-strong:focus {
		border-color: @alert-password-strong-color;
		background-color: @alert-password-strong-bg-color;
 	}
	
	h3.alert-heading {
		border-bottom: 0px;
		margin-top: 0px;
		margin-bottom: 0.5rem;
		padding-bottom: 0px;
	}

	p.alert {
		padding: 6px 12px;
		border-radius: 0px;
	}

	span.alert-danger[data-bs-toggle=popover],
	span.alert-warning[data-bs-toggle=popover],
	span.alert-info[data-bs-toggle=popover] {
		background-color: transparent;
	}	
}
@alerts: alerts;