
.mixin(header) {
	header {
		width: @header-width;
		overflow: hidden;
		height: @header-height;
		border-bottom: @header-border-bottom;
		background-color: @header-background-color;
		
		#header-cage {	
			width: @header-cage-width;
			max-width: @header-cage-max-width;
			margin: 0px auto;			
		}
		
		.logo {
			background: transparent @header-logo-url no-repeat left center;
			background-size: auto 100%;
			margin-top: @header-logo-margin-top;
			margin-left: @header-logo-margin-left;
			height: @header-logo-height;
			width: @header-logo-width;
			display: inline-block;
		}
		
		> .alert {
			position: fixed;
			top: 16px;
			left: 0px;
			right: 0px;
			width: 80%;
			max-width: @main-process-max-width;
			z-index: 1033;	
			margin-left: auto;
			margin-right: auto;
			border-radius: 0px;
			
			ul {
				padding-left: 1rem;
			}
			
			.btn-close {
				background: transparent none;
				opacity: 1.0;
				&:focus,&:active {
					border: 0px !important;
					outline: none !important;
					box-shadow: none;
				}
				&:before {
					font-size: 18px;
				}	
			}
		}
		
		#headerContext {
			position: absolute;
			left: 0px;
			top: @header-height;
			width: 100%;
			height: @header-context-height;
			background-color: @header-context-background-color;
			
			#headerContextCage {
				width: @header-context-cage-width;
				max-width: @header-context-cage-max-width;
				margin: 0px auto;
			}
			
			#headerContextToggle {
				float: left;
				border: @header-context-toggle-border;
				border-radius: @header-context-toggle-border-radius;
				padding: 4px 10px;
				margin-left: 16px;
				margin-top: 1px;
				cursor: pointer;
				background-color: @header-context-toggle-background-color;
				
				.fa-bars {
					color: @header-context-toggle-bars-color;
				}

				&:hover {
					background-color: @header-context-toggle-background-color-hover;
					
					.fa-bars {
						color: @header-context-toggle-bars-color-hover;
					}
				}	
			}
			.header-context-3-lines {
				color: @header-context-lines-color;
				font-size: @header-context-lines-font-size;
				line-height: 13px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				font-family: @header-context-lines-font-family;
				font-weight: @header-context-lines-font-weight;
				padding-left: 16px;
				padding-right: 16px;
				margin-top: 5px;
			}
			.header-context-2-lines {
				color: @header-context-lines-color;
				font-size: @header-context-lines-font-size;
				line-height: 20px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				font-family: @header-context-lines-font-family;
				font-weight: @header-context-lines-font-weight;
				padding-left: 16px;
				padding-right: 16px;
				margin-top: 5px;
			}
			.header-context-1-line {
				color: @header-context-lines-color;
				font-size: @font-size;
				line-height: 38px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				font-family: @header-context-lines-font-family;
				font-weight: @header-context-lines-font-weight;
				padding-left: 16px;
				padding-right: 16px;
				margin-top: 5px;
			}
		}	
	
		@media (max-width: @viewport-sm-max) {
			height: @header-height-sm;
			
			.logo {
				background: transparent @header-logo-url-sm no-repeat left center;
				background-size: auto 100%;
				margin-top: @header-logo-margin-top-sm;
				margin-left: @header-logo-margin-left-sm;
				height: @header-logo-height-sm;
				width: @header-logo-width-sm;
			}
			
			#headerContext {
				top: @header-height-sm;
			}	
		}
	}
	
	header.shadowed {
		-webkit-box-shadow: @header-shadowed;
		-moz-box-shadow: @header-shadowed;
	    box-shadow: @header-shadowed;
	}

	body.in header {
		height: @header-height;
		@media (max-width: @viewport-sm-max) {
			height: calc(~'@{header-height-sm} + @{header-context-height}');
		}	
	}

	body.in header.head-context {
		height: calc(~'@{header-height} + @{header-context-height}');
		-webkit-transition: height 0.5s ease;
		-moz-transition: height 0.5s ease;
		-o-transition: height 0.5s ease;
		transition: height 0.5s ease;

		@media (max-width: @viewport-sm-max) {
			height: calc(~'@{header-height-sm} + @{header-context-height}');
		}
	}	
}
@header: header;