
.mixin(popovers) {

	.popover {
		border-radius: @popover-border-radius;
		z-index: 170;
		
		.popover-header {
			background-color: transparent;
			border-bottom: 0px;
			padding-bottom: 0px;
			font-family: @popover-header-font-family;
			font-weight: @popover-header-font-weight;
			font-size: @popover-header-font-size;
			margin-top: 0px;
			margin-bottom: 0px;
		}
		.popover-body {
			font-family: @popover-body-font-family;
			font-weight: @popover-body-font-weight;
			font-size: @popover-body-font-size;
		}
	}
	.popover.bs-popover-top > .popover-arrow {
		bottom: -0.5rem;
	}
	
	.popover.alert-danger {
		.popover-header,
		.popover-body {
			color: @alert-danger-txt-color;
		}		
	}
	.popover.bs-popover-top.alert-danger {
		> .popover-arrow {
			&:before {
				border-top-color: @alert-danger-color;
			}
			&:after {
				border-top-color: @alert-danger-bg-color;
			}	
		}
	}
	.popover.bs-popover-start.alert-danger {
		> .popover-arrow {
			&:before {
				border-left-color: @alert-danger-color;
			}
			&:after {
				border-left-color: @alert-danger-bg-color;
			}	
		}
	}
	.popover.bs-popover-end.alert-danger {
		> .popover-arrow {
			&:before {
				border-right-color: @alert-danger-color;
			}
			&:after {
				border-right-color: @alert-danger-bg-color;
			}	
		}
	}
	.popover.bs-popover-bottom.alert-danger {
		> .popover-arrow {
			&:before {
				border-bottom-color: @alert-danger-color;
			}
			&:after {
				border-bottom-color: @alert-danger-bg-color;
			}		
		}
	}
	
	.popover.alert-warning {
		.popover-header,
		.popover-body {
			color: @alert-warning-txt-color;
		}		
	}
	.popover.bs-popover-top.alert-warning {
		> .popover-arrow {
			&:before {
				border-top-color: @alert-warning-color;
			}
			&:after {
				border-top-color: @alert-warning-bg-color;
			}	
		}
	}
	.popover.bs-popover-start.alert-warning {
		> .popover-arrow {
			&:before {
				border-left-color: @alert-warning-color;
			}
			&:after {
				border-left-color: @alert-warning-bg-color;
			}	
		}
	}
	.popover.bs-popover-end.alert-warning {
		> .popover-arrow {
			&:before {
				border-right-color: @alert-warning-color;
			}
			&:after {
				border-right-color: @alert-warning-bg-color;
			}	
		}
	}
	.popover.bs-popover-bottom.alert-warning {
		> .popover-arrow {
			&:before {
				border-bottom-color: @alert-warning-color;
			}
			&:after {
				border-bottom-color: @alert-warning-bg-color;
			}		
		}
	}
	
	.popover.alert-info {
		.popover-header,
		.popover-body {
			color: @alert-info-txt-color;
		}		
	}
	.popover.bs-popover-top.alert-info {
		> .popover-arrow {
			&:before {
				border-top-color: @alert-info-color;
			}
			&:after {
				border-top-color: @alert-info-bg-color;
			}	
		}
	}
	.popover.bs-popover-start.alert-info {
		> .popover-arrow {
			&:before {
				border-left-color: @alert-info-color;
			}
			&:after {
				border-left-color: @alert-info-bg-color;
			}	
		}
	}
	.popover.bs-popover-end.alert-info {
		> .popover-arrow {
			&:before {
				border-right-color: @alert-info-color;
			}
			&:after {
				border-right-color: @alert-info-bg-color;
			}	
		}
	}
	.popover.bs-popover-bottom.alert-info {
		> .popover-arrow {
			&:before {
				border-bottom-color: @alert-info-color;
			}
			&:after {
				border-bottom-color: @alert-info-bg-color;
			}		
		}
	}
	
	.popover.alert-password-weak {
		.popover-header,
		.popover-body {
			color: @font-color;
		}		
	}
	.popover.bs-popover-top.alert-password-weak {
		> .popover-arrow {
			&:before {
				border-top-color: @alert-password-weak-color;
			}
			&:after {
				border-top-color: @alert-password-weak-bg-color;
			}	
		}
	}
	.popover.bs-popover-start.alert-password-weak {
		> .popover-arrow {
			&:before {
				border-left-color: @alert-password-weak-color;
			}
			&:after {
				border-left-color: @alert-password-weak-bg-color;
			}	
		}
	}
	.popover.bs-popover-end.alert-password-weak {
		> .popover-arrow {
			&:before {
				border-right-color: @alert-password-weak-color;
			}
			&:after {
				border-right-color: @alert-password-weak-bg-color;
			}	
		}
	}
	.popover.bs-popover-bottom.alert-password-weak {
		> .popover-arrow {
			&:before {
				border-bottom-color: @alert-password-weak-color;
			}
			&:after {
				border-bottom-color: @alert-password-weak-bg-color;
			}		
		}
	}
	
	.popover.alert-password-medium {
		.popover-header,
		.popover-body {
			color: @font-color;
		}		
	}
	.popover.bs-popover-top.alert-password-medium {
		> .popover-arrow {
			&:before {
				border-top-color: @alert-password-medium-color;
			}
			&:after {
				border-top-color: @alert-password-medium-bg-color;
			}	
		}
	}
	.popover.bs-popover-start.alert-password-medium {
		> .popover-arrow {
			&:before {
				border-left-color: @alert-password-medium-color;
			}
			&:after {
				border-left-color: @alert-password-medium-bg-color;
			}	
		}
	}
	.popover.bs-popover-end.alert-password-medium {
		> .popover-arrow {
			&:before {
				border-right-color: @alert-password-medium-color;
			}
			&:after {
				border-right-color: @alert-password-medium-bg-color;
			}	
		}
	}
	.popover.bs-popover-bottom.alert-password-medium {
		> .popover-arrow {
			&:before {
				border-bottom-color: @alert-password-medium-color;
			}
			&:after {
				border-bottom-color: @alert-password-medium-bg-color;
			}		
		}
	}
	
	.popover.alert-password-strong {
		.popover-header,
		.popover-body {
			color: @font-color;
		}		
	}
	.popover.bs-popover-top.alert-password-strong {
		> .popover-arrow {
			&:before {
				border-top-color: @alert-password-strong-color;
			}
			&:after {
				border-top-color: @alert-password-strong-bg-color;
			}	
		}
	}
	.popover.bs-popover-start.alert-password-strong {
		> .popover-arrow {
			&:before {
				border-left-color: @alert-password-strong-color;
			}
			&:after {
				border-left-color: @alert-password-strong-bg-color;
			}	
		}
	}
	.popover.bs-popover-end.alert-password-strong {
		> .popover-arrow {
			&:before {
				border-right-color: @alert-password-strong-color;
			}
			&:after {
				border-right-color: @alert-password-strong-bg-color;
			}	
		}
	}
	.popover.bs-popover-bottom.alert-password-strong {
		> .popover-arrow {
			&:before {
				border-bottom-color: @alert-password-strong-color;
			}
			&:after {
				border-bottom-color: @alert-password-strong-bg-color;
			}		
		}
	}
	
	.popover.info-default {
		background-color: @form-control-background-color;
		border: @form-control-border;
		
		.popover-header {
			color: @form-control-color;
		}
		.popover-body {
			color: @form-control-color;
		}	
	}
	.popover.bs-popover-top.info-default {
		> .popover-arrow {
			&:before {
				border-top: @form-control-border;
				border-top-width: 0.5rem;
			}
			&:after {
				border-top-color: @form-control-background-color;
			}	
		}
	}
	.popover.bs-popover-start.info-default {
		> .popover-arrow {
			&:before {
				border-left: @form-control-border;
				border-left-width: 0.5rem;
			}
			&:after {
				border-left-color: @form-control-background-color;
			}	
		}
	}
	.popover.bs-popover-end.info-default {
		> .popover-arrow {
			&:before {
				border-right: @form-control-border;
				border-right-width: 0.5rem;
			}
			&:after {
				border-right-color: @form-control-background-color;
			}	
		}
	}
	.popover.bs-popover-bottom.info-default {
		> .popover-arrow {
			&:before {
				border-bottom: @form-control-border;
				border-bottom-width: 0.5rem;
			}
			&:after {
				border-bottom-color: @form-control-background-color;
			}		
		}
	}
}
@popovers: popovers;