
@media (prefers-color-scheme: dark) {

	body {
		color: @dm-font-color;
	}

	h1 {
		color: @dm-h1-color;
		border-bottom: @dm-h1-border-bottom;
	}
	h2 {
		color: @dm-h2-color;
		border-bottom: @dm-h2-border-bottom;
	}
	h3 {
		color: @dm-h3-color;
		border-bottom: @dm-h3-border-bottom;
	}

	.highlight {
		color: @dm-highlight-color;
	}
	.highlight-red {
		color: @dm-red;
	}
	.highlight-green {
		color: @dm-green;
	}
	.required {
		color: @dm-required-color;
	}
	.alert-danger .required {
		color: @dm-alert-danger-txt-color;
	}
	.alert-warning .required {
		color: @dm-alert-warning-txt-color;
	} 
	.alert-info .required {
		color: @dm-alert-info-txt-color;
	}  

	.footnote {
		color: @dm-footnote-color;
	} 

	hr {
		background-color: @dm-hr-background-color;
	}
	
}	