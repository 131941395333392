
/* ******************************************* */
/*       Default Customizing - Dark Mode       */
/* ******************************************* */

@media (prefers-color-scheme: dark) {
	/*
        .mixin(@dm-body-bg-cockpit,@dm-cockpit-bg-type);
        .mixin(@dm-body-bg-process,@dm-process-bg-type);
        .mixin(@dm-base-layout);
        .mixin(@dm-header);
        .mixin(@dm-header-menu);
        .mixin(@dm-alerts);
        .mixin(@dm-popovers);
        .mixin(@dm-main-login-card);
        .mixin(@dm-cockpit-cards);
        .mixin(@dm-context-menu);
        .mixin(@dm-process-cards);
        .mixin(@dm-form-rows);
        .mixin(@dm-form-labels);
        .mixin(@dm-form-inputs);
        .mixin(@dm-input-groups);
        .mixin(@dm-calendar);
        .mixin(@dm-file-input);
        .mixin(@dm-form-floating);
        .mixin(@dm-buttons);
        .mixin(@dm-links);
        .mixin(@dm-slider);
        .mixin(@dm-selects);
        .mixin(@dm-autocomplete);
        .mixin(@dm-radios);
        .mixin(@dm-checkboxes);
        .mixin(@dm-meter-value-display);
        .mixin(@dm-tabs);
        .mixin(@dm-modal-dialogue);
        .mixin(@dm-tables);
        .mixin(@dm-datatables);
        .mixin(@dm-nomoretable);
        .mixin(@dm-multi-meter-value-input-table);
        .mixin(@dm-tariff-calculator);
        .mixin(@dm-tariff-cards);
        .mixin(@dm-base-supplier-card);
        .mixin(@dm-accordion);
        .mixin(@dm-marginal-columns);
        .mixin(@dm-footer);
        .mixin(@dm-service-status);
        .mixin(@dm-cash-payments);
        .mixin(@dm-example-invoice);

	 */

        /* integrate EMS - Analyse - dark mode
        .mixin(@dm-ems-integration);
        .mixin(@dm-ems-analysis);
        .mixin(@dm-ems-jstree);
        .mixin(@dm-ems-datetimepicker);
        */
	/* integrate EMS - FFVAVDashboard - dark mode
	.mixin(@dm-ems-eed);
	*/
		
	
	/* ++++++++++++++++++++++++++++++++++ */
	/* Additional Customizing - Dark Mode */
	/* ++++++++++++++++++++++++++++++++++ */
	
	
	
}	
         