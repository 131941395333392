
.mixin(example-invoice) {

	#exampleImageContainer {
		position: relative;
		margin-left: -1rem;
		margin-right: -1rem;
	}
	
	.exampleInvoice {
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	.bubble {
		position: absolute;
		z-index: 2;
		
		background-color: @example-invoice-bubble-background-color;
		color: @example-invoice-bubble-color;
		width: 29px;
		border-radius: 15px;
		top: 13.3%;
		left: 8.5%;
	}

	.bubbleText {
		display: block;
		font-size: 20px;
		text-align: center;
	}
	
}
@example-invoice: example-invoice;	