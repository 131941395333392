
.mixin(context-menu) {
	
	#contextMenu {
		
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		margin: 0 auto;
		z-index: 33;
		width: 100%;

		.login-name {
			background-color: @context-menu-login-name-background-color;
			font-family: @context-menu-login-name-font-family;
			font-weight: @context-menu-login-name-font-weight;
			color: @context-menu-login-name-color;
			padding: @context-menu-login-name-padding;
			font-size: @context-menu-login-name-font-size;
		}

		.accordion-item {
			border: 0px;
			border-radius: 0px;
			margin-bottom: 0px;
			background-color: transparent;
			
			h2.accordion-header {
				border-bottom: 0px;
				margin-top: 0px;
				margin-bottom: 0px;
				padding: 0px;
			}
			
			.accordion-button::after,
			.accordion-button.collapsed::after {
				background: transparent none;
				width: 0px;
				height: 0px;
			}

			.accordion-collapse.show {
				border-radius: 0px;
				-webkit-box-shadow: @shadowed-element-box-shadow;
				-moz-box-shadow: @shadowed-element-box-shadow;
				box-shadow: @shadowed-element-box-shadow;
			}

			.accordion-button {
				padding: 4px 16px;
				border-radius: 0px;
				background-color: @context-menu-accordion-button-background-color-hover-focus;
				border-bottom: @context-menu-accordion-button-border-bottom-hover-focus;

				&:hover, &:focus {
					-webkit-box-shadow: none;
					-moz-box-shadow: none;
					box-shadow: none;
					background-color: @context-menu-accordion-button-background-color-hover-focus;

					.context-menu-level-1,
					.context-menu-level-2,
					.context-menu-level-3 {
						&:before {
							color: @context-menu-level-icon-color-hover-focus;
						}	
					}
					.context-menu-label {
							color: @context-menu-label-color-hover-focus;
					}
					.context-menu-value {
							color: @context-menu-value-color-hover-focus;
					}
				}

				.fa-caret-down {
					display: none;
				}
				.fa-caret-up {
					display: inline-block;
				}

				.context-menu-level-1 {
					&:before {
						content: '\f2bb';
						font-family: 'FontAwesome'; 
						font-weight: 900;
						font-size: 20px;
						padding-top: 5px;
						display: inline-block;
						color: @context-menu-level-icon-color-hover-focus;
					}
				}
				.context-menu-level-2 {
					&:before {
						content: '\f64f';
						font-family: 'FontAwesome'; 
						font-weight: 900;
						font-size: 20px;
						padding-top: 5px;
						display: inline-block;
						color: @context-menu-level-icon-color-hover-focus;
					}
				}
				.context-menu-level-3 {
					&:before {
						content: '\f3fd';
						font-family: 'FontAwesome'; 
						font-weight: 900;
						font-size: 20px;
						padding-top: 5px;
						display: inline-block;
						color: @context-menu-level-icon-color-hover-focus;
					}
				}
				.context-menu-label {
					font-family: @context-menu-label-font-family;
					font-weight: @context-menu-label-font-weight;
					font-size: @context-menu-label-font-size;
					color: @context-menu-label-color-hover-focus;
					padding-left: 40px;
					margin-top: -31px;	
				}
				.context-menu-value {
					font-family: @context-menu-value-font-family;
					font-weight: @context-menu-value-font-weight;
					font-size: @context-menu-value-font-size;
					color: @context-menu-value-color-hover-focus;
					padding-left: 40px;
					margin-top: 1px;	
				}
			}
			.accordion-button:not(.collapsed) {
				-webkit-box-shadow: none;
				-moz-box-shadow: none;
				box-shadow: none;
			}
			.accordion-button.collapsed {

				background-color: @context-menu-accordion-button-background-color;

				&:hover, &:focus {
					background-color: @context-menu-accordion-button-background-color-hover-focus;
					
					.context-menu-level-1,
					.context-menu-level-2,
					.context-menu-level-3 {
						&:before {
							color: @context-menu-level-icon-color-hover-focus;
						}	
					}
					.context-menu-label {
						color: @context-menu-label-color-hover-focus;
					}
					.context-menu-value {
						color: @context-menu-value-color-hover-focus;
					}	
				}	
				
				.fa-caret-down {
					display: inline-block;
				}
				.fa-caret-up {
					display: none;
				}

				.context-menu-level-1,
				.context-menu-level-2,
				.context-menu-level-3 {
					&:before {
						color: @context-menu-level-icon-color;
					}
				}
				.context-menu-label {
					color: @context-menu-label-color;
				}
				.context-menu-value {
					color: @context-menu-value-color;
				}	
			}
			.accordion-button.collapsed.no-toggle {
				cursor: default;
				background-color: @context-menu-accordion-button-background-color;

				&:hover, &:focus {
					background-color: @context-menu-accordion-button-background-color;
				}
				
				.fa-caret-down {
					display: none;
				}
				.fa-caret-up {
					display: none;
				}	
			}

			.accordion-body {
				padding: 0px;
				background-color: transparent;
				border-radius: 0px;
				
				.context-menu-entry {
					display: block;
					width: 100%;
					background-color: @context-menu-entry-background-color;
					border-bottom: @context-menu-entry-border-bottom;
					font-family: @context-menu-entry-font-family;
					font-weight: @context-menu-entry-font-weight;
					font-size: @context-menu-entry-font-size;
					color: @context-menu-entry-color;
					text-decoration: none;
					padding: @context-menu-entry-padding;

					&:hover, &:focus {
						background-color: @context-menu-entry-background-color-hover-focus;
						border-bottom: @context-menu-entry-border-bottom-hover-focus;
						font-family: @context-menu-entry-font-family-hover-focus;
						font-weight: @context-menu-entry-font-weight-hover-focus;
						color: @context-menu-entry-color-hover-focus;		
					}
				}
				.context-menu-entry.active {
					background-color: @context-menu-entry-active-background-color;
					font-family: @context-menu-entry-active-font-family;
					font-weight: @context-menu-entry-active-font-weight;
					color: @context-menu-entry-active-color;
					
					&:hover, &:focus {
						background-color: @context-menu-entry-active-background-color-hover-focus;
						font-family: @context-menu-entry-active-font-family-hover-focus;
						font-weight: @context-menu-entry-active-font-weight-hover-focus;
						color: @context-menu-entry-active-color-hover-focus;
					}
				}
				.context-menu-entry.context-menu-entry-icon-power {
					background-image: @context-menu-entry-icon-power-url;
					background-repeat: no-repeat;
					background-position: @context-menu-entry-icon-power-background-position;
					background-size: @context-menu-entry-icon-power-background-size;
				}
				.context-menu-entry.context-menu-entry-icon-gas {
					background-image: @context-menu-entry-icon-gas-url;
					background-repeat: no-repeat;
					background-position: @context-menu-entry-icon-gas-background-position;
					background-size: @context-menu-entry-icon-gas-background-size;
				}
				.context-menu-entry.context-menu-entry-icon-water {
					background-image: @context-menu-entry-icon-water-url;
					background-repeat: no-repeat;
					background-position: @context-menu-entry-icon-water-background-position;
					background-size: @context-menu-entry-icon-water-background-size;
				}
				.context-menu-entry.context-menu-entry-icon-thermal {
					background-image: @context-menu-entry-icon-thermal-url;
					background-repeat: no-repeat;
					background-position: @context-menu-entry-icon-thermal-background-position;
					background-size: @context-menu-entry-icon-thermal-background-size;
				}
				.context-menu-entry.context-menu-entry-icon-powerCharge {
					background-image: @context-menu-entry-icon-powercharge-url;
					background-repeat: no-repeat;
					background-position: @context-menu-entry-icon-powercharge-background-position;
					background-size: @context-menu-entry-icon-powercharge-background-size;
				}
			}
		}

		@media (max-width: @viewport-md-max) {
		
			.login-name {
				padding: @context-menu-login-name-padding-md;
				font-size: @context-menu-login-name-font-size-md;
			}		
		}		
				
		@media (max-width: @viewport-sm-max) {
			
			position: relative;
			display: none;
			
			.login-name {
				padding: @context-menu-login-name-padding-sm;
				font-size: @context-menu-login-name-font-size-sm;
			}
			.accordion-item {
				.accordion-collapse.show {
					-webkit-box-shadow: none;
					-moz-box-shadow: none;
					box-shadow: none;
				}

				&:last-child {
					.accordion-body {
						border-bottom: @context-menu-accordion-item-last-child-border-bottom-sm;
					}
				}
			}
		}
	}

	.process {
		#contextMenu {
			.accordion-item {
				.accordion-button.collapsed.no-toggle {
					border-bottom: @context-menu-accordion-button-border-bottom-process-no-toggle;

					.context-menu-level-1,
					.context-menu-level-2,
					.context-menu-level-3 {
						&:before {
							color: @context-menu-level-icon-color-process-no-toggle;
						}	
					}
				}
			}

			@media (max-width: @viewport-md-max) {
				width: 50%;
				position: relative;
				float: left;
			}
		}
	}
}
@context-menu: context-menu;