
@font-face {
    font-family: "icomoon-branches";
    src: url("../../fonts/icomoon-branches.eot");
    src: url("../../fonts/icomoon-branches.eot?#iefix") format("embedded-opentype"),
		 url("../../fonts/icomoon-branches.woff") format("woff"),
		 url("../../fonts/icomoon-branches.ttf") format("truetype"),
		 url("../../fonts/icomoon-branches.svg#icomoon") format("svg");
}
.icomoon-branches {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: "icomoon-branches";
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}	
.icomoon-branches-power:before {
    content: "\e901";
}
.icomoon-branches-gas:before {
    content: "\e900";
}
.icomoon-branches-water:before {
    content: "\e903";
}
.icomoon-branches-thermal:before {
    content: "\e902";
}

@font-face {
    font-family: 'PT-Sans';
    src: url('../../fonts/PT-Sans.eot');
    src: url('../../fonts/PT-Sans.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/PT-Sans.woff') format('woff'),
         url('../../fonts/PT-Sans.ttf') format('truetype'),
         url('../../fonts/PT-Sans.svg#PTSansRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PT-Sans-bold';
    src: url('../../fonts/PT-Sans-bold.eot');
    src: url('../../fonts/PT-Sans-bold.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/PT-Sans-bold.woff') format('woff'),
         url('../../fonts/PT-Sans-bold.ttf') format('truetype'),
         url('../../fonts/PT-Sans-bold.svg#PTSansBold') format('svg');
    font-weight: normal;
    font-style: normal;
}


body {
	color: @font-color;
	font-family: @font-regular;
	font-weight: @font-regular-weight;
	font-size: @font-size;
}

.font-normal {
	font-family: @font-regular;
	font-weight: @font-regular-weight;	
}
.font-bold, b, strong {
	font-family: @font-bold;
	font-weight: @font-bold-weight;	
}

h1 {
	color: @h1-color;
	font-family: @h1-font-family;
	font-weight: @h1-font-weight;
	font-size: @h1-font-size;
	border-bottom: @h1-border-bottom;
}
h2 {
	color: @h2-color;
	font-family: @h2-font-family;
	font-weight: @h2-font-weight;
	font-size: @h2-font-size;

	border-bottom: @h2-border-bottom;
	padding-bottom: 4px;
	margin-top: 1rem;
	margin-bottom: 1rem;

	.fa {
		font-size: @font-size;
	}

	@media (max-width: @viewport-sm-max) {
		margin-bottom: 0.5rem;
	}
}
h3 {
	color: @h3-color;
	font-family: @h3-font-family;
	font-weight: @h3-font-weight;
	font-size: @h3-font-size;

	border-bottom: @h3-border-bottom;
	padding-bottom: 4px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	
	.fa {
		font-size: @font-size;
	}
	
	@media (max-width: @viewport-sm-max) {
		margin-bottom: 0.5rem;
	}
}

.highlight {
	color: @highlight-color;
}
.highlight-red {
	color: @red;
}
.highlight-green {
	color: @green;
}
.required {
	color: @required-color;
}
.alert-danger .required {
	color: @alert-danger-txt-color;
}
.alert-warning .required {
	color: @alert-warning-txt-color;
} 
.alert-info .required {
	color: @alert-info-txt-color;
}  

.footnote {
	color: @footnote-color;
	line-height: 17px;
} 

hr {
	background-color: @hr-background-color;
	opacity: 1;
}

.abbreviate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}