
.mixin(selects) {

	.bootstrap-select.form-control {
		.btn.dropdown-toggle {
			background-color: @form-control-background-color;
			border: @form-control-border;
			border-radius: @form-control-border-radius;
			color: @form-control-color;
			font-family: @form-control-font-family;
			font-weight: @form-control-font-weight;
			font-size: @form-control-font-size;

			&:focus {
				-webkit-box-shadow: none;
				-moz-box-shadow: none;
				box-shadow: none;
				outline: 0 !important;
				background-color: @form-control-background-color-focus;
				border: @form-control-border-focus;	
				color: @form-control-color-focus;
				font-family: @form-control-font-family-focus;
				font-weight: @form-control-font-weight-focus;
				font-size: @form-control-font-size-focus;	
			}

			&:after {
				display: inline-block;
				font-family: 'FontAwesome';
				content: '\f0d7';
				font-weight: 900;
				font-size: 20px;
				line-height: 20px;
				border: 0;
				margin-top: 1px;
			}
		}
		.btn.dropdown-toggle.show {
			background-color: @form-control-background-color-focus;
			border: @form-control-border-focus;
			color: @form-control-color-focus;
			font-family: @form-control-font-family-focus;
			font-weight: @form-control-font-weight-focus;
			font-size: @form-control-font-size-focus;	
		}

		.dropdown-menu {
			border-radius: 0px;
			padding: 0px;
			border: @bootstrap-select-dropdown-menu-border;
			background-color: @bootstrap-select-dropdown-menu-background-color;
			-webkit-box-shadow: @bootstrap-select-dropdown-menu-box-shadow;
			-moz-box-shadow: @bootstrap-select-dropdown-menu-box-shadow;
			box-shadow: @bootstrap-select-dropdown-menu-box-shadow;
			z-index: 50;
			inset: -2px auto auto 0px !important;	

			li { 
				.dropdown-item {
					background-color: @bootstrap-select-dropdown-item-background-color;
					border-bottom: @bootstrap-select-dropdown-item-border-bottom;
					font-family: @bootstrap-select-dropdown-item-font-family;
					font-weight: @bootstrap-select-dropdown-item-font-weight;
					font-size: @bootstrap-select-dropdown-item-font-size;
					color: @bootstrap-select-dropdown-item-color;
	
					&:hover, &:focus {
						background-color: @bootstrap-select-dropdown-item-background-color-hover-focus;
						border-bottom: @bootstrap-select-dropdown-item-border-bottom-hover-focus;
						font-family: @bootstrap-select-dropdown-item-font-family-hover-focus;
						font-weight: @bootstrap-select-dropdown-item-font-weight-hover-focus;
						font-size: @bootstrap-select-dropdown-item-font-size-hover-focus;
						color: @bootstrap-select-dropdown-item-color-hover-focus;
					}
				}
				&:last-child {
					.dropdown-item {
						border-bottom: 0px;
					}
				}
			}
			li.selected {
				.dropdown-item {
					background-color: @bootstrap-select-dropdown-item-selected-background-color;
					border-bottom: @bootstrap-select-dropdown-item-selected-border-bottom;
					font-family: @bootstrap-select-dropdown-item-selected-font-family;
					font-weight: @bootstrap-select-dropdown-item-selected-font-weight;
					font-size: @bootstrap-select-dropdown-item-selected-font-size;
					color: @bootstrap-select-dropdown-item-selected-color;
	
					&:hover, &:focus {
						background-color: @bootstrap-select-dropdown-item-selected-background-color-hover-focus;
						border-bottom: @bootstrap-select-dropdown-item-selected-border-bottom-hover-focus;
						font-family: @bootstrap-select-dropdown-item-selected-font-family-hover-focus;
						font-weight: @bootstrap-select-dropdown-item-selected-font-weight-hover-focus;
						font-size: @bootstrap-select-dropdown-item-selected-font-size-hover-focus;
						color: @bootstrap-select-dropdown-item-selected-color-hover-focus;
					}
				}
			}			
		}


		@media (max-width: @viewport-sm-max) {

			.btn.dropdown-toggle {
				font-size: @form-control-font-size-sm;
				
				&:focus {
					font-size: @form-control-font-size-sm-focus;
				}
			}
			.btn.dropdown-toggle.show {
				font-size: @form-control-font-size-sm-focus;
			}
		}		
	}
	.bootstrap-select.form-control.dropup {
		.btn.dropdown-toggle {
			&:after {
				content: '\f0d8';
			}
		}
		.dropdown-menu {			
			inset: auto auto -2px 0px !important;	
		}
	}

	.bootstrap-select.form-control.alert-danger {
		.btn.dropdown-toggle {
			background-color: @alert-danger-bg-color;
			border-color: @alert-danger-color;
			color: @alert-danger-txt-color;
			&:hover, &:focus {
				background-color: @alert-danger-bg-color;
				border-color: @alert-danger-color;
				color: @alert-danger-txt-color;
			}
		}
	}
	.bootstrap-select.form-control.alert-warning {
		.btn.dropdown-toggle {
			background-color: @alert-warning-bg-color;
			border-color: @alert-warning-color;
			color: @alert-warning-txt-color;
			&:hover, &:focus {
				background-color: @alert-warning-bg-color;
				border-color: @alert-warning-color;
				color: @alert-warning-txt-color;
			}
		}
	}
	.bootstrap-select.form-control.alert-info {
		.btn.dropdown-toggle {
			background-color: @alert-info-bg-color;
			border-color: @alert-info-color;
			color: @alert-info-txt-color;
			&:hover, &:focus {
				background-color: @alert-info-bg-color;
				border-color: @alert-info-color;
				color: @alert-info-txt-color;
			}
		}
	}						
	 
}
@selects: selects;



.mixin(autocomplete) {

	.bootstrap-autocomplete.dropdown-menu {
		border: @bootstrap-select-dropdown-menu-border;
		border-radius: 0px;
		-webkit-box-shadow: @bootstrap-select-dropdown-menu-box-shadow;
		-moz-box-shadow: @bootstrap-select-dropdown-menu-box-shadow;
		box-shadow: @bootstrap-select-dropdown-menu-box-shadow;
		padding: 0px;
		
		.dropdown-item {
			background-color: @bootstrap-select-dropdown-item-background-color;
			border-bottom: @bootstrap-select-dropdown-item-border-bottom;
			font-family: @bootstrap-select-dropdown-item-font-family;
			font-weight: @bootstrap-select-dropdown-item-font-weight;
			font-size: @bootstrap-select-dropdown-item-font-size;
			color: @bootstrap-select-dropdown-item-color;
	
			&:hover, &:focus {
				background-color: @bootstrap-select-dropdown-item-background-color-hover-focus;
				border-bottom: @bootstrap-select-dropdown-item-border-bottom-hover-focus;
				font-family: @bootstrap-select-dropdown-item-font-family-hover-focus;
				font-weight: @bootstrap-select-dropdown-item-font-weight-hover-focus;
				font-size: @bootstrap-select-dropdown-item-font-size-hover-focus;
				color: @bootstrap-select-dropdown-item-color-hover-focus;
			}
				
			&:last-child {
				border-bottom: 0px;
			}
		}

		.dropdown-item.active {
			background-color: @bootstrap-select-dropdown-item-background-color-hover-focus;
			border-bottom: @bootstrap-select-dropdown-item-border-bottom-hover-focus;
			font-family: @bootstrap-select-dropdown-item-font-family-hover-focus;
			font-weight: @bootstrap-select-dropdown-item-font-weight-hover-focus;
			font-size: @bootstrap-select-dropdown-item-font-size-hover-focus;
			color: @bootstrap-select-dropdown-item-color-hover-focus;
	
			&:hover, &:focus {
				background-color: @bootstrap-select-dropdown-item-background-color-hover-focus;
				border-bottom: @bootstrap-select-dropdown-item-border-bottom-hover-focus;
				font-family: @bootstrap-select-dropdown-item-font-family-hover-focus;
				font-weight: @bootstrap-select-dropdown-item-font-weight-hover-focus;
				font-size: @bootstrap-select-dropdown-item-font-size-hover-focus;
				color: @bootstrap-select-dropdown-item-color-hover-focus;
			}
				
			&:last-child {
				border-bottom: 0px;
			}
		}		
	}		
}
@autocomplete: autocomplete;