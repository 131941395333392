.mixin(radios) {

	/* hide the radios */
	input[type=radio]:not(old) {
		margin: 0;
		padding: 0;
		font-size: 1em;
		opacity: 0;
		position: absolute;
		z-index: -1;
	}

	/* positioning the label of radios */
	input[type=radio]:not(old) + label {
		display: inline-block;
		font-family: @radio-checkbox-label-font-family;
		font-weight: @radio-checkbox-label-font-weight;
		font-size: @radio-checkbox-label-font-size;
		color: @radio-checkbox-label-color;
		max-width: 100%;
		float: left;
		
		div.radio-label {
			padding-left: 16px;
			margin-top: -24px;
			margin-right: 16px;
			margin-left: 16px;
			
			a {
				outline: none;
			}	
			
			&:hover {
				cursor: pointer;
			}
		}
		div.radio-label.radio-label-branch-icon.power {
			background: transparent @radio-checkbox-branch-icon-power-url no-repeat @radio-checkbox-branch-icon-power-background-position;
			background-size: @radio-checkbox-branch-icon-power-background-size;
			padding-left: 32px;
		}
		div.radio-label.radio-label-branch-icon.gas {
			background: transparent @radio-checkbox-branch-icon-gas-url no-repeat @radio-checkbox-branch-icon-gas-background-position;
			background-size: @radio-checkbox-branch-icon-gas-background-size;
			padding-left: 34px;
		}
		div.radio-label.radio-label-branch-icon.water {
			background: transparent @radio-checkbox-branch-icon-water-url no-repeat @radio-checkbox-branch-icon-water-background-position;
			background-size: @radio-checkbox-branch-icon-water-background-size;
			padding-left: 38px;
		}
		div.radio-label.radio-label-branch-icon.thermal {
			background: transparent @radio-checkbox-branch-icon-thermal-url no-repeat @radio-checkbox-branch-icon-thermal-background-position;
			background-size: @radio-checkbox-branch-icon-thermal-background-size;
			padding-left: 38px;
		}
		div.radio-label.radio-label-branch-icon.powerCharge {
			background: transparent @radio-checkbox-branch-icon-powercharge-url no-repeat @radio-checkbox-branch-icon-powercharge-background-position;
			background-size: @radio-checkbox-branch-icon-powercharge-background-size;
			padding-left: 38px;
		}
		div.radio-label.radio-label-branch-icon.wastewater {
			background: transparent @radio-checkbox-branch-icon-wastewater-url no-repeat @radio-checkbox-branch-icon-wastewater-background-position;
			background-size: @radio-checkbox-branch-icon-wastewater-background-size;
			padding-left: 38px;
		}	
	}
	
	/* styling unchecked radios [gradient is possible] */
	input[type=radio]:not(old) + label > span {
		display: inline-block;
		width: 22px;
		height: 22px;
		margin: 7px 0px 2px 0px;	
		border: @radio-checkbox-unchecked-border;
		border-radius: 15px;
		background: none @radio-checkbox-unchecked-background-gradient-start;
		background-image: -moz-linear-gradient(@radio-checkbox-unchecked-background-gradient-start,@radio-checkbox-unchecked-background-gradient-end);
		background-image: -ms-linear-gradient(@radio-checkbox-unchecked-background-gradient-start,@radio-checkbox-unchecked-background-gradient-end);
		background-image: -o-linear-gradient(@radio-checkbox-unchecked-background-gradient-start,@radio-checkbox-unchecked-background-gradient-end);
		background-image: -webkit-linear-gradient(@radio-checkbox-unchecked-background-gradient-start,@radio-checkbox-unchecked-background-gradient-end);
		background-image: linear-gradient(@radio-checkbox-unchecked-background-gradient-start,@radio-checkbox-unchecked-background-gradient-end);
		vertical-align: bottom;
				
		&:hover {
			cursor: pointer;
		}
	}
	input[type=radio]:not(old):focus + label > span {
		border-color: @radio-checkbox-unchecked-border-color-focus;
	}

	/* styling checked radios [gradient is possible] */
	input[type=radio]:not(old):checked + label > span {
		border: @radio-checkbox-checked-border;
		background-image: -moz-linear-gradient(@radio-checkbox-checked-background-gradient-start,@radio-checkbox-checked-background-gradient-end);
		background-image: -ms-linear-gradient(@radio-checkbox-checked-background-gradient-start,@radio-checkbox-checked-background-gradient-end);
		background-image: -o-linear-gradient(@radio-checkbox-checked-background-gradient-start,@radio-checkbox-checked-background-gradient-end);
		background-image: -webkit-linear-gradient(@radio-checkbox-checked-background-gradient-start,@radio-checkbox-checked-background-gradient-end);
		background-image:  linear-gradient(@radio-checkbox-checked-background-gradient-start,@radio-checkbox-checked-background-gradient-end);
		
		&:hover {
			cursor: pointer;
		}
	}
	input[type=radio]:not(old):checked:focus + label > span {
		border-color: @radio-checkbox-checked-border-color-focus;
	}

	/* bullet inside selected radios [gradient is possible] */
	input[type=radio]:not(old):checked + label > span > span {
		display: block;
		width: 10px;
		height: 10px;
		margin: 5px;
		border: @radio-bullet-border;
		border-radius: 5px;
		background: none @radio-bullet-background-gradient-start;
		background-image: -moz-linear-gradient(@radio-bullet-background-gradient-start,@radio-bullet-background-gradient-end);
		background-image: -ms-linear-gradient(@radio-bullet-background-gradient-start,@radio-bullet-background-gradient-end);
		background-image: -o-linear-gradient(@radio-bullet-background-gradient-start,@radio-bullet-background-gradient-end);
		background-image: -webkit-linear-gradient(@radio-bullet-background-gradient-start,@radio-bullet-background-gradient-end);
		background-image:  linear-gradient(@radio-bullet-background-gradient-start,@radio-bullet-background-gradient-end);
	}

	/* styling unchecked disabled radios [gradient is possible] */
	input[type=radio]:not(old):disabled + label > span {
		border-color: @inactive-border-color;
		background: none @inactive-bg-color;
		background-image: -moz-linear-gradient(@inactive-bg-color,@inactive-bg-color);
		background-image: -ms-linear-gradient(@inactive-bg-color,@inactive-bg-color);
		background-image: -o-linear-gradient(@inactive-bg-color,@inactive-bg-color);
		background-image: -webkit-linear-gradient(@inactive-bg-color,@inactive-bg-color);
		background-image: linear-gradient(@inactive-bg-color,@inactive-bg-color);
		&:hover {
			cursor: not-allowed;
		}
	}
	
	/* styling checked disabled radios [gradient is possible] */
	input[type=radio]:not(old):checked:disabled + label > span {
		background-image: -moz-linear-gradient(@inactive-bg-color,@inactive-bg-color);
		background-image: -ms-linear-gradient(@inactive-bg-color,@inactive-bg-color);
		background-image: -o-linear-gradient(@inactive-bg-color,@inactive-bg-color);
		background-image: -webkit-linear-gradient(@inactive-bg-color,@inactive-bg-color);
		background-image:  linear-gradient(@inactive-bg-color,@inactive-bg-color);
		&:hover {
			cursor: not-allowed;
		}
	}
	
	/* bullet inside selected disabled radios [gradient is possible] */
	input[type=radio]:not(old):checked:disabled + label > span > span {
		border-color: @inactive-color;
		background: none @inactive-color;
		background-image: -moz-linear-gradient(@inactive-color,@inactive-color);
		background-image: -ms-linear-gradient(@inactive-color,@inactive-color);
		background-image: -o-linear-gradient(@inactive-color,@inactive-color);
		background-image: -webkit-linear-gradient(@inactive-color,@inactive-color);
		background-image:  linear-gradient(@inactive-color,@inactive-color);
	}

	/* alert-danger marked radios */
	input[type=radio]:not(old) + label.alert-danger {
		background-color: transparent;
		color: @alert-danger-txt-color;
	}
	input[type=radio]:not(old) + label.alert-danger > span {
		border-color: @alert-danger-color;
		background: none @alert-danger-bg-color;
		background-image: -moz-linear-gradient(@alert-danger-bg-color,@alert-danger-bg-color);
		background-image: -ms-linear-gradient(@alert-danger-bg-color,@alert-danger-bg-color);
		background-image: -o-linear-gradient(@alert-danger-bg-color,@alert-danger-bg-color);
		background-image: -webkit-linear-gradient(@alert-danger-bg-color,@alert-danger-bg-color);
		background-image: linear-gradient(@alert-danger-bg-color,@alert-danger-bg-color);
	}
	input[type=radio]:not(old):focus + label.alert-danger > span {
		border-color: @alert-danger-color;
	}
	input[type=radio]:not(old):checked + label.alert-danger > span > span {
		border-color: @alert-danger-color;
		background: none @alert-danger-color;
		background-image: -moz-linear-gradient(@alert-danger-color,@alert-danger-color);
		background-image: -ms-linear-gradient(@alert-danger-color,@alert-danger-color);
		background-image: -o-linear-gradient(@alert-danger-color,@alert-danger-color);
		background-image: -webkit-linear-gradient(@alert-danger-color,@alert-danger-color);
		background-image:  linear-gradient(@alert-danger-color,@alert-danger-color);
	}	

	/* alert-warning marked radios */
	input[type=radio]:not(old) + label.alert-warning {
		background-color: transparent;
		color: @alert-warning-txt-color;
	}
	input[type=radio]:not(old) + label.alert-warning > span {
		border-color: @alert-warning-color;
		background: none @alert-warning-bg-color;
		background-image: -moz-linear-gradient(@alert-warning-bg-color,@alert-warning-bg-color);
		background-image: -ms-linear-gradient(@alert-warning-bg-color,@alert-warning-bg-color);
		background-image: -o-linear-gradient(@alert-warning-bg-color,@alert-warning-bg-color);
		background-image: -webkit-linear-gradient(@alert-warning-bg-color,@alert-warning-bg-color);
		background-image: linear-gradient(@alert-warning-bg-color,@alert-warning-bg-color);
	}
	input[type=radio]:not(old):focus + label.alert-warning > span {
		border-color: @alert-warning-color;
	}	
	input[type=radio]:not(old):checked + label.alert-warning > span > span {
		border-color: @alert-warning-color;
		background: none @alert-warning-color;
		background-image: -moz-linear-gradient(@alert-warning-color,@alert-warning-color);
		background-image: -ms-linear-gradient(@alert-warning-color,@alert-warning-color);
		background-image: -o-linear-gradient(@alert-warning-color,@alert-warning-color);
		background-image: -webkit-linear-gradient(@alert-warning-color,@alert-warning-color);
		background-image:  linear-gradient(@alert-warning-color,@alert-warning-color);
	}	

	/* alert-info marked radios */
	input[type=radio]:not(old) + label.alert-info {
		background-color: transparent;
		color: @alert-info-txt-color;
	}
	input[type=radio]:not(old) + label.alert-info > span {
		border-color: @alert-info-color;
		background: none @alert-info-bg-color;
		background-image: -moz-linear-gradient(@alert-info-bg-color,@alert-info-bg-color);
		background-image: -ms-linear-gradient(@alert-info-bg-color,@alert-info-bg-color);
		background-image: -o-linear-gradient(@alert-info-bg-color,@alert-info-bg-color);
		background-image: -webkit-linear-gradient(@alert-info-bg-color,@alert-info-bg-color);
		background-image: linear-gradient(@alert-info-bg-color,@alert-info-bg-color);
	}
	input[type=radio]:not(old):focus + label.alert-info > span {
		border-color: @alert-info-color;
	}	
	input[type=radio]:not(old):checked + label.alert-info > span > span {
		border-color: @alert-info-color;
		background: none @alert-info-color;
		background-image: -moz-linear-gradient(@alert-info-color,@alert-info-color);
		background-image: -ms-linear-gradient(@alert-info-color,@alert-info-color);
		background-image: -o-linear-gradient(@alert-info-color,@alert-info-color);
		background-image: -webkit-linear-gradient(@alert-info-color,@alert-info-color);
		background-image:  linear-gradient(@alert-info-color,@alert-info-color);
	}	

	.card .card .radio-card-header {
		padding-top: 0.2rem;
		padding-bottom: 0.5rem;
		
		input[type=radio]:not(old) + label {
			color: @main-process-card-in-card-title-color;
		}
	}

	table th,
	table td {
		input[type=radio]:not(old) + label > span {
			margin: 0px;
		}	
	}
		
	.dual-choice-radio-button-group {
		display: flex;

		div {
			display: flex;
		}
		
		input[type="radio"]:not(old) {
		
			+ label {
			
				text-align: center;
				padding: 8px;
				cursor: pointer;
				width: 50%;
			
				background-color:@button-default-background-color;
				border: @button-default-border;
				border-radius: @button-border-radius;
							
				div.radio-label {
					margin-top: 0px;
					padding-left: 0px;
					color: @button-default-color;
				}
				
				&:hover {
				
					background-color: @button-default-background-color-hover-focus;
					border: @button-default-border-hover-focus;
					
					div.radio-label {
						color: @button-default-color-hover-focus;
					}	
				}
			}

			&:first-of-type + label {
				margin-right: 8px;
			}
			&:last-of-type + label {
				margin-left: 8px;
			}		
		}
			
		input[type=radio]:not(old):checked + label {
			background: @button-primary-background-color;
			border: @button-primary-border;
			
			div.radio-label {
				color: @button-primary-color;
			}
		}	
	}
}
@radios: radios;


.mixin(checkboxes) {

	/* hide the checkboxes */
	input[type=checkbox]:not(old) {
		margin: 0;
		padding: 0;
		font-size: 1em;
		opacity: 0;
		position: absolute;
		z-index: -1;
	}

	/* positioning the label of checkboxes */
	input[type=checkbox]:not(old) + label {
		display: inline-block;
		font-family: @radio-checkbox-label-font-family;
		font-weight: @radio-checkbox-label-font-weight;
		font-size: @radio-checkbox-label-font-size;
		color: @radio-checkbox-label-color;
		max-width: 100%;
		float: left;
		
		div.checkbox-label {
			padding-left: 16px;
			margin-top: -24px;
			margin-right: 16px;
			margin-left: 16px;
			
			a {
				outline: none;
			}		
			
			&:hover {
				cursor: pointer;
			}
		}
		div.checkbox-label.checkbox-label-branch-icon.power {
			background: transparent @radio-checkbox-branch-icon-power-url no-repeat @radio-checkbox-branch-icon-power-background-position;
			background-size: @radio-checkbox-branch-icon-power-background-size;
			padding-left: 32px;
		}
		div.checkbox-label.checkbox-label-branch-icon.gas {
			background: transparent @radio-checkbox-branch-icon-gas-url no-repeat @radio-checkbox-branch-icon-gas-background-position;
			background-size: @radio-checkbox-branch-icon-gas-background-size;
			padding-left: 34px;
		}
		div.checkbox-label.checkbox-label-branch-icon.water {
			background: transparent @radio-checkbox-branch-icon-water-url no-repeat @radio-checkbox-branch-icon-water-background-position;
			background-size: @radio-checkbox-branch-icon-water-background-size;
			padding-left: 38px;
		}
		div.checkbox-label.checkbox-label-branch-icon.thermal {
			background: transparent @radio-checkbox-branch-icon-thermal-url no-repeat @radio-checkbox-branch-icon-thermal-background-position;
			background-size: @radio-checkbox-branch-icon-thermal-background-size;
			padding-left: 38px;
		}
		div.checkbox-label.checkbox-label-branch-icon.powerCharge {
			background: transparent @radio-checkbox-branch-icon-powercharge-url no-repeat @radio-checkbox-branch-icon-powercharge-background-position;
			background-size: @radio-checkbox-branch-icon-powercharge-background-size;
			padding-left: 38px;
		}
		div.checkbox-label.checkbox-label-branch-icon.wastewater {
			background: transparent @radio-checkbox-branch-icon-wastewater-url no-repeat @radio-checkbox-branch-icon-wastewater-background-position;
			background-size: @radio-checkbox-branch-icon-wastewater-background-size;
			padding-left: 38px;
		}	
	}	
	
	/* styling unchecked checkboxes [gradient is possible] */
	input[type=checkbox]:not(old) + label > span {
		display: inline-block;
		width: 22px;
		height: 22px;
		margin: 7px 0px 2px 0px;
		border: @radio-checkbox-unchecked-border;
		border-radius: 4px;
		background: none @radio-checkbox-unchecked-background-gradient-start;
		background-image: -moz-linear-gradient(@radio-checkbox-unchecked-background-gradient-start,@radio-checkbox-unchecked-background-gradient-end);
		background-image: -ms-linear-gradient(@radio-checkbox-unchecked-background-gradient-start,@radio-checkbox-unchecked-background-gradient-end);
		background-image: -o-linear-gradient(@radio-checkbox-unchecked-background-gradient-start,@radio-checkbox-unchecked-background-gradient-end);
		background-image: -webkit-linear-gradient(@radio-checkbox-unchecked-background-gradient-start,@radio-checkbox-unchecked-background-gradient-end);
		background-image: linear-gradient(@radio-checkbox-unchecked-background-gradient-start,@radio-checkbox-unchecked-background-gradient-end);
		vertical-align: bottom;
		
		&:hover {
			cursor: pointer;
		}
	}
	input[type=checkbox]:not(old):focus + label > span {
		border-color: @radio-checkbox-unchecked-border-color-focus;
	}
	
	/* styling checked checkboxes [gradient is possible] */
	input[type=checkbox]:not(old):checked + label > span {
		border: @radio-checkbox-checked-border;
		background-image: -moz-linear-gradient(@radio-checkbox-checked-background-gradient-start,@radio-checkbox-checked-background-gradient-end);
		background-image: -ms-linear-gradient(@radio-checkbox-checked-background-gradient-start,@radio-checkbox-checked-background-gradient-end);
		background-image: -o-linear-gradient(@radio-checkbox-checked-background-gradient-start,@radio-checkbox-checked-background-gradient-end);
		background-image: -webkit-linear-gradient(@radio-checkbox-checked-background-gradient-start,@radio-checkbox-checked-background-gradient-end);
		background-image:  linear-gradient(@radio-checkbox-checked-background-gradient-start,@radio-checkbox-checked-background-gradient-end);
		
		&:hover {
			cursor: pointer;
		}
	}
	input[type=checkbox]:not(old):checked:focus + label > span {
		border-color: @radio-checkbox-checked-border-color-focus;
	}

	/* tick inside selected checkboxes */
	input[type=checkbox]:not(old):checked + label > span:before {
		content: '\f00c';
		display: block;
		color: @checkbox-tick-color;
		text-align: center;
		font-family: 'FontAwesome';
		font-weight: 900;
		font-size: 16px;
		line-height: 20px;
	}

	/* styling unchecked disabled checkboxes [gradient is possible] */
	input[type=checkbox]:not(old):disabled + label > span {
		border-color: @inactive-border-color;		
		background: none @inactive-bg-color;
		background-image: -moz-linear-gradient(@inactive-bg-color,@inactive-bg-color);
		background-image: -ms-linear-gradient(@inactive-bg-color,@inactive-bg-color);
		background-image: -o-linear-gradient(@inactive-bg-color,@inactive-bg-color);
		background-image: -webkit-linear-gradient(@inactive-bg-color,@inactive-bg-color);
		background-image: linear-gradient(@inactive-bg-color,@inactive-bg-color);
		&:hover {
			cursor: not-allowed;
		}
	}

	/* styling checked disabled checkboxes [gradient is possible] */
	input[type=checkbox]:not(old):checked:disabled + label > span {
		background-image: -moz-linear-gradient(@inactive-bg-color,@inactive-bg-color);
		background-image: -ms-linear-gradient(@inactive-bg-color,@inactive-bg-color);
		background-image: -o-linear-gradient(@inactive-bg-color,@inactive-bg-color);
		background-image: -webkit-linear-gradient(@inactive-bg-color,@inactive-bg-color);
		background-image:  linear-gradient(@inactive-bg-color,@inactive-bg-color);
		&:hover {
			cursor: not-allowed;
		}
	}
	
	/* tick inside selected disabled checkboxes */
	input[type=checkbox]:not(old):checked:disabled + label > span:before {
		color: @inactive-color;		
	}

	/* alert-danger marked checkboxes */
	input[type=checkbox]:not(old) + label.alert-danger {
		background-color: transparent;
		color: @alert-danger-txt-color;
	}
	input[type=checkbox]:not(old) + label.alert-danger > span {
		border-color: @alert-danger-color;
		background: none @alert-danger-bg-color;
		background-image: -moz-linear-gradient(@alert-danger-bg-color,@alert-danger-bg-color);
		background-image: -ms-linear-gradient(@alert-danger-bg-color,@alert-danger-bg-color);
		background-image: -o-linear-gradient(@alert-danger-bg-color,@alert-danger-bg-color);
		background-image: -webkit-linear-gradient(@alert-danger-bg-color,@alert-danger-bg-color);
		background-image: linear-gradient(@alert-danger-bg-color,@alert-danger-bg-color);
	}
	input[type=checkbox]:not(old):checked + label.alert-danger > span:before {
		color: @alert-danger-color;
	}
	input[type=checkbox]:not(old):focus + label.alert-danger > span, 
	input[type=checkbox]:not(old):checked:focus + label.alert-danger > span {
		border-color: @alert-danger-color;
	}	

	/* alert-warning marked checkboxes */
	input[type=checkbox]:not(old) + label.alert-warning {
		background-color: transparent;
		color: @alert-warning-txt-color;
	}
	input[type=checkbox]:not(old) + label.alert-warning > span {
		border-color: @alert-warning-color;
		background: none @alert-warning-bg-color;
		background-image: -moz-linear-gradient(@alert-warning-bg-color,@alert-warning-bg-color);
		background-image: -ms-linear-gradient(@alert-warning-bg-color,@alert-warning-bg-color);
		background-image: -o-linear-gradient(@alert-warning-bg-color,@alert-warning-bg-color);
		background-image: -webkit-linear-gradient(@alert-warning-bg-color,@alert-warning-bg-color);
		background-image: linear-gradient(@alert-warning-bg-color,@alert-warning-bg-color);
	}
	input[type=checkbox]:not(old):checked + label.alert-warning > span:before {
		color: @alert-warning-color;
	}
	input[type=checkbox]:not(old):focus + label.alert-warning > span, 
	input[type=checkbox]:not(old):checked:focus + label.alert-warning > span {
		border-color: @alert-warning-color;
	}	

	/* alert-info marked checkboxes */
	input[type=checkbox]:not(old) + label.alert-info {
		background-color: transparent;
		color: @alert-info-txt-color;
	}
	input[type=checkbox]:not(old) + label.alert-info > span {
		border-color: @alert-info-color;
		background: none @alert-info-bg-color;
		background-image: -moz-linear-gradient(@alert-info-bg-color,@alert-info-bg-color);
		background-image: -ms-linear-gradient(@alert-info-bg-color,@alert-info-bg-color);
		background-image: -o-linear-gradient(@alert-info-bg-color,@alert-info-bg-color);
		background-image: -webkit-linear-gradient(@alert-info-bg-color,@alert-info-bg-color);
		background-image: linear-gradient(@alert-info-bg-color,@alert-info-bg-color);
	}
	input[type=checkbox]:not(old):checked + label.alert-info > span:before {
		color: @alert-info-color;
	}
	input[type=checkbox]:not(old):focus + label.alert-info > span,
	input[type=checkbox]:not(old):checked:focus + label.alert-info > span {
		border-color: @alert-info-color;
	}	

	.card .card .checkbox-card-header {
		padding-top: 0.2rem;
		padding-bottom: 0.5rem;
		
		input[type=checkbox]:not(old) + label {
			color: @main-process-card-in-card-title-color;
		}
	}
	
	table th,
	table td {
		input[type=checkbox]:not(old) + label > span {
			margin: 0px;
		}	
	}
}
@checkboxes: checkboxes;
	