.mixin(header-menu) {
	#header-menu {
		margin-top: @header-menu-margin-top;
		
		> div.float-end > a {
			margin-right: 18px;
			text-decoration: none;
			
			.fa {
				color: @header-menu-icon-color;
			}
			
			&:hover, &:focus {
				text-decoration: none;
				
				.fa {
					color: @header-menu-icon-color-hover;
				}
			}
			&:after {
				display: none !important;
			}
		}
		.fa-user-cog {
			display: block;
			float: left;
			margin-right: 3px;
			margin-top: 0px;
			font-size: 27px;
		}
		.fa-caret-down {
			font-size: 20px;
			margin-top: 3px;
		}
		.fa-envelope {
			font-size: 24px;
			display: block;
			margin-top: 6px;
		}
		.fa-envelope.no-news {
			margin-right: 20px;
		}
		.postbox-badge {
			background-color: @header-menu-postbox-badge-background-color;
			color: @header-menu-postbox-badge-color;
			margin-left: 7px;
			margin-top: -32px;
			margin-right: 13px;
			padding: 2px 6px 3px 6px;
			border-radius: 10px;
			font-family: @header-menu-postbox-badge-font-family;
			font-weight: @header-menu-postbox-badge-font-weight;
			font-size: @header-menu-postbox-badge-font-size;
		}
		#logout {
			background-color: @header-menu-logout-background-color;
			padding: 2px 16px 3px 16px;
			margin-right: 0px;
			margin-top: -3px;
			border-top-left-radius: @header-menu-logout-border-radius;
			border-bottom-left-radius: @header-menu-logout-border-radius;	
			font-size: @header-menu-logout-font-size;
			font-family: @header-menu-logout-font-family;
			font-weight: @header-menu-logout-font-weight;
			color: @header-menu-logout-color;
			display: inline-block;
			
			.fa {
				color: @header-menu-logout-icon-color;
			}
			
			&:hover, &:focus {
				background-color: @header-menu-logout-background-color-hover;
				color: @header-menu-logout-color-hover;
				
				.fa {
					color: @header-menu-logout-icon-color-hover;
				}
			}
			@media (min-width: (@viewport-xxl-max + 1px)) {
				border-top-right-radius: @header-menu-logout-border-radius;
				border-bottom-right-radius: @header-menu-logout-border-radius;	
			}
		}
		
		ul.dropdown-menu.show {
			position: fixed !important;
			left: auto !important;
			right: @header-menu-dropdown-menu-right !important;
			margin: 0px auto !important;
			top: @header-height !important;
			max-height: 250px;
			overflow-y: auto;
			transform: none !important;
			padding: 0px;
			border-radius: 0px;
			border: @header-menu-dropdown-menu-border;
			border-top: 0px;
			-webkit-box-shadow: @header-menu-dropdown-menu-box-shadow;
			-moz-box-shadow: @header-menu-dropdown-menu-box-shadow;
			box-shadow: @header-menu-dropdown-menu-box-shadow;
			
			> li {
				
				> a.dropdown-item {
					background-color: @header-menu-dropdown-item-background-color;
					border-bottom: @header-menu-dropdown-item-border-bottom;
					color: @header-menu-dropdown-item-color;
					font-family: @header-menu-dropdown-item-font-family;
					font-weight: @header-menu-dropdown-item-font-weight;
					font-size: @header-menu-dropdown-item-font-size;
					padding: @header-menu-dropdown-item-padding;
					
					&:hover, &:focus {
						background-color: @header-menu-dropdown-item-background-color-hover;
						border-bottom: @header-menu-dropdown-item-border-bottom-hover;
						color: @header-menu-dropdown-item-color-hover;
						font-family: @header-menu-dropdown-item-font-family-hover;
						font-weight: @header-menu-dropdown-item-font-weight-hover;
						font-size: @header-menu-dropdown-item-font-size-hover;
						border-left: @header-menu-dropdown-item-border-left-hover;
						padding: @header-menu-dropdown-item-padding-hover;
					}
				}	
				
				&:last-child {
					> a.dropdown-item {
						border-bottom: 0px;
					}	
				}
			}
			
			@media (min-width: (@viewport-xxl-max + 1px)) {
				right: calc(~'((100% - @{viewport-xxl-max}) / 2) + @{header-menu-dropdown-menu-right}') !important;
			}
		}	

		@media (max-width: @viewport-sm-max) {
			margin-top: @header-menu-margin-top-sm;
			
			#logout {
				padding: 0px 16px;
				height: 28px;
				margin-top: 2px;
			}
			.fa-user-cog {
				font-size: 24px;
			}
			.fa-envelope {
				font-size: 20px;
			}
			.postbox-badge {
				margin-left: 2px;
				margin-top: -30px;
			}
			.fa-sign-out-alt {
				&:before {
					margin-top: 0px;
				}
			}
			
			ul.dropdown-menu.show {
				top: @header-height-sm !important;
				right: @header-menu-dropdown-menu-right-sm !important;
			}
		}
		@media (max-width: @viewport-xs-max) {
			ul.dropdown-menu.show {
				right: @header-menu-dropdown-menu-right-xs !important;
				max-width: calc(~'100% - ( 2 * @{header-menu-dropdown-menu-right-xs} )');
			}	
		}
	}
}
@header-menu: header-menu;	