
.mixin(modal-dialogue) {

	.modal-dialog {
		.modal-content {
			border: @modal-content-border;
			border-radius: @modal-content-border-radius;
			
			.modal-header {
				border-top-left-radius: @modal-content-border-radius;
				border-top-right-radius: @modal-content-border-radius;
				padding: 0.5rem 1rem;
				background-color: @modal-header-background-color;
				border-bottom: @modal-header-border-bottom;
				
				.modal-title {
					font-family: @modal-title-font-family;
					font-weight: @modal-title-font-weight;
					font-size: @modal-title-font-size;
					color: @modal-title-color;
					border-bottom: 0px;
					padding-bottom: 0px;
					margin-top: 0px;
					margin-bottom: 0px;
				}
				
				.btn-close {
					background: transparent none;
					opacity: 1.0;
					color: @modal-title-color;
					&:focus,&:active {
						border: 0px !important;
						outline: none !important;
						box-shadow: none;
						color: @modal-title-color;
					}
					&:before {
						font-size: 18px;
					}	
				}
			}
			.modal-body {
				background-color: @modal-body-background-color;
				
				h3[data-bs-toggle=collapse] {
					padding-bottom: 0.5rem;
					cursor: pointer;
					
					.fa-caret-down {
						display: none;
						margin-top: 0.75rem;
					}
					.fa-caret-up {
						display: inline-block;
						margin-top: 0.75rem;
					}	
				}
				h3[data-bs-toggle=collapse].collapsed {
										
					.fa-caret-down {
						display: inline-block;
					}
					.fa-caret-up {
						display: none;
					}	
				}
			}
			.modal-footer {
				border-bottom-left-radius: @modal-content-border-radius;
				border-bottom-right-radius: @modal-content-border-radius;
				background-color: @modal-footer-background-color;
				border-top: @modal-footer-border-top;
			}
		}	
	}

	@media (min-width: 700px) {
		.modal-dialog-wide {
			max-width: 700px;
		}
	}
}
@modal-dialogue: modal-dialogue;