
.mixin(marginal-columns) {
	.main-marginal-column {
		.card {
			margin-left: 1rem;
			margin-right: 1rem;
			border: @marginal-card-border;
			border-radius: @marginal-card-border-radius;

			.card-header {
				height: @main-process-top-stripe-height;
				background-color: @marginal-card-header-background-color;
				padding: 0.5rem 1rem;
				border-top-left-radius: @marginal-card-border-radius;
				border-top-right-radius: @marginal-card-border-radius;
				border-bottom: @marginal-card-header-border-bottom;
				
				h3 {
					border-bottom: 0px;
					padding-bottom: 0px;
					font-family: @marginal-card-header-font-family;
					font-weight: @marginal-card-header-font-weight;
					font-size: @marginal-card-header-font-size;
					color: @marginal-card-header-color;
					margin-top: 2px;
					margin-bottom: 0px;
				}
			}
			.card-body {
				border-bottom-left-radius: @marginal-card-border-radius;
				border-bottom-right-radius: @marginal-card-border-radius;
				background-color: @marginal-card-body-background-color;
			}
			
			@media (max-width: calc(~'@{main-context-and-process-std-viewport-min-width} - 1px')) {
				margin-left: 0px;
				margin-right: 0px;
				padding-top: 1.75rem;
				
				.card-header {
					height: auto;
					background-color: @non-marginal-card-header-background-color;
					
					h3 {
						color: @non-marginal-card-header-color;
					}
				}
			}
			@media (max-width: @viewport-sm-max) {
				padding-top: 0px;
			}
		}
		.card.shopping-cart {
			.card-body {
				padding: 0px;
				
				.accordion-item {
					margin-top: 1px;
					
					.accordion-header {
						background-color: @marginal-shopping-cart-header-background-color;
						
						.accordion-button {
						
							font-family: @marginal-shopping-cart-header-font-family;
							font-weight: @marginal-shopping-cart-header-font-weight;
							font-size: @marginal-shopping-cart-header-font-size;
							color: @marginal-shopping-cart-header-color;
							background-color: @marginal-shopping-cart-header-background-color;	
						
					
							.branch-icon-before-text.power {
								display: inline-block;
								background: transparent @marginal-shopping-cart-header-branch-icon-power-url no-repeat @marginal-shopping-cart-header-branch-icon-power-background-position;
								background-size: @marginal-shopping-cart-header-branch-icon-power-background-size;
								padding-left: 15px;	
							}			
							.branch-icon-before-text.gas {
								display: inline-block;
								background: transparent @marginal-shopping-cart-header-branch-icon-gas-url no-repeat @marginal-shopping-cart-header-branch-icon-gas-background-position;
								background-size: @marginal-shopping-cart-header-branch-icon-gas-background-size;
								padding-left: 16px;	
							}
							.branch-icon-before-text.water {
								display: inline-block;
								background: transparent @marginal-shopping-cart-header-branch-icon-water-url no-repeat @marginal-shopping-cart-header-branch-icon-water-background-position;
								background-size: @marginal-shopping-cart-header-branch-icon-water-background-size;
								padding-left: 20px;	
							}
							.branch-icon-before-text.thermal {
								display: inline-block;
								background: transparent @marginal-shopping-cart-header-branch-icon-thermal-url no-repeat @marginal-shopping-cart-header-branch-icon-thermal-background-position;
								background-size: @marginal-shopping-cart-header-branch-icon-thermal-background-size;
								padding-left: 20px;	
							}
							.branch-icon-before-text.powerCharge {
								display: inline-block;
								background: transparent @marginal-shopping-cart-header-branch-icon-powercharge-url no-repeat @marginal-shopping-cart-header-branch-icon-powercharge-background-position;
								background-size: @marginal-shopping-cart-header-branch-icon-powercharge-background-size;
								padding-left: 20px;	
							}
						}
						.accordion-button:not(.collapsed) {
							-webkit-box-shadow: none;
							-moz-box-shadow: none;
							box-shadow: none;
						}
						.accordion-button,
						.accordion-button.collapsed {
							border-bottom: 0px;
							&:hover, &:focus {
								border-bottom: 0px;
							}
						}			
					}
					
					.accordion-body {
						padding: 0px;
						
						.shopping-cart-sub-header {
							border-bottom: 0px;
							margin: 0px;
							padding: 0.25rem 1rem;
							background-color: @marginal-shopping-cart-sub-header-background-color;
							font-family: @marginal-shopping-cart-sub-header-font-family;
							font-weight: @marginal-shopping-cart-sub-header-font-weight;
							font-size: @marginal-shopping-cart-sub-header-font-size;
							color: @marginal-shopping-cart-sub-header-color;
						}
						.shopping-card-row {
							margin-left: 1rem;
							margin-right: 1rem;
							padding: 0.25rem 0px;
							font-size: @marginal-shopping-cart-row-font-size;
							border-bottom: @marginal-shopping-cart-row-border-bottom;
							
							&:last-child {
								border-bottom: 0px;
							}		
						}
					}	
					
					&:first-child {
						margin-top: 0px;
					}
					&:last-child {
						border-bottom-left-radius: @marginal-card-border-radius;
						border-bottom-right-radius: @marginal-card-border-radius;
						
						.accordion-header {
							border-bottom-left-radius: @marginal-card-border-radius;
							border-bottom-right-radius: @marginal-card-border-radius;
							
							.accordion-button.collapsed {
								border-bottom-left-radius: @marginal-card-border-radius;
								border-bottom-right-radius: @marginal-card-border-radius;
							}
						}
						
						.accordion-body {
							border-bottom-left-radius: @marginal-card-border-radius;
							border-bottom-right-radius: @marginal-card-border-radius;
						}
					}
				}		
			}
			
			@media (max-width: calc(~'@{main-context-and-process-std-viewport-min-width} - 1px')) {
				
				.card-body {
					padding-left: 1rem;
					padding-right: 1rem;
					padding-bottom: 1rem;				
				
					.accordion-item {
						margin-top: 0.5rem;
						
						&:last-child {
							border-bottom-left-radius: 0px;
							border-bottom-right-radius: 0px;
							
							.accordion-header {
								border-bottom-left-radius: 0px;
								border-bottom-right-radius: 0px;
								
								.accordion-button.collapsed {
									border-bottom-left-radius: 0px;
									border-bottom-right-radius: 0px;
								}
							}
							.accordion-body {
								border-bottom-left-radius: 0px;
								border-bottom-right-radius: 0px;
							}	
						}	
					}
				}
			}
		}
	}
}
@marginal-columns: marginal-columns;	