.mixin(process-cards) {
	
	.main-process {
		.card {
		
			background-color: @main-process-card-background-color;
			border: @main-process-card-border;
			border-radius: @main-process-card-border-radius;
			
			.card-header {
				background-color: @main-process-card-header-background-color;
				border-bottom: @main-process-card-header-border-bottom;
				border-radius: 0px;
				border-top-left-radius: @main-process-card-border-radius;
				border-top-right-radius: @main-process-card-border-radius;
				
				.card-title {
					font-family: @main-process-card-title-font-family;
					font-weight: @main-process-card-title-font-weight;
					color: @main-process-card-title-color;
					font-size: @main-process-card-title-font-size;
					margin-bottom: 0px;
					margin-right: 1rem;
					padding-top: @main-process-card-title-padding-top;
					border-bottom: 0px;
					margin-top: 0px;
					padding-bottom: 0px;
					-webkit-hyphens: auto;
					-ms-hyphens: auto;
					hyphens: auto;
				}

				.steps-container {

					margin: @steps-container-margin;

					.step {
						width: @step-width;
						height: @step-height;
						background-color: @step-background-color;
						border: @step-border;
						border-radius: @step-border-radius;
						margin-right: 8px;
					}
					.step.active {
						background-color: @step-background-color-active;
						border: @step-border-active;
					}
				}
			}
			.card-body {
				background-color: @main-process-card-body-background-color;
				border-radius: 0px;
				border-bottom-left-radius: @main-process-card-body-border-radius;
				border-bottom-right-radius: @main-process-card-body-border-radius;
				
				> .alert {
					border-radius: 0px;
					
					ul {
						padding-left: 1rem;
					}
					
					.btn-close {
						background: transparent none;
						opacity: 1.0;
						
						&:focus,&:active {
							border: 0px !important;
							outline: none !important;
							box-shadow: none;
						}						
						&:before {
							font-size: 18px;
						}	
					}	
				}
				
				.branch-icon-before-text.power {
					display: inline-block;
					background: transparent @branch-icon-before-text-power-url no-repeat @branch-icon-before-text-power-background-position;
					background-size: @branch-icon-before-text-power-background-size;
					padding-left: 15px;	
				}			
				.branch-icon-before-text.gas {
					display: inline-block;
					background: transparent @branch-icon-before-text-gas-url no-repeat @branch-icon-before-text-gas-background-position;
					background-size: @branch-icon-before-text-gas-background-size;
					padding-left: 16px;	
				}
				.branch-icon-before-text.water {
					display: inline-block;
					background: transparent @branch-icon-before-text-water-url no-repeat @branch-icon-before-text-water-background-position;
					background-size: @branch-icon-before-text-water-background-size;
					padding-left: 20px;	
				}
				.branch-icon-before-text.thermal {
					display: inline-block;
					background: transparent @branch-icon-before-text-thermal-url no-repeat @branch-icon-before-text-thermal-background-position;
					background-size: @branch-icon-before-text-thermal-background-size;
					padding-left: 20px;	
				}
				.branch-icon-before-text.powerCharge {
					display: inline-block;
					background: transparent @branch-icon-before-text-powercharge-url no-repeat @branch-icon-before-text-powercharge-background-position;
					background-size: @branch-icon-before-text-powercharge-background-size;
					padding-left: 20px;	
				}
			}
			.card-footer {
				background-color: @main-process-card-footer-background-color;
				border-top: @main-process-card-footer-border-top;
				border-radius: 0px;
				border-bottom-left-radius: @main-process-card-footer-border-radius;
				border-bottom-right-radius: @main-process-card-footer-border-radius;
			}		
		}
		
		@media (max-width: @viewport-sm-max) {
			.card {
				.card-header {
					.card-title {
						font-size: @main-process-card-title-font-size-sm;
						padding-top: 0px;
					}
				}
			}
		}
		
		.card {
			.card {
				background-color: @main-process-card-in-card-background-color;
				border: @main-process-card-in-card-border;
				border-radius: @main-process-card-in-card-border-radius;
				
				.card-header {
					border-top-left-radius: @main-process-card-in-card-border-radius;
					border-top-right-radius: @main-process-card-in-card-border-radius;
					background-color: @main-process-card-in-card-header-background-color;
					border-bottom: @main-process-card-in-card-header-border-bottom;
					
					.card-title {
						padding-top: 0px;
						font-family: @main-process-card-in-card-title-font-family;
						font-weight: @main-process-card-in-card-title-font-weight;
						color: @main-process-card-in-card-title-color;
						font-size: @main-process-card-in-card-title-font-size;
						margin-right: 0px;
						border-bottom: 0px;
						padding-bottom: 0px;
						margin-top: 0px;
						margin-bottom: 0px;
					}
				}
				.card-body {
					border-bottom-left-radius: @main-process-card-in-card-body-border-radius;
					border-bottom-right-radius: @main-process-card-in-card-body-border-radius;
					background-color: @main-process-card-in-card-body-background-color;
				}
				.card-footer {
					background-color: @main-process-card-in-card-footer-background-color;
					border-top: @main-process-card-in-card-footer-border-top;
					border-radius: 0px;
					border-bottom-left-radius: @main-process-card-in-card-footer-border-radius;
					border-bottom-right-radius: @main-process-card-in-card-footer-border-radius;
					padding-top: 0px;
					padding-bottom: 0px;
				}	
			}
		}
		
		.two-column-flex-cards-row {
			width: calc(~'100% + 1.5rem');
			
			.card {
				padding-bottom: 1rem;
				background-color: transparent;
			}
		}

		.ecar-image-row {
			background: transparent @ecar-image-row-url no-repeat 0.75rem center;
			background-size: 400px;
			min-height: 200px;
			
			@media (max-width: @viewport-xl-max) {
				background-size: 370px;
				min-height: 184px;
			}
			@media (max-width: 1299px) {
				background-size: 330px;
				min-height: 164px;
			}
			@media (max-width: @viewport-lg-max) {
				background-size: 315px;
				min-height: 156px;
			}		
			@media (max-width: @viewport-md-max) {
				background-size: 370px;
				min-height: 184px;
			}
			@media (max-width: @viewport-sm-max) {
				background-size: 300px;
				background-position: calc(~'100% - 0.75rem') center;
				min-height: 156px;
			}
			@media (max-width: 689px) {
				background-size: 180px;
				min-height: 90px;
			}
			@media (max-width: @viewport-xs-max) {
				background-size: 100px;
				background-position: 0.75rem -1rem;
				padding-top: 68px;
				min-height: 0px;
			}
		}	
	}

	.in .main-process {
		.card {
			border-top-left-radius: 0px;
			
			.card-header {
				border-top-left-radius: 0px;
			}
		}
		@media (max-width: @viewport-sm-max) {
			.card {
				border-top-left-radius: @main-process-card-border-radius;
				
				.card-header {
					border-top-left-radius: @main-process-card-border-radius;
				}
			}
		}
	}

	.originalValue {
		color: @grey-3;
	}
}
@process-cards: process-cards;
